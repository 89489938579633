import { type ReactNode } from 'react';
import { COLORS } from '../../constants/style/color';
import { type ModalStatusType } from '../../types/common';
// import CheckPermissions from '../../utils/checkPermission';
import Spinners from '../spinner';
import { Btn } from './button.styled';

interface ButtonType {
  variant:
    | 'primary'
    | 'primary-outline'
    | 'primary-dark'
    | 'secondary'
    | 'secondary-dark'
    | 'secondary-outline'
    | 'links'
    | string;
  icon?: string | ReactNode;
  title: string | ReactNode;
  onClick?: any;
  btnClassName?: string;
  loading?: boolean;
  disabled?: boolean;
  as?: ModalStatusType;
  hasId?: string;
  type?: 'button' | 'submit' | 'reset';
}

const Button = ({
  variant,
  icon,
  title,
  onClick,
  btnClassName,
  loading,
  type,
  disabled,
  hasId,
  as,
  ...rest
}: ButtonType): JSX.Element => {
  return (
    // <CheckPermissions type={as} hasId={hasId}>
    <Btn
      className={btnClassName}
      type={type}
      variant={variant}
      onClick={onClick}
      as='button'
      disabled={disabled ?? loading}
      {...rest}>
      {Boolean(icon) && <span className='material-symbols-outlined'>{icon}</span>}
      {loading === true ? <Spinners borderColor={COLORS.White} size='sm' /> : title}
    </Btn>
    // </CheckPermissions>
  );
};

export default Button;
