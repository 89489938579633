import { type UseQueryResult, useQuery } from 'react-query';
import { PlanTypeService } from '../../services/wealthlane-award-services';

const useGetPlanTypes = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string;
    }>
  | undefined,
  unknown
> => {
  const planTypes = useQuery(
    ['getPlanTypes'],
    async () => {
      return await handleRequest(PlanTypeService.getApiAwardApiAppPlanTypePlanTypes());
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.name ?? '',
            value: item.id ?? '',
          };
        });
      },
    }
  );

  return planTypes;
};

export default useGetPlanTypes;
