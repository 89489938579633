import styled, { css } from 'styled-components';
import { COLORS } from '../../constants/style/color';
import { rem } from '../../utils/style';

interface TooltipComponentWrapperType {
  size: 'lg' | 'md' | 'sm';
  variant: 'dark' | 'light';
  width?: string;
}

export const TooltipComponentContainer = styled.div`
  .tooltip-text {
    cursor: pointer;
    display: flex;
  }
`;

export const TooltipComponentWrapper = styled.div<TooltipComponentWrapperType>`
  display: flex;
  filter: drop-shadow(${rem(0)} ${rem(2)} ${rem(4)} ${COLORS.SoftShadow});
  z-index: 11;
  border-radius: ${rem(4)};
  max-width: ${rem(300)};

  ${(props) => {
    if (props.variant === 'dark') {
      return css`
        background: ${COLORS.Black};

        span {
          color: ${COLORS.White};
        }
      `;
    }

    if (props.variant === 'light') {
      return css`
        background: ${COLORS.White};
      `;
    }
  }};

  ${(props) => {
    if (props.size === 'sm') {
      return css`
        padding: ${rem(10)} ${rem(11)};
      `;
    }

    if (props.size === 'md') {
      return css`
        padding: ${rem(12)} ${rem(8)};
      `;
    }

    if (props.size === 'lg') {
      return css`
        padding: ${rem(14)};
      `;
    }
  }};
`;
