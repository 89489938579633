/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_TaxCertificationFormDTO_TaxCertificationW9Forms_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_TaxCertificationFormDTO_TaxCertificationW9Forms_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_CustomerService_Dtos_TaxCertificationFormDTO_TaxCertificationFormW8DTO } from '../models/Wealthlane_CustomerService_Dtos_TaxCertificationFormDTO_TaxCertificationFormW8DTO';
import type { Wealthlane_CustomerService_Dtos_TaxCertificationFormDTO_TaxCertificationFormW9DTO } from '../models/Wealthlane_CustomerService_Dtos_TaxCertificationFormDTO_TaxCertificationFormW9DTO';
import type { Wealthlane_CustomerService_Dtos_TaxCertificationFormDTO_TaxCetificationViewDTO } from '../models/Wealthlane_CustomerService_Dtos_TaxCertificationFormDTO_TaxCetificationViewDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TaxCertificationService {

    /**
     * @param formData 
     * @returns Wealthlane_CustomerService_Dtos_TaxCertificationFormDTO_TaxCertificationFormW9DTO Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppTaxCertificationTaxCertificationFormW9(
formData?: {
Name: string;
BusinessName?: string;
IndividualProprietor: boolean;
CCorporation: boolean;
SCorporation: boolean;
Partnership: boolean;
Trust: boolean;
LimitedLiabilityCompany: boolean;
LimitedLiabilityCompanyText?: string;
FederalTaxClassificationOther: boolean;
FederalTaxClassificationOtherText?: string;
Address: string;
CityStateZipCode: string;
AccountNumbers?: string;
ExcemptPayeeCode?: string;
ExcemptionFATCAReportingCode?: string;
RequestorName?: string;
RequestorAddress?: string;
SSN: string;
EmployerIdenticationNumber?: string;
CertificationDate: string;
Signature: Blob;
},
): CancelablePromise<Wealthlane_CustomerService_Dtos_TaxCertificationFormDTO_TaxCertificationFormW9DTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/tax-certification/tax-certification-form-w9',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData 
     * @returns Wealthlane_CustomerService_Dtos_TaxCertificationFormDTO_TaxCertificationFormW9DTO Success
     * @throws ApiError
     */
    public static putApiCustomerApiAppTaxCertificationTaxCertificationFormW9(
formData?: {
Name: string;
BusinessName?: string;
IndividualProprietor: boolean;
CCorporation: boolean;
SCorporation: boolean;
Partnership: boolean;
Trust: boolean;
LimitedLiabilityCompany: boolean;
LimitedLiabilityCompanyText?: string;
FederalTaxClassificationOther: boolean;
FederalTaxClassificationOtherText?: string;
Address: string;
CityStateZipCode: string;
AccountNumbers?: string;
ExcemptPayeeCode?: string;
ExcemptionFATCAReportingCode?: string;
RequestorName?: string;
RequestorAddress?: string;
SSN: string;
EmployerIdenticationNumber?: string;
CertificationDate: string;
Signature: Blob;
},
): CancelablePromise<Wealthlane_CustomerService_Dtos_TaxCertificationFormDTO_TaxCertificationFormW9DTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/customer/api/app/tax-certification/tax-certification-form-w9',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param participantId 
     * @returns Wealthlane_CustomerService_Dtos_TaxCertificationFormDTO_TaxCetificationViewDTO Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppTaxCertificationTaxCertificationForm(
participantId: string,
): CancelablePromise<Wealthlane_CustomerService_Dtos_TaxCertificationFormDTO_TaxCetificationViewDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/tax-certification/tax-certification-form/{ParticipantId}',
            path: {
                'ParticipantId': participantId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param fromCertificationDate 
     * @param toCertificationDate 
     * @param fromExpirationDate 
     * @param toExpirationDate 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_TaxCertificationFormDTO_TaxCertificationW9Forms_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppTaxCertificationTaxCertificationFormW9List(
fromCertificationDate?: string,
toCertificationDate?: string,
fromExpirationDate?: string,
toExpirationDate?: string,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_TaxCertificationFormDTO_TaxCertificationW9Forms_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/tax-certification/tax-certification-form-w9List',
            query: {
                'FromCertificationDate': fromCertificationDate,
                'ToCertificationDate': toCertificationDate,
                'FromExpirationDate': fromExpirationDate,
                'ToExpirationDate': toExpirationDate,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData 
     * @returns Wealthlane_CustomerService_Dtos_TaxCertificationFormDTO_TaxCertificationFormW8DTO Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppTaxCertificationTaxCertificationFormW8(
formData?: {
Name: string;
CitizenshipCountryId: string;
PermanentAddress: string;
PermanentCity: string;
PermanentCountryId: string;
HasDifferentMailingAddress: boolean;
MailingAddress?: string;
MailingCity?: string;
MailingCountryId?: string;
SSNOrIdentificationNumber?: string;
ForeignTaxIdentifyingNumber?: string;
FTINLegallyRequired: boolean;
ReferenceNumber?: string;
DateOfBirth: string;
BeneficialCountryId: string;
BeneficialArticle: string;
BeneficialPercentage: number;
BeneficialIncomeType: string;
BeneficialAdditionalCondition: string;
CapacitySignCertify: boolean;
CertificationDate: string;
PrintName: string;
Signature: Blob;
},
): CancelablePromise<Wealthlane_CustomerService_Dtos_TaxCertificationFormDTO_TaxCertificationFormW8DTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/tax-certification/tax-certification-form-w8',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
