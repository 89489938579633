import { type UseQueryResult, useQuery } from 'react-query';
import {
  ParticipantService,
  // eslint-disable-next-line camelcase
  type Wealthlane_CustomerService_Dtos_CompanyLogo_CompanyLogoBlobDto,
} from '../../services/wealthlane-customer-services';

const useGetProfileImage = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_CustomerService_Dtos_CompanyLogo_CompanyLogoBlobDto | undefined,
  unknown
> => {
  const ProfileImage = useQuery(
    ['getProfileImage'],
    async () => {
      return await handleRequest(ParticipantService.getApiCustomerApiAppParticipantCompanyLogo());
    },
    {
      keepPreviousData: true,
      cacheTime: 1000 * 60 * 5,
    }
  );

  return ProfileImage;
};

export default useGetProfileImage;
