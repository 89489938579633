/* eslint-disable no-unused-vars */
import { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { RouteList } from './routesList';
import ProtectedRoute from './ProtectedRoute';
import AuthService from '../services/authService';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '../auth/authSlice';
import SignUp from '../pages/signup';
import EmailConfirmation from '../pages/email-confirmation';
import SignUpConfirmationPage from '../pages/signups-sonfirmation';
import Login from '../auth/login';
import Callback from '../auth/callback';

const RoutesContainer = () => {
  const authService = new AuthService();
  const isAuthenticated = useSelector(selectIsAuthenticated) || authService.isAuthenticated();

  return (
    <Suspense fallback={() => <>LOADING</>}>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/sign-up' element={<SignUp />} />
        <Route path='/sign-up-confirm' element={<SignUpConfirmationPage />} />
        <Route path='/email-confirm' element={<EmailConfirmation />} />

        <Route path='/callback' element={<Callback />} />
        <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
          {RouteList.map((route, i) => (
            <Route path={route.path} element={<route.component />} key={i} exact={route.exact} />
          ))}
          <Route path='/' element={<Navigate to='/signup-check' replace />} />
          <Route path='*' element={<Navigate to='/page-not-found' replace />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default RoutesContainer;
