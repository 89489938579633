import { getViewHeight } from '../../utils/style';
import styled from 'styled-components';
import { COLORS } from '../../constants/style/color';

export const AlertPopupContainer = styled.div`
  .popupDialog {
    height: 100%;
    /* min-height: ${getViewHeight('3.5rem')}; */
    width: auto;
    max-width: 840px;
    position: relative;
    display: flex;
    align-items: center;
    margin: 1.75rem auto;
    justify-content: center;
  }

  .popupContent {
    width: auto;
    margin: 0.5rem;
    background: ${COLORS.White};
    box-shadow: 0px 2px 7px ${COLORS.CardShadow};
    border-radius: 6px;
    padding: 24px 42px;

    @media screen and (min-width: 576px) {
      min-width: 515px;
      margin: 1.75rem auto;
    }
  }

  .popupButtons {
    margin-top: 18px;
    display: flex;
    justify-content: center;

    button {
      margin: 0 4px;
      font-size: 1.4rem;
      line-height: 1.6rem;
      padding: 0.8rem 1.6rem;

      > span {
        height: 15px;
        align-items: center;
        display: flex;
        span {
          font-size: 15px;
        }
      }
    }
  }
`;
