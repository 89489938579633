import { IDENTITY_CONFIG, METADATA_OIDC } from '../utils/authConst';
import { UserManager, WebStorageStateStore, Log } from 'oidc-client';

export default class AuthService {
  UserManager;

  constructor() {
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      metadata: {
        ...METADATA_OIDC,
      },
    });
    // Logger
    Log.logger = console;
    Log.level = Log.DEBUG;
    this.UserManager.events.addUserLoaded((user) => {
      if (window.location.href.includes('callback')) {
        this.navigateToScreen();
      }
    });

    this.UserManager.events.addAccessTokenExpired(() => {
      void this.signInSilent();
    });
  }

  signInRedirectCallback = (): any => {
    localStorage.setItem('loggedIn Time', `${String(new Date())}`);
    localStorage.setItem('Notify', 'Yes');
    return this.UserManager.signinRedirectCallback();
  };

  getUser = async (): Promise<any> => {
    const user = await this.UserManager.getUser();

    if (user == null) {
      return await this.UserManager.signinRedirectCallback();
    }
    return user;
  };

  parseJwt = (token: string): any => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };

  signInRedirect = async (): Promise<void> => {
    localStorage.setItem('redirectUri', window.location.pathname);
    void this.UserManager.signinRedirect({});
  };

  navigateToScreen = (): void => {
    window.location.replace('/');
  };

  isAuthenticated = (): boolean => {
    const oidcStorage = localStorage.getItem(
      `oidc.user:${IDENTITY_CONFIG.authority ?? ''}:${IDENTITY_CONFIG.client_id ?? ''}`
    );

    if (oidcStorage != null) {
      const parsedStorage = JSON.parse(oidcStorage);
      return !!(Boolean(parsedStorage) && Boolean(parsedStorage.access_token));
    }

    return false;
  };

  signInSilent = async (): Promise<void> => {
    this.UserManager.signinSilent({
      scope: IDENTITY_CONFIG.scope,
      response_type: IDENTITY_CONFIG.response_type,
    })
      .then((user) => {
        void this.UserManager.signinSilentCallback();
        void this.UserManager.signinRedirect({});
        console.log('signed in', user);
      })
      .catch(() => {
        void this.logout();
      });
  };

  signinSilentCallback = async (): Promise<void> => {
    await this.UserManager.signinSilentCallback();
  };

  createSignInRequest = (): any => {
    return this.UserManager.createSigninRequest();
  };

  logout = async (): Promise<void> => {
    void this.UserManager.signoutRedirect({
      id_token_hint: localStorage.getItem('id_token'),
    });
    localStorage.removeItem('loggedIn Time');
    localStorage.removeItem('Notify');
    sessionStorage.clear();
    void this.UserManager.clearStaleState();
  };

  signOutRedirectCallback = async (): Promise<void> => {
    try {
      await this.UserManager.signoutRedirectCallback();
      localStorage.clear();
      window.location.replace(process.env.REACT_APP_PUBLIC_URL ?? '');
    } catch (err) {
      // Handle the error if necessary
    }

    await this.UserManager.clearStaleState();
  };

  getToken = (): string => {
    const oidcStorageString = localStorage.getItem(
      `oidc.user:${IDENTITY_CONFIG.authority ?? ''}:${IDENTITY_CONFIG.client_id ?? ''}`
    );
    const oidcStorage = oidcStorageString != null ? JSON.parse(oidcStorageString) : null;
    return oidcStorage != null && Boolean(oidcStorage.access_token)
      ? oidcStorage.access_token
      : null;
  };
}
