import { type UseQueryResult, useQuery } from 'react-query';
import {
  TradePreClearanceService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_TradePreClearance_PreClearancedAwardDetailDto,
} from '../../services/wealthlane-award-services';

const useGetPreClearanceAwardDetails = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_TradePreClearance_PreClearancedAwardDetailDto | undefined,
  unknown
> => {
  const preClearanceAwardDetails = useQuery(
    ['getPreClearanceAwardDetails'],
    async () => {
      return await handleRequest(
        TradePreClearanceService.getApiAwardApiAppTradePreClearancePreClearancedAwardDetails()
      );
    },
    {
      select: (data) => {
        if (data != null) {
          const payload = {
            ...data,
            awardDetails: data?.awardDetails?.map((item) => {
              return {
                ...item,
                isChecked: item.availableQty === item.transactionQty,
                isSellAll: typeof item.rsuAwardId === 'string' && item.rsuAwardId !== '',
                isRSU: typeof item.rsuAwardId === 'string' && item.rsuAwardId !== '',
                isPSU: typeof item.psuAwardId === 'string' && item.psuAwardId !== '',
                isOption: typeof item.optionAwardId === 'string' && item.optionAwardId !== '',
                isSellToCover: false,
                transactionQty: item.transactionQty === 0 ? '' : item.transactionQty,
              };
            }),
            requestRemarks: '',
            participantId:
              (data?.awardDetails != null &&
                data?.awardDetails?.length > 0 &&
                data?.awardDetails[0].participantId) ??
              '',
            rejectionRemarks: '',
            isVerified: null,
          };
          return payload;
        }
      },
    }
  );

  return preClearanceAwardDetails as any;
};

export default useGetPreClearanceAwardDetails;
