/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_CustomerService_Dtos_ParticipantLogInAndSecurity_ChangeParticipantContactNumberDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantLogInAndSecurity_ChangeParticipantContactNumberDto';
import type { Wealthlane_CustomerService_Dtos_ParticipantLogInAndSecurity_ChangeParticipantLogInPasswordDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantLogInAndSecurity_ChangeParticipantLogInPasswordDto';
import type { Wealthlane_CustomerService_Dtos_ParticipantLogInAndSecurity_ChangeParticipantSecurityQuestionsDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantLogInAndSecurity_ChangeParticipantSecurityQuestionsDto';
import type { Wealthlane_CustomerService_Dtos_ParticipantLogInAndSecurity_ParticipantLogInAndSecurityDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantLogInAndSecurity_ParticipantLogInAndSecurityDto';
import type { Wealthlane_CustomerService_Dtos_ParticipantLogInAndSecurity_ValidateParticipantSecurityQuestionsAnswersDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantLogInAndSecurity_ValidateParticipantSecurityQuestionsAnswersDto';
import type { Wealthlane_CustomerService_Dtos_ParticipantSignUp_ParticipantMultiPurposeOTPVerficationDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantSignUp_ParticipantMultiPurposeOTPVerficationDto';
import type { Wealthlane_CustomerService_Dtos_SecurityQuestion_UserSelectedSecurityQuestionDto } from '../models/Wealthlane_CustomerService_Dtos_SecurityQuestion_UserSelectedSecurityQuestionDto';
import type { Wealthlane_Shared_Hosting_Dtos_ParticipantLogInSecurity_ParticipantLogInSecurityResponseDto } from '../models/Wealthlane_Shared_Hosting_Dtos_ParticipantLogInSecurity_ParticipantLogInSecurityResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ParticipantLogInAndSecurityService {

    /**
     * @returns Wealthlane_CustomerService_Dtos_ParticipantLogInAndSecurity_ParticipantLogInAndSecurityDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppParticipantLogInAndSecurityParticipantLogInSecurity(): CancelablePromise<Wealthlane_CustomerService_Dtos_ParticipantLogInAndSecurity_ParticipantLogInAndSecurityDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/participant-log-in-and-security/participant-log-in-security',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_Shared_Hosting_Dtos_ParticipantLogInSecurity_ParticipantLogInSecurityResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppParticipantLogInAndSecuritySendParticipantMultiPurposeOTp(): CancelablePromise<Wealthlane_Shared_Hosting_Dtos_ParticipantLogInSecurity_ParticipantLogInSecurityResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/participant-log-in-and-security/send-participant-multi-purpose-oTP',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_Shared_Hosting_Dtos_ParticipantLogInSecurity_ParticipantLogInSecurityResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppParticipantLogInAndSecurityVerifyParticipantMultiPurposeOTp(
requestBody?: Wealthlane_CustomerService_Dtos_ParticipantSignUp_ParticipantMultiPurposeOTPVerficationDto,
): CancelablePromise<Wealthlane_Shared_Hosting_Dtos_ParticipantLogInSecurity_ParticipantLogInSecurityResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/participant-log-in-and-security/verify-participant-multi-purpose-oTP',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_Shared_Hosting_Dtos_ParticipantLogInSecurity_ParticipantLogInSecurityResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppParticipantLogInAndSecurityChangeParticipantContactNumber(
requestBody?: Wealthlane_CustomerService_Dtos_ParticipantLogInAndSecurity_ChangeParticipantContactNumberDto,
): CancelablePromise<Wealthlane_Shared_Hosting_Dtos_ParticipantLogInSecurity_ParticipantLogInSecurityResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/participant-log-in-and-security/change-participant-contact-number',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_Shared_Hosting_Dtos_ParticipantLogInSecurity_ParticipantLogInSecurityResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppParticipantLogInAndSecurityChangeParticipantSecurityQuestions(
requestBody?: Wealthlane_CustomerService_Dtos_ParticipantLogInAndSecurity_ChangeParticipantSecurityQuestionsDto,
): CancelablePromise<Wealthlane_Shared_Hosting_Dtos_ParticipantLogInSecurity_ParticipantLogInSecurityResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/participant-log-in-and-security/change-participant-security-questions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppParticipantLogInAndSecurityValidateMultiPurposeToken(): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/participant-log-in-and-security/validate-multi-purpose-token',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppParticipantLogInAndSecurityValidateSecurityQuestionAnswers(
requestBody?: Wealthlane_CustomerService_Dtos_ParticipantLogInAndSecurity_ValidateParticipantSecurityQuestionsAnswersDto,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/participant-log-in-and-security/validate-security-question-answers',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_Shared_Hosting_Dtos_ParticipantLogInSecurity_ParticipantLogInSecurityResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppParticipantLogInAndSecurityVerifyParticipantMultiPurposeSecurityQuestionAnswers(
requestBody?: Wealthlane_CustomerService_Dtos_ParticipantLogInAndSecurity_ValidateParticipantSecurityQuestionsAnswersDto,
): CancelablePromise<Wealthlane_Shared_Hosting_Dtos_ParticipantLogInSecurity_ParticipantLogInSecurityResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/participant-log-in-and-security/verify-participant-multi-purpose-security-question-answers',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param email 
     * @returns Wealthlane_CustomerService_Dtos_SecurityQuestion_UserSelectedSecurityQuestionDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppParticipantLogInAndSecuritySecurityQuestionsByParticipantEmail(
email?: string,
): CancelablePromise<Wealthlane_CustomerService_Dtos_SecurityQuestion_UserSelectedSecurityQuestionDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/participant-log-in-and-security/security-questions-by-participant-email',
            query: {
                'email': email,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_CustomerService_Dtos_SecurityQuestion_UserSelectedSecurityQuestionDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppParticipantLogInAndSecuritySecurityQuestionsByParticipantId(): CancelablePromise<Wealthlane_CustomerService_Dtos_SecurityQuestion_UserSelectedSecurityQuestionDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/participant-log-in-and-security/security-questions-by-participant-id',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_Shared_Hosting_Dtos_ParticipantLogInSecurity_ParticipantLogInSecurityResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppParticipantLogInAndSecurityChangeParticipantLogInPasswordWithSecurityQuestionAnswer(
requestBody?: Wealthlane_CustomerService_Dtos_ParticipantLogInAndSecurity_ChangeParticipantLogInPasswordDto,
): CancelablePromise<Wealthlane_Shared_Hosting_Dtos_ParticipantLogInSecurity_ParticipantLogInSecurityResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/participant-log-in-and-security/change-participant-log-in-password-with-security-question-answer',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
