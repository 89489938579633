/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_NotificationService_DTOs_NotificationSettingsDto } from '../models/Wealthlane_NotificationService_DTOs_NotificationSettingsDto';
import type { Wealthlane_NotificationService_DTOs_NotificationSettingsRequestDto } from '../models/Wealthlane_NotificationService_DTOs_NotificationSettingsRequestDto';
import type { Wealthlane_NotificationService_DTOs_ResponseDto } from '../models/Wealthlane_NotificationService_DTOs_ResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NotificationSettingsService {

    /**
     * @returns Wealthlane_NotificationService_DTOs_NotificationSettingsDto Success
     * @throws ApiError
     */
    public static getApiNotificationApiAppNotificationSettingsNotificationSettings(): CancelablePromise<Array<Wealthlane_NotificationService_DTOs_NotificationSettingsDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/notification/api/app/notification-settings/notification-settings',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_NotificationService_DTOs_NotificationSettingsDto Success
     * @throws ApiError
     */
    public static getApiNotificationApiAppNotificationSettingsParticipantNotificationSettings(): CancelablePromise<Array<Wealthlane_NotificationService_DTOs_NotificationSettingsDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/notification/api/app/notification-settings/participant-notification-settings',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_NotificationService_DTOs_ResponseDto Success
     * @throws ApiError
     */
    public static postApiNotificationApiAppNotificationSettingsSaveNotificationSettings(
requestBody?: Wealthlane_NotificationService_DTOs_NotificationSettingsRequestDto,
): CancelablePromise<Wealthlane_NotificationService_DTOs_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/notification/api/app/notification-settings/save-notification-settings',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
