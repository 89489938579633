import { type UseQueryResult, useQuery } from 'react-query';
import { SecurityQuestionService } from '../../services/wealthlane-customer-services';

const useGetSecurityQuestion = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string;
      category: number | undefined;
    }>
  | undefined,
  unknown
> => {
  const CustomerSecurityQuestionList = useQuery(
    ['getCustomerSecurityQuestionList'],
    async () => {
      return await handleRequest(
        SecurityQuestionService.getApiCustomerApiAppSecurityQuestionSecurityQuestions()
      );
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.displayText ?? '',
            value: item.id ?? '',
            category: item.category,
          };
        });
      },
    }
  );
  return CustomerSecurityQuestionList;
};

export default useGetSecurityQuestion;
