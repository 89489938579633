/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto } from '../models/Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto';
import type { Wealthlane_AwardSettlementService_Dtos_TradeOrder_ExportPlacedOrderRequestDto } from '../models/Wealthlane_AwardSettlementService_Dtos_TradeOrder_ExportPlacedOrderRequestDto';
import type { Wealthlane_AwardSettlementService_Dtos_TradeOrder_OrderPlacedCallBackRequestDto } from '../models/Wealthlane_AwardSettlementService_Dtos_TradeOrder_OrderPlacedCallBackRequestDto';
import type { Wealthlane_AwardSettlementService_Dtos_TradeOrder_OrderView } from '../models/Wealthlane_AwardSettlementService_Dtos_TradeOrder_OrderView';
import type { Wealthlane_AwardSettlementService_Dtos_TradeOrder_TradeCancelOrderStatusDto } from '../models/Wealthlane_AwardSettlementService_Dtos_TradeOrder_TradeCancelOrderStatusDto';
import type { Wealthlane_AwardSettlementService_Dtos_TradeOrder_TradeOrderStatusDto } from '../models/Wealthlane_AwardSettlementService_Dtos_TradeOrder_TradeOrderStatusDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TradeOrderService {

    /**
     * @param requestBody 
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiAwardSettlementApiAppTradeOrderCallBackOrderPlaced(
requestBody?: Wealthlane_AwardSettlementService_Dtos_TradeOrder_OrderPlacedCallBackRequestDto,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award-settlement/api/app/trade-order/call-back-order-placed',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param transactionId 
     * @returns Wealthlane_AwardSettlementService_Dtos_TradeOrder_OrderView Success
     * @throws ApiError
     */
    public static getApiAwardSettlementApiAppTradeOrderTradeOrderByTransactionId(
transactionId: string,
): CancelablePromise<Wealthlane_AwardSettlementService_Dtos_TradeOrder_OrderView> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award-settlement/api/app/trade-order/trade-order-by-transaction-id/{TransactionId}',
            path: {
                'TransactionId': transactionId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static postApiAwardSettlementApiAppTradeOrderExportPlacedOrders(
requestBody?: Wealthlane_AwardSettlementService_Dtos_TradeOrder_ExportPlacedOrderRequestDto,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award-settlement/api/app/trade-order/export-placed-orders',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData 
     * @returns Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardSettlementApiAppTradeOrderImportOrders(
formData?: {
File?: Blob;
},
): CancelablePromise<Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award-settlement/api/app/trade-order/import-orders',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param transactionId 
     * @returns Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardSettlementApiAppTradeOrderCancelOrder(
transactionId: string,
): CancelablePromise<Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award-settlement/api/app/trade-order/cancel-order/{transactionId}',
            path: {
                'transactionId': transactionId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardSettlementService_Dtos_TradeOrder_TradeOrderStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardSettlementApiAppTradeOrderTradeOrderStatus(): CancelablePromise<Array<Wealthlane_AwardSettlementService_Dtos_TradeOrder_TradeOrderStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award-settlement/api/app/trade-order/trade-order-status',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardSettlementService_Dtos_TradeOrder_TradeCancelOrderStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardSettlementApiAppTradeOrderTradeOrderCancelStatus(): CancelablePromise<Array<Wealthlane_AwardSettlementService_Dtos_TradeOrder_TradeCancelOrderStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award-settlement/api/app/trade-order/trade-order-cancel-status',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
