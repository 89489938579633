import { type UseQueryResult, useQuery } from 'react-query';
import {
  MyHoldingsService,
  TransactionService,
  // eslint-disable-next-line camelcase
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Transactions_TransactionList_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
} from '../../services/wealthlane-award-services';
import { TableParamsType } from '../../types/common';

const useGetTransactionHistory = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Transactions_TransactionList_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
  | undefined,
  unknown
> => {
  const {
    approvalStatusIds,
    fromDate,
    toDate,
    taxCollectionOptions: taxCollectionStatuses,
    transferAllocateOptions: transferAllocateStatuses,
    settlementOptions: settlementStatuses,
    awardOptions: awardTypes,
    settlementTypes: settlementTypes,
    recordTypes: recordTypes,
    transactionStatus: transactionStatus,
    cancelStatusId: cancelStatusId,
    filter,
    sorting,
    skipCount,
    maxResultCount,
  } = tableInfo;
  const transactionHistory = useQuery(
    [
      'getTransactionHistory',
      approvalStatusIds,
      fromDate,
      toDate,
      taxCollectionStatuses,
      transferAllocateStatuses,
      settlementStatuses,
      awardTypes,
      settlementTypes,
      recordTypes,
      transactionStatus,
      cancelStatusId,
      filter,
      sorting,
      skipCount,
      maxResultCount,
    ],
    async () => {
      return await handleRequest(
        TransactionService.getApiAwardApiAppTransactionTransactionListForParticipant(
          approvalStatusIds,
          fromDate,
          toDate,
          taxCollectionStatuses,
          transferAllocateStatuses,
          settlementStatuses,
          awardTypes,
          settlementTypes,
          recordTypes,
          transactionStatus,
          cancelStatusId,
          filter,
          sorting,
          skipCount,
          maxResultCount
        )
      );
    }
  );
  return transactionHistory;
};

export default useGetTransactionHistory;
