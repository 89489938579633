import {
  DigitsOnlyMessage,
  RequiredMessage,
  NotBlankMessage,
  MaxCharMessage,
  EmailMessage,
  AlphanumericMessage,
  TextMessage,
} from '../../constants/validationMessage';
import * as Yup from 'yup';

export const regExp = {
  text: /^[a-zA-Z]+$/,
  textWithSpace: /^[A-Za-z\s]+$/,
  textWithoutSpace: /^\s*\S[\s\S]*$/,
  textWithNumber: /^[a-zA-Z0-9-_ ]+$/,
  textWithNumberAndDot: /^[a-zA-Z0-9 .,]*$/,
  addressValidation: /^[a-zA-Z0-9 .,']*$/,
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  contact: /^(98|97)([0-9]{8})$/,
  digits: /^[0-9][0-9]*$/,
  numericPhone: /^[0-9-][0-9-]*$/,
  number: /^[0-9][0-9]*$/,
  numberWithSpecialCharacter: /^[0-9#$%^&*()@!-/.,]*$/,
  landline: /^01[0-9]+$/,
  textWithNumberWithoutZeroLeading: /^[a-zA-Z1-9_ ][a-zA-Z0-9_ ]*$/,
  phone: /^((\\([0-9]{3}\\)[ \\-]*)|([0-9]{3})[ \\-]*)*?[0-9]{4}?$/,
  // eslint-disable-next-line no-useless-escape
  url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm,
};

interface commonValidationType {
  required?: boolean;
  email?: boolean;
  noBlank?: boolean;
  alphanumeric?: boolean;
  alphanumericDot?: boolean;
  addressValidation?: boolean;
  isText?: boolean;
  digits?: boolean;
  numericPhone?: boolean;
  textLimit?: number;
  min?: number;
  max?: number;
  isNumber?: boolean;
  isBoolean?: boolean;
  isDate?: boolean;
  doubleSpace?: boolean;
}

export const commonValidation = ({
  isNumber = false,
  isBoolean = false,
  isDate = false,
  required,
  email,
  noBlank,
  alphanumeric,
  alphanumericDot,
  numericPhone,
  digits,
  textLimit,
  isText,
  min,
  max,
  addressValidation,
  doubleSpace = false,
}: commonValidationType): any => {
  let textYup: any = Yup.string();

  if (isNumber) {
    textYup = Yup.number();
  }

  if (isDate) {
    textYup = Yup.date().typeError('Required field');
  }

  if (isBoolean) {
    textYup = Yup.boolean();
  }

  if (textLimit != null) {
    textYup = textYup.max(textLimit, MaxCharMessage);
  }

  if (required ?? false) {
    textYup = textYup.required(RequiredMessage);
  }

  // if (isBoolean && (required ?? false)) {
  //   textYup = Yup.boolean().test('match', 'Required field', (value: any) => !!value);
  // }

  if (isBoolean && (required ?? false)) {
    textYup = Yup.boolean().test('match', 'Required field', (value: any) => {
      if (typeof value !== 'boolean') {
        return false;
      }
      return !!value;
    });
  }

  if (min != null) {
    textYup = textYup.min(min, `Must be greater than ${min - 1}`);
  }

  if (max != null) {
    textYup = textYup.max(max, `Must be less than or equal to ${max}`);
  }

  if (email ?? false) {
    textYup = textYup.email(EmailMessage);
  }

  if (isText ?? false) {
    textYup = textYup.matches(regExp.text, TextMessage);
  }

  if (alphanumeric ?? false) {
    textYup = textYup.matches(regExp.textWithNumber, AlphanumericMessage);
  }

  if (alphanumericDot ?? false) {
    textYup = textYup.matches(regExp.textWithNumberAndDot, AlphanumericMessage);
  }
  if (addressValidation ?? false) {
    textYup = textYup.matches(regExp.addressValidation, AlphanumericMessage);
  }

  if (digits ?? false) {
    textYup = textYup.matches(regExp.digits, DigitsOnlyMessage);
  }
  if (numericPhone ?? false) {
    textYup = textYup.matches(regExp.numericPhone, DigitsOnlyMessage);
  }
  if (noBlank ?? false) {
    textYup = textYup.matches(regExp.textWithoutSpace, NotBlankMessage);
  }

  if (doubleSpace) {
    textYup = Yup.string()
      .test('double-space', function (value) {
        const validation: boolean = value?.includes('  ') ?? false;
        if (validation) {
          return this.createError({
            path: this.path,
            message: 'Must not contain double space ',
          });
        } else {
          return true;
        }
      })
      .concat(textYup);
  }

  return textYup.nullable();
};
