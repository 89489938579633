import { type UseQueryResult, useQuery } from 'react-query';
import {
  MyHoldingsService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_MyHoldings_HoldingsDto,
} from '../../services/wealthlane-award-services';

const useGetHoldingList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_MyHoldings_HoldingsDto[] | undefined,
  unknown
> => {
  const holdingList = useQuery(['getHoldingList'], async () => {
    return await handleRequest(MyHoldingsService.getApiAwardApiAppMyHoldingsHoldings());
  });
  return holdingList;
};

export default useGetHoldingList;
