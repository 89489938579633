import { type UseMutationResult, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import ToastComponent from '../../components/toast';
import {
  TradePreClearanceService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_Response_ResponseDto,
} from '../../services/wealthlane-award-services';

const usePostPreClearanceRequest = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  closeModal: () => void
): UseMutationResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_Response_ResponseDto | undefined,
  unknown,
  any,
  unknown
> => {
  return useMutation(
    async (payload: any) => {
      return await handleRequest(
        TradePreClearanceService.postApiAwardApiAppTradePreClearancePreClearanceRequest(payload)
      );
    },
    {
      // eslint-disable-next-line camelcase
      onSuccess: (response: Wealthlane_AwardService_Dtos_Response_ResponseDto | undefined) => {
        if (response != null && response !== undefined) {
          toast.success(<ToastComponent label='Success' message='Pre-Clearance Requested.' />);
          closeModal();
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostPreClearanceRequest;
