import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SpinnerPageComponent from '../components/spinner/spinnerPage';
import AuthService from '../services/authService';

function Login(): JSX.Element {
  const authService = new AuthService();
  const navigate = useNavigate();

  useEffect(() => {
    if (authService.isAuthenticated()) {
      navigate('/');
    } else {
      authService.signInRedirect();
    }
  }, []);

  return <SpinnerPageComponent />;
}

export default Login;
