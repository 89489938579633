import { type UseQueryResult, useQuery } from 'react-query';
import {
  OptionExerciseService,
  ReleaseService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_Transactions_ElectionHistoryDto,
} from '../../services/wealthlane-award-services';

const useGetPsuTransactionHistory = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: string
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_Transactions_ElectionHistoryDto[] | undefined,
  unknown
> => {
  const psuTransactionHistory = useQuery(['getPsuTransactionHistory'], async () => {
    return await handleRequest(
      ReleaseService.getApiAwardApiAppReleasePsuTransactionListByAwardId(id)
    );
  });

  return psuTransactionHistory;
};

export default useGetPsuTransactionHistory;
