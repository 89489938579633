import { type UseQueryResult, useQuery } from 'react-query';
import { ElectionList } from '../../constants/common';
import { ParticipantTaskService } from '../../services/wealthlane-award-services';

const useGetPsuElectionMethodSetting = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string;
    }>
  | undefined,
  unknown
> => {
  const PsuElectionMethodSetting = useQuery(
    ['getPsuElectionMethodSetting'],
    async () => {
      return await handleRequest(
        ParticipantTaskService.getApiAwardApiAppParticipantTaskPSUElectionMethodSetting()
      );
    },
    {
      select: (data: any) => {
        if (data != null) {
          const filterData = ElectionList.filter((item) => data[item.value] !== false);
          return filterData;
        }
      },
    }
  );

  return PsuElectionMethodSetting;
};

export default useGetPsuElectionMethodSetting;
