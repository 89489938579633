/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_CustomerService_Dtos_ParticipantSignUp_ParticipantEmailConfirmationDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantSignUp_ParticipantEmailConfirmationDto';
import type { Wealthlane_CustomerService_Dtos_ParticipantSignUp_ParticipantEmailVerificationDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantSignUp_ParticipantEmailVerificationDto';
import type { Wealthlane_CustomerService_Dtos_ParticipantSignUp_ParticipantInformationForSignUpDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantSignUp_ParticipantInformationForSignUpDto';
import type { Wealthlane_CustomerService_Dtos_ParticipantSignUp_ParticipantOTPVerificationDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantSignUp_ParticipantOTPVerificationDto';
import type { Wealthlane_CustomerService_Dtos_ParticipantSignUp_ParticipantSignUpCompletedResponseDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantSignUp_ParticipantSignUpCompletedResponseDto';
import type { Wealthlane_CustomerService_Dtos_ParticipantSignUp_ParticipantVerificationDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantSignUp_ParticipantVerificationDto';
import type { Wealthlane_CustomerService_Dtos_ParticipantSignUp_ParticipantVerificationResponseDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantSignUp_ParticipantVerificationResponseDto';
import type { Wealthlane_CustomerService_Dtos_ParticipantSignUp_RegisterParticipantAccountDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantSignUp_RegisterParticipantAccountDto';
import type { Wealthlane_CustomerService_Dtos_ParticipantSignUp_RegisterParticipantInformationDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantSignUp_RegisterParticipantInformationDto';
import type { Wealthlane_CustomerService_Dtos_ParticipantSignUp_RegisterParticipantLogInCredentialDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantSignUp_RegisterParticipantLogInCredentialDto';
import type { Wealthlane_CustomerService_Dtos_ParticipantSignUp_ResendParticipantOTPDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantSignUp_ResendParticipantOTPDto';
import type { Wealthlane_CustomerService_Dtos_ParticipantSignUp_ResendParticipantSignUpActivationCodeDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantSignUp_ResendParticipantSignUpActivationCodeDto';
import type { Wealthlane_CustomerService_Dtos_Response_ResponseDto } from '../models/Wealthlane_CustomerService_Dtos_Response_ResponseDto';
import type { Wealthlane_Shared_Hosting_Dtos_ParticipantSignup_ParticipantSignUpTokenAndCodeExpiryTimeDto } from '../models/Wealthlane_Shared_Hosting_Dtos_ParticipantSignup_ParticipantSignUpTokenAndCodeExpiryTimeDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ParticipantSignUpService {

    /**
     * @param formData 
     * @returns Wealthlane_CustomerService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppParticipantSignUpSubmitParticipantInformation(
formData?: {
FirstName: string;
MiddleName?: string;
LastName: string;
DateOfBirth: string;
GenderId?: string;
FullLegalName: string;
MaritalStatusId?: string;
CurrentHomeAddressCountryId: string;
CurrentHomeAddressStateId?: string;
CurrentHomeAddressStateText?: string;
CurrentHomeAddressCityText?: string;
AddressLine1: string;
CurrentHomeAddressLine2?: string;
CurrentHomeAddressZipPostalCode: string;
CurrentHomeAddressHomePhone?: string;
CurrentHomeAddressMobilePhone: string;
WorkPhone?: string;
CurrentHomeAddressMobilePhoneCallingCodeId?: string;
CurrentHomeAddressHomePhoneCallingCodeId?: string;
WorkPhoneCallingCodeId?: string;
CurrentHomeAddressStartDate: string;
MailingAddressCountryId: string;
MailingAddressStateId?: string;
MailingAddressStateText?: string;
MailingAddressCityText?: string;
MailingAddressLine1: string;
MailingAddressLine2?: string;
MailingAddressZipPostalCode: string;
EmployerName: string;
EmploymentCountryId?: string;
EmploymentStateId?: string;
EmploymentStateText?: string;
EmploymentCityText?: string;
EmploymentAddressLine1: string;
EmploymentAddressLine2?: string;
EmploymentZipPostalCode: string;
GovernmentDocumentTypeId?: string;
GovernmentDocumentIdNo?: string;
GovernmentDocumentIdIssueDate?: string;
GovernmentDocumentExpirationDate?: string;
AccountTypeId?: string;
RegistrationTypeId?: string;
EmployeedByARegisteredBrokerDealer?: boolean;
DirectorOfPolicyMaker?: boolean;
ShareHolderOfAPubliclyOwnedCompany?: boolean;
ParticipantInvestmentObjectiveId?: string;
ParticipantAnnualIncomeId?: string;
ParticipantLiquidNetWorthId?: string;
ParticipantTotalNetWorthId?: string;
ParticipantInvestmentExperienceId?: string;
NumberOfDependents?: number;
CustomerTitleId?: string;
CustomerDepartmentId?: string;
BeneficiaryFirstName: string;
BeneficiaryMiddleName?: string;
BeneficiaryLastName: string;
BeneficiaryEmail: string;
BeneficiaryMobileCallingCodeId?: string;
BeneficiaryMobileNumber: string;
BeneficiaryRelationship: string;
CostCenter: string;
isMailingAddress?: boolean;
GovernmentDocuments?: Array<Blob>;
},
): CancelablePromise<Wealthlane_CustomerService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/participant-sign-up/submit-participant-information',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_ParticipantSignUp_ParticipantVerificationResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppParticipantSignUpParticipantVerification(
requestBody?: Wealthlane_CustomerService_Dtos_ParticipantSignUp_ParticipantVerificationDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_ParticipantSignUp_ParticipantVerificationResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/participant-sign-up/participant-verification',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppParticipantSignUpRegisterParticipantLogInCredential(
requestBody?: Wealthlane_CustomerService_Dtos_ParticipantSignUp_RegisterParticipantLogInCredentialDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/participant-sign-up/register-participant-log-in-credential',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppParticipantSignUpRegisterParticipantInformation(
requestBody?: Wealthlane_CustomerService_Dtos_ParticipantSignUp_RegisterParticipantInformationDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/participant-sign-up/register-participant-information',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppParticipantSignUpParticipantEmailConfirmation(
requestBody?: Wealthlane_CustomerService_Dtos_ParticipantSignUp_ParticipantEmailConfirmationDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/participant-sign-up/participant-email-confirmation',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppParticipantSignUpRegisterParticipantAccount(
requestBody?: Wealthlane_CustomerService_Dtos_ParticipantSignUp_RegisterParticipantAccountDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/participant-sign-up/register-participant-account',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_CustomerService_Dtos_ParticipantSignUp_ParticipantSignUpCompletedResponseDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppParticipantSignUpIsParticipantSignUpCompleted(): CancelablePromise<Wealthlane_CustomerService_Dtos_ParticipantSignUp_ParticipantSignUpCompletedResponseDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/participant-sign-up/is-participant-sign-up-completed',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_CustomerService_Dtos_ParticipantSignUp_ParticipantInformationForSignUpDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppParticipantSignUpParticipantInformationForSignUp(): CancelablePromise<Wealthlane_CustomerService_Dtos_ParticipantSignUp_ParticipantInformationForSignUpDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/participant-sign-up/participant-information-for-sign-up',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppParticipantSignUpParticipantEmailVerification(
requestBody?: Wealthlane_CustomerService_Dtos_ParticipantSignUp_ParticipantEmailVerificationDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/participant-sign-up/participant-email-verification',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppParticipantSignUpParticipantOTpVerification(
requestBody?: Wealthlane_CustomerService_Dtos_ParticipantSignUp_ParticipantOTPVerificationDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/participant-sign-up/participant-oTPVerification',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppParticipantSignUpResendParticipantOTp(
requestBody?: Wealthlane_CustomerService_Dtos_ParticipantSignUp_ResendParticipantOTPDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/participant-sign-up/resend-participant-oTP',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppParticipantSignUpResendParticipantSignUpActivationCode(
requestBody?: Wealthlane_CustomerService_Dtos_ParticipantSignUp_ResendParticipantSignUpActivationCodeDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/participant-sign-up/resend-participant-sign-up-activation-code',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_Shared_Hosting_Dtos_ParticipantSignup_ParticipantSignUpTokenAndCodeExpiryTimeDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppParticipantSignUpParticipantSignUpTokenAndCodeExpiryTime(): CancelablePromise<Wealthlane_Shared_Hosting_Dtos_ParticipantSignup_ParticipantSignUpTokenAndCodeExpiryTimeDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/participant-sign-up/participant-sign-up-token-and-code-expiry-time',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
