import React, { type PropsWithChildren, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ModelBody } from './modal.styled';
import SlideUpAnimation from '../animation/slideUp';
import { selectNotify } from '../../auth/notify';

interface ModalBodyComponentType {
  showFooter?: boolean;
  isCenter?: boolean;
  subTitle?: boolean;
  className?: string;
  closeModal?: any;
}

const ModalBodyComponent = ({
  className,
  showFooter = true,
  isCenter,
  children,
  closeModal,
  subTitle,
}: PropsWithChildren<ModalBodyComponentType>): JSX.Element => {
  const showNotify = useSelector(selectNotify);
  const clicking = useRef<any>(null);

  useEffect(() => {
    function handleClickOutside(event: Event): void {
      const checkValue = (event?.target as HTMLElement).className?.includes('notification-modal')
        ? false
        : Boolean(clicking.current) &&
          !(clicking.current as HTMLElement).contains(event.target as Node);
      if (checkValue) {
        closeModal?.();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [clicking]);

  return (
    <SlideUpAnimation>
      <ModelBody
        showNotify={showNotify}
        showFooter={showFooter}
        subTitle={subTitle}
        ref={clicking}
        className={`${className ?? ''} ${
          isCenter != null ? 'flex justify-center items-center' : ''
        }`}>
        {children}
      </ModelBody>
    </SlideUpAnimation>
  );
};

export default ModalBodyComponent;
