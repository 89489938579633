/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_CustomerService_Dtos_UserDefinedFieldValues_CreateUserDefinedFieldValuesDto } from '../models/Wealthlane_CustomerService_Dtos_UserDefinedFieldValues_CreateUserDefinedFieldValuesDto';
import type { Wealthlane_CustomerService_Dtos_UserDefinedFieldValues_UpdateUserDefinedFieldValuesDto } from '../models/Wealthlane_CustomerService_Dtos_UserDefinedFieldValues_UpdateUserDefinedFieldValuesDto';
import type { Wealthlane_CustomerService_Dtos_UserDefinedFieldValues_UserDefinedFieldValuesDto } from '../models/Wealthlane_CustomerService_Dtos_UserDefinedFieldValues_UserDefinedFieldValuesDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserDefinedFieldValuesService {

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_UserDefinedFieldValues_CreateUserDefinedFieldValuesDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppUserDefinedFieldValuesUserDefinedFieldValues(
requestBody?: Array<Wealthlane_CustomerService_Dtos_UserDefinedFieldValues_CreateUserDefinedFieldValuesDto>,
): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_UserDefinedFieldValues_CreateUserDefinedFieldValuesDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/user-defined-field-values/user-defined-field-values',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_UserDefinedFieldValues_UpdateUserDefinedFieldValuesDto Success
     * @throws ApiError
     */
    public static putApiCustomerApiAppUserDefinedFieldValuesUserDefinedFieldValues(
requestBody?: Array<Wealthlane_CustomerService_Dtos_UserDefinedFieldValues_UpdateUserDefinedFieldValuesDto>,
): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_UserDefinedFieldValues_UpdateUserDefinedFieldValuesDto>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/customer/api/app/user-defined-field-values/user-defined-field-values',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param moduleId 
     * @param moduleFormId 
     * @returns Wealthlane_CustomerService_Dtos_UserDefinedFieldValues_UserDefinedFieldValuesDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppUserDefinedFieldValuesUserDefinedFieldValues(
moduleId?: number,
moduleFormId?: string,
): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_UserDefinedFieldValues_UserDefinedFieldValuesDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/user-defined-field-values/user-defined-field-values',
            query: {
                'ModuleId': moduleId,
                'ModuleFormId': moduleFormId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns boolean Success
     * @throws ApiError
     */
    public static deleteApiCustomerApiAppUserDefinedFieldValues(
id?: string,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/customer/api/app/user-defined-field-values',
            query: {
                'Id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
