/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsListDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsListDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_CustomerService_Dtos_UserDefinedFields_UpdateUserDefinedFieldsDto } from '../models/Wealthlane_CustomerService_Dtos_UserDefinedFields_UpdateUserDefinedFieldsDto';
import type { Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsByModuleIdDto } from '../models/Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsByModuleIdDto';
import type { Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsDto } from '../models/Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsDto';
import type { Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsGetDto } from '../models/Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsGetDto';
import type { Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedModulesDto } from '../models/Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedModulesDto';
import type { Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedStatusDto } from '../models/Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedStatusDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserDefinedFieldsService {

    /**
     * @returns Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedModulesDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppUserDefinedFieldsUserDefinedModules(): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedModulesDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/user-defined-fields/user-defined-modules',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedStatusDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppUserDefinedFieldsUserDefinedStatus(): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/user-defined-fields/user-defined-status',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppUserDefinedFieldsUserDefinedFields(
requestBody?: Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/user-defined-fields/user-defined-fields',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsGetDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppUserDefinedFieldsUserDefinedFieldById(
id: string,
): CancelablePromise<Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsGetDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/user-defined-fields/user-defined-field-by-id/{Id}',
            path: {
                'Id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_UserDefinedFields_UpdateUserDefinedFieldsDto Success
     * @throws ApiError
     */
    public static putApiCustomerApiAppUserDefinedFieldsUserDefinedField(
requestBody?: Wealthlane_CustomerService_Dtos_UserDefinedFields_UpdateUserDefinedFieldsDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_UserDefinedFields_UpdateUserDefinedFieldsDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/customer/api/app/user-defined-fields/user-defined-field',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param moduleId 
     * @param statusId 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsListDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppUserDefinedFieldsUserDefinedFieldsList(
moduleId?: number,
statusId?: number,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsListDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/user-defined-fields/user-defined-fields-list',
            query: {
                'ModuleId': moduleId,
                'StatusId': statusId,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns boolean Success
     * @throws ApiError
     */
    public static deleteApiCustomerApiAppUserDefinedFields(
id?: string,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/customer/api/app/user-defined-fields',
            query: {
                'Id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param moduleId 
     * @returns Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsByModuleIdDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppUserDefinedFieldsUserDefinedFieldsByModuleId(
moduleId: number,
): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsByModuleIdDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/user-defined-fields/user-defined-fields-by-module-id/{ModuleId}',
            path: {
                'ModuleId': moduleId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
