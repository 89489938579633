import { lazy } from 'react';

export const RouteList = [
  {
    path: '/profile',
    component: lazy(async () => await import('../pages/profile')),
    exact: true,
  },
  {
    path: '/profile/update',
    component: lazy(
      async () => await import('../pages/profile/profile-info/personal/personalForm')
    ),
    exact: true,
  },
  {
    path: '/financial-institution',
    component: lazy(async () => await import('../pages/financial-institution')),
    exact: true,
  },
  {
    path: '/settings',
    component: lazy(async () => await import('../pages/settings')),
    exact: true,
  },
  {
    path: '/overview',
    component: lazy(async () => await import('../pages/overview')),
    exact: true,
  },
  {
    path: '/elect-form',
    component: lazy(async () => await import('../pages/election-method/electForm')),
    exact: true,
  },
  {
    path: '/onboarding',
    component: lazy(async () => await import('../pages/onboarding/index')),
    exact: true,
  },
  {
    path: '/onboarding/success',
    component: lazy(
      async () => await import('../pages/onboarding/onboarding-steps/onboardingStepFinalLoader')
    ),
    exact: true,
  },
  {
    path: '/signup-check',
    component: lazy(async () => await import('../auth/signUpCheck')),
    exact: true,
  },
  {
    path: '/holdings',
    component: lazy(async () => await import('../pages/my-holdings')),
    exact: true,
  },
  {
    path: '/transactions/transaction-history',
    component: lazy(async () => await import('../pages/transactions/transaction-history/index')),
    exact: true,
  },
  {
    path: '/transactions/cash',
    component: lazy(async () => await import('../pages/transactions/cash-transfer')),
    exact: true,
  },
  {
    path: '/transactions/Share',
    component: lazy(async () => await import('../pages/transactions/share-transfer')),
    exact: true,
  },
  {
    path: '/release-transactions',
    component: lazy(async () => await import('../pages/release-transactions')),
    exact: true,
  },
  {
    path: '/holdings/holding-details',
    component: lazy(async () => await import('../pages/my-holdings/holdingDetails')),
    exact: true,
  },
  {
    path: '/election-method',
    component: lazy(async () => await import('../pages/election-method')),
    exact: true,
  },
  {
    path: '/psu-election-method',
    component: lazy(async () => await import('../pages/psu-election-method')),
    exact: true,
  },
  {
    path: '/option-exercise',
    component: lazy(async () => await import('../pages/option-election-method')),
    exact: true,
  },
  {
    path: '/exercise-option',
    component: lazy(async () => await import('../pages/exercise-option')),
    exact: true,
  },
  {
    path: '/sell-share',
    component: lazy(async () => await import('../pages/sell-share')),
    exact: true,
  },
  // {
  //   path: '/election-method/:id',
  //   component: lazy(() => import('../pages/ElectionMethod')),
  //   exact: true,
  // },
  {
    path: '/notification',
    component: lazy(async () => await import('../pages/notification')),
    exact: true,
  },
  {
    path: '/w8-form',
    component: lazy(async () => await import('../pages/tax-certification/formTemplateEight')),
    exact: true,
  },
  {
    path: '/w9-form',
    component: lazy(async () => await import('../pages/tax-certification/formTemplateNine')),
    exact: true,
  },
  {
    path: '/w8-pdf',
    component: lazy(async () => await import('../pages/tax-certification/pdf/w8Pdf')),
    exact: true,
  },
  {
    path: '/pre-clearance-request',
    component: lazy(async () => await import('../pages/overview/pre-clearance/request')),
    exact: true,
  },
  // Page Not Found
  {
    path: '/page-not-found',
    component: lazy(async () => await import('../pages/pageNotFound')),
    exact: true,
  },
];
