import { type UseQueryResult, useQuery } from 'react-query';
import {
  MyHoldingsService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_OptionAward_OptionDocumentDto,
} from '../../services/wealthlane-award-services';

const useGetOptionAwardDocuments = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_OptionAward_OptionDocumentDto[] | undefined,
  unknown
> => {
  const optionAwardDocuments = useQuery(
    ['getOptionAwardDocuments', id],
    async () => {
      if (id !== undefined && id !== null && id !== '') {
        return await handleRequest(
          MyHoldingsService.getApiAwardApiAppMyHoldingsOptionAwardDocuments(id)
        );
      }
    },
    {
      enabled: typeof id === 'string' && id !== '',
    }
  );

  return optionAwardDocuments;
};

export default useGetOptionAwardDocuments;
