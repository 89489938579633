import { type UseQueryResult, useQuery } from 'react-query';
import { NotificationService } from '../../services/wealthlane-notification-services';
import { MyHoldingsService } from '../../services/wealthlane-award-services';

const useGetParticipantCash = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
) => {
  const participantCashList = useQuery(['getGetParticipantCashList'], async () =>
    handleRequest(MyHoldingsService.getApiAwardApiAppMyHoldingsParticipantCash())
  );
  return participantCashList;
};

export default useGetParticipantCash;
