/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_TaxService_Dtos_ParticipantTransaction_ParticipantDetailTransaction } from '../models/Wealthlane_TaxService_Dtos_ParticipantTransaction_ParticipantDetailTransaction';
import type { Wealthlane_TaxService_Dtos_ParticipantTransaction_ParticipantTransaction } from '../models/Wealthlane_TaxService_Dtos_ParticipantTransaction_ParticipantTransaction';
import type { Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationResponseDto } from '../models/Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TaxCalculationService {

    /**
     * @param requestBody 
     * @returns Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationResponseDto Success
     * @throws ApiError
     */
    public static postApiTaxApiAppTaxCalculationParticipantTaxDetailForCompany(
requestBody?: Wealthlane_TaxService_Dtos_ParticipantTransaction_ParticipantDetailTransaction,
): CancelablePromise<Array<Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationResponseDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tax/api/app/tax-calculation/participant-tax-detail-for-company',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationResponseDto Success
     * @throws ApiError
     */
    public static postApiTaxApiAppTaxCalculationParticipantTaxDetailForParticipant(
requestBody?: Wealthlane_TaxService_Dtos_ParticipantTransaction_ParticipantDetailTransaction,
): CancelablePromise<Array<Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationResponseDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tax/api/app/tax-calculation/participant-tax-detail-for-participant',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationResponseDto Success
     * @throws ApiError
     */
    public static postApiTaxApiAppTaxCalculationTaxCalculation(
requestBody?: Wealthlane_TaxService_Dtos_ParticipantTransaction_ParticipantTransaction,
): CancelablePromise<Array<Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationResponseDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tax/api/app/tax-calculation/tax-calculation',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
