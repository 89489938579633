import { type UseQueryResult, useQuery } from 'react-query';
import {
  StockService,

  // eslint-disable-next-line camelcase
  type Wealthlane_FinanceIntegrationService_Dtos_StockDto_StockDto,
} from '../../services/wealthlane-finance-integration-services';

const useGetStockForCustomerDetails = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  filterData: {
    fromDate: string;
    toDate: string;
  }
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_FinanceIntegrationService_Dtos_StockDto_StockDto | undefined,
  unknown
> => {
  const { fromDate, toDate } = filterData;
  const stockApi = useQuery(['getStockForCustomerDataDetails'], async () => {
    return await handleRequest(
      StockService.getApiFinanceIntegrationApiAppStockStocks(fromDate, toDate)
    );
  });
  return stockApi;
};

export default useGetStockForCustomerDetails;
