import { type UseQueryResult, useQuery } from 'react-query';
import {
  NotificationService,
  // eslint-disable-next-line camelcase
  type Wealthlane_NotificationService_DTOs_BellNotificationDto,
} from '../../services/wealthlane-notification-services';

const useGetUnseenNotification = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_NotificationService_DTOs_BellNotificationDto[] | undefined,
  unknown
> => {
  const unseenNotificationList = useQuery(['getGetUnseenNotificationList'], async () => {
    return await handleRequest(
      NotificationService.getApiNotificationApiAppNotificationNotifications()
    );
  });
  return unseenNotificationList;
};

export default useGetUnseenNotification;
