import styled, { css } from 'styled-components';
import { COLORS } from '../../constants/style/color';
import { getRgbaValue, rem } from '../../utils/style';

interface ButtonType {
  // remove string as type
  variant:
    | 'primary'
    | 'primary-outline'
    | 'primary-dark'
    | 'secondary'
    | 'secondary-dark'
    | 'links'
    | 'rounded'
    | string;
}

export const SecondaryButton = (disabledColor: string): any => {
  return css`
    text-align: left;
    font-size: ${rem(14)};
    line-height: ${rem(16)};
    padding: ${rem(8)} ${rem(16)};
    border: ${rem(1)} solid transparent;
    min-width: ${rem(67)};

    > span {
      font-size: ${rem(16)};
      padding-right: ${rem(2)};
    }

    &:disabled {
      color: ${getRgbaValue(`${disabledColor}`, 0.25)};
    }
  `;
};

export const Btn = styled.div<ButtonType>`
  font-weight: 500;
  font-size: ${rem(16)};
  line-height: ${rem(22)};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: ${rem(6)};
  padding: ${rem(10)} ${rem(20)};
  transition: all 300ms ease;
  white-space: nowrap;

  background-position: center;
  transition: background 0.8s;

  > span {
    font-size: ${rem(20)};
    padding-right: ${rem(4)};
    margin-top: 0px !important;
  }

  ${(props) => {
    if (props.variant === 'rounded') {
      return css`
        border-radius: ${rem(33)};
        background: ${COLORS.Iris};
        color: ${COLORS.White};
        padding: ${rem(7)} ${rem(14)};
        font-size: ${rem(12)};

        &:not(:disabled):hover {
          box-shadow: ${rem(0)} ${rem(4)} ${rem(6)} ${COLORS.FloatingButtonShadow};
          background: ${COLORS.Iris} radial-gradient(circle, transparent 1%, ${COLORS.Iris} 1%)
            center/15000%;
        }

        &:not(:disabled):active {
          box-shadow: ${rem(0)} ${rem(1)} ${rem(4)} ${COLORS.PressedButtonShadow};
          background-color: ${COLORS.LightPurple};
          background-size: 100%;
          transition: background 0s;
        }

        &:disabled {
          background-color: ${COLORS.CulturedWhite};
          color: ${getRgbaValue(`${COLORS.Silver}`, 0.5)};
        }
      `;
    }

    if (props.variant === 'primary') {
      return css`
        background: ${COLORS.Purple};
        color: ${COLORS.White};

        &:not(:disabled):hover {
          box-shadow: ${rem(0)} ${rem(4)} ${rem(6)} ${COLORS.FloatingButtonShadow};
          background: ${COLORS.Purple} radial-gradient(circle, transparent 1%, ${COLORS.Purple} 1%)
            center/15000%;
        }

        &:not(:disabled):active {
          box-shadow: ${rem(0)} ${rem(1)} ${rem(4)} ${COLORS.PressedButtonShadow};
          background-color: ${COLORS.LightPurple};
          background-size: 100%;
          transition: background 0s;
        }

        &:disabled {
          background-color: ${COLORS.CulturedWhite};
          color: ${getRgbaValue(`${COLORS.Silver}`, 0.5)};
        }
      `;
    }

    if (props.variant === 'lightPrimary') {
      return css`
        background: ${COLORS.LightPurple};
        color: ${COLORS.Purple};

        &:not(:disabled):hover {
          box-shadow: ${rem(0)} ${rem(4)} ${rem(6)} ${getRgbaValue(`${COLORS.Iris}`, 0.24)};
        }

        &:not(:disabled):active {
          box-shadow: ${rem(0)} ${rem(1)} ${rem(4)} ${getRgbaValue(`${COLORS.Iris}`, 0.44)};
        }

        &:disabled {
          background-color: ${COLORS.CulturedWhite};
          color: ${getRgbaValue(`${COLORS.Silver}`, 0.5)};
        }
      `;
    }

    if (props.variant === 'primary-outline') {
      return css`
        background: ${COLORS.White};
        color: ${COLORS.Purple};
        border: ${rem(1)} solid ${COLORS.Purple};
        padding: ${rem(9)} ${rem(19)};

        &:not(:disabled):hover {
          box-shadow: ${rem(0)} ${rem(4)} ${rem(6)} ${getRgbaValue(`${COLORS.Iris}`, 0.24)};
        }

        &:not(:disabled):active {
          box-shadow: ${rem(0)} ${rem(1)} ${rem(4)} ${getRgbaValue(`${COLORS.Iris}`, 0.44)};
        }

        &:disabled {
          border: 1px solid ${getRgbaValue(`${COLORS.Purple}`, 0.2)};
          color: ${getRgbaValue(`${COLORS.Purple}`, 0.5)};
        }
      `;
    }

    if (props.variant === 'primary-dark') {
      return css`
        background: ${COLORS.White};
        color: ${COLORS.Black};
        border: ${rem(1)} solid ${COLORS.LightGray};
        padding: ${rem(9)} ${rem(19)};
        box-shadow: ${rem(0)} ${rem(2)} ${rem(4)} ${getRgbaValue(`${COLORS.Gray500}`, 0.16)};

        &:not(:disabled):hover {
          border: ${rem(1)} solid ${COLORS.SpanishGray};
          box-shadow: ${rem(0)} ${rem(4)} ${rem(6)} ${getRgbaValue(`${COLORS.Gray100}`, 0.24)};
        }

        &:not(:disabled):active {
          border: ${rem(1)} solid ${COLORS.SpanishGray};
          box-shadow: ${rem(0)} ${rem(1)} ${rem(4)} ${getRgbaValue(`${COLORS.Gray100}`, 0.44)};
        }

        &:disabled {
          background-color: ${COLORS.OffWhite};
          color: ${getRgbaValue(`${COLORS.Silver}`, 0.5)};
        }
      `;
    }

    if (props.variant === 'secondary') {
      return css`
        @include ${SecondaryButton(COLORS.Iris)};
        background: ${COLORS.LightPurple};
        color: ${COLORS.Purple};

        &:not(:disabled):active {
          border: ${rem(1)} solid ${getRgbaValue(`${COLORS.Iris}`, 0.3)};
          background-color: ${COLORS.LightPurple};
          background-size: 100%;
          transition: background 0s;
        }

        &:not(:disabled):hover {
          box-shadow: ${rem(0)} ${rem(4)} ${rem(3)} ${rem(0)}
            ${getRgbaValue(`${COLORS.Gray200}`, 0.08)};
          background: ${COLORS.LightPurple}
            radial-gradient(circle, transparent 1%, ${COLORS.LightPurple} 1%) center/15000%;
        }
      `;
    }

    if (props.variant === 'secondary-dark') {
      return css`
        @include ${SecondaryButton(COLORS.Gray)};
        background: ${COLORS.OffWhite};
        color: ${COLORS.Gray};
        border: 1px solid ${COLORS.LightGray};

        &:not(:disabled):active {
          border: ${rem(1)} solid ${getRgbaValue(`${COLORS.LightGray}`, 0.5)};
        }
      `;
    }

    if (props.variant === 'secondary-white') {
      return css`
        @include ${SecondaryButton(COLORS.Gray)};
        background: ${COLORS.White};
        border: ${rem(1)} solid ${COLORS.LightGray};
        color: ${COLORS.Gray};

        &:not(:disabled):active {
          color: ${COLORS.Black};
          border: ${rem(1)} solid ${COLORS.SpanishGray};
        }

        &:not(:disabled):hover {
          border: ${rem(1)} solid ${COLORS.SpanishGray};
          box-shadow: ${rem(0)} ${rem(4)} ${rem(6)} ${getRgbaValue(`${COLORS.Gray100}`, 0.24)};
        }
      `;
    }

    if (props.variant === 'secondary-outline') {
      return css`
        @include ${SecondaryButton(COLORS.Gray)};
        background: ${COLORS.White};
        padding: ${rem(8)};
        border: ${rem(1)} solid ${getRgbaValue(`${COLORS.Iris}`, 0.3)};
        color: ${COLORS.Purple};

        &:not(:disabled):hover {
          box-shadow: ${rem(0)} ${rem(4)} ${rem(6)} ${getRgbaValue(`${COLORS.Iris}`, 0.24)};
        }

        &:not(:disabled):active {
          box-shadow: ${rem(0)} ${rem(1)} ${rem(4)} ${getRgbaValue(`${COLORS.Iris}`, 0.44)};
        }

        &:disabled {
          border: 1px solid ${getRgbaValue(`${COLORS.Purple}`, 0.2)};
          color: ${getRgbaValue(`${COLORS.Purple}`, 0.5)};
        }
      `;
    }

    if (props.variant === 'error') {
      return css`
        @include ${SecondaryButton(COLORS.Gray)};
        background: ${COLORS.OffWhite};
        color: ${COLORS.Black};

        span {
          color: ${COLORS.Silver};
        }

        &:not(:disabled):hover {
          color: ${COLORS.Red};
          background: ${COLORS.LightRed};

          span {
            color: ${COLORS.Red};
          }
        }

        &:not(:disabled):active {
          color: ${COLORS.Red};
          background: ${COLORS.LightRed};
          border: ${rem(1)} solid ${COLORS.Red};

          span {
            color: ${COLORS.Red};
          }
        }

        &:disabled {
          border: ${rem(1)} solid ${getRgbaValue(`${COLORS.Iris}`, 0.25)};

          span {
            opacity: 0.25;
            color: ${COLORS.SpanishGray};
          }
        }
      `;
    }

    if (props.variant === 'success') {
      return css`
        @include ${SecondaryButton(COLORS.Gray)};
        background: ${COLORS.OffWhite};
        color: ${COLORS.Black};

        span {
          color: ${COLORS.Silver};
        }

        &:not(:disabled):hover {
          color: ${COLORS.Green};
          background: ${COLORS.LightGreen};

          span {
            color: ${COLORS.Green};
          }
        }

        &:not(:disabled):active {
          color: ${COLORS.Green};
          background: ${COLORS.LightGreen};
          border: ${rem(1)} solid ${COLORS.Green};

          span {
            color: ${COLORS.Green};
          }
        }

        &:disabled {
          background-color: ${COLORS.OffWhite};
          border: ${rem(1)} solid ${COLORS.LightGray};

          span {
            opacity: 0.25;
            color: ${COLORS.SpanishGray};
          }
        }
      `;
    }

    if (props.variant === 'success-outline') {
      return css`
        @include ${SecondaryButton(COLORS.Gray)};
        background-color: ${COLORS.White};
        color: ${COLORS.Black};
        border: ${rem(1)} solid ${COLORS.LightGray};

        span {
          color: ${COLORS.Silver};
        }

        &:not(:disabled):hover {
          color: ${COLORS.Green};
          border: ${rem(1)} solid ${COLORS.Green};

          span {
            color: ${COLORS.Green};
          }
        }

        &:not(:disabled):active {
          color: ${COLORS.Green};
          background: ${COLORS.LightGreen};
          border: ${rem(1)} solid ${COLORS.Green};

          span {
            color: ${COLORS.Green};
          }
        }

        &:disabled {
          background-color: ${COLORS.OffWhite};
          border: ${rem(1)} solid ${COLORS.LightGray};

          span {
            opacity: 0.25;
            color: ${COLORS.SpanishGray};
          }
        }
      `;
    }

    if (props.variant === 'link') {
      return css`
        color: ${COLORS.Iris};
        text-decoration: underline;
        font-size: ${rem(14)};
        line-height: ${rem(20)};
        padding: 0;
        font-weight: 700;

        &:not(:disabled):hover {
          box-shadow: none;
        }

        &:not(:disabled):active {
          box-shadow: none;
        }

        &:disabled {
          color: ${COLORS.SpanishGray};
        }
      `;
    }
  }};
`;
