/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_ParticipantInvestmentExperience_ParticipantInvestmentExperienceDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_ParticipantInvestmentExperience_ParticipantInvestmentExperienceDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_CustomerService_Dtos_ParticipantInvestmentExperience_CreateUpdateParticipantInvestmentExperienceDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantInvestmentExperience_CreateUpdateParticipantInvestmentExperienceDto';
import type { Wealthlane_CustomerService_Dtos_ParticipantInvestmentExperience_ParticipantInvestmentExperienceDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantInvestmentExperience_ParticipantInvestmentExperienceDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ParticipantInvestmentExperienceService {

    /**
     * @returns Wealthlane_CustomerService_Dtos_ParticipantInvestmentExperience_ParticipantInvestmentExperienceDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppParticipantInvestmentExperienceParticipantInvestmentExperiencesList(): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_ParticipantInvestmentExperience_ParticipantInvestmentExperienceDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/participant-investment-experience/participant-investment-experiences-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_ParticipantInvestmentExperience_ParticipantInvestmentExperienceDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppParticipantInvestmentExperience(
requestBody?: Wealthlane_CustomerService_Dtos_ParticipantInvestmentExperience_CreateUpdateParticipantInvestmentExperienceDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_ParticipantInvestmentExperience_ParticipantInvestmentExperienceDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/participant-investment-experience',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_ParticipantInvestmentExperience_ParticipantInvestmentExperienceDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppParticipantInvestmentExperience(
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_ParticipantInvestmentExperience_ParticipantInvestmentExperienceDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/participant-investment-experience',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_ParticipantInvestmentExperience_ParticipantInvestmentExperienceDto Success
     * @throws ApiError
     */
    public static putApiCustomerApiAppParticipantInvestmentExperience(
id: string,
requestBody?: Wealthlane_CustomerService_Dtos_ParticipantInvestmentExperience_CreateUpdateParticipantInvestmentExperienceDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_ParticipantInvestmentExperience_ParticipantInvestmentExperienceDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/customer/api/app/participant-investment-experience/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiCustomerApiAppParticipantInvestmentExperience(
id: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/customer/api/app/participant-investment-experience/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_CustomerService_Dtos_ParticipantInvestmentExperience_ParticipantInvestmentExperienceDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppParticipantInvestmentExperience1(
id: string,
): CancelablePromise<Wealthlane_CustomerService_Dtos_ParticipantInvestmentExperience_ParticipantInvestmentExperienceDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/participant-investment-experience/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
