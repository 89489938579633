import Heading from '../../../components/typography';
import { ContentBody, ContentContainer, ContentHeader } from './signUpSteps.styled';

import { ErrorMessage, Form, Formik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import Button from '../../../components/button';
import { Label } from '../../../components/input-text/input.styled';
import InputText from '../../../components/input-text/inputText';
import InitialValuesForm from '../../../constants/initialValues';
import { ParticipantSignUpService } from '../../../services/wealthlane-customer-services';
import { ErrorMessageWrapper } from '../../../constants/style/common.styled';
import { INPUT_DATE_FORMAT, YesNoOptions } from '../../../constants/common';
import CustomDatePicker from '../../../components/date-picker/customDatePicker';
import ModalBodyComponent from '../../../components/modal/modalBodyComponent';
import { useGetToken, useWealthlaneCustomerApi } from '../../../hooks';
import { STEPONE_VALIDATION } from '../../../utils/validations/validations';
import { COLORS } from '../../../constants/style/color';
import { toast } from 'react-toastify';
import ToastComponent from '../../../components/toast';
import useGetCodeExpiryTime from '../../../hooks/get/useGetCodeExpiryTime';
import CustomerRadio from '../../../components/radio-button/customRadio';

interface StepOneType {
  setStep: (value: number) => void;
  formData?: any;
  setFormData?: any;
}

const StepOne = ({ setStep }: StepOneType): JSX.Element => {
  const { handleRequest, isLoading } = useWealthlaneCustomerApi();
  const query = useGetToken();
  const tokenValue = query.get('token');
  const data = useGetCodeExpiryTime(handleRequest);

  const [isCitizen, setIsUsCitizen] = useState(false);

  const checkResident = (values: any): void => {
    if (values.isUsCitizen === 'Yes') {
      setIsUsCitizen(true);
    } else {
      setIsUsCitizen(false);
    }
  };

  const formValues = window.sessionStorage.getItem('step1');
  useEffect(() => {
    if (tokenValue != null) {
      window.sessionStorage.setItem('token', tokenValue?.split(' ').join('+'));
    }
  }, [tokenValue]);

  function handleSubmit(values: any): void {
    let token = window.sessionStorage.getItem('token');
    const payload = {
      ...values,
      dateOfBirth: moment(values?.dateOfBirth).format(INPUT_DATE_FORMAT),
      hireDate: moment(values?.hireDate).format(INPUT_DATE_FORMAT),
    };
    handleRequest(
      ParticipantSignUpService.postApiCustomerApiAppParticipantSignUpParticipantVerification({
        ...payload,
        token,
        isUsCitizen: values.isUsCitizen === 'Yes',
      })
    )
      .then((response: any) => {
        if (response != null) {
          const step1 = {
            ...response,
            ...payload,
            companyTickerSymbol: values.companyTickerSymbol,
            dateOfBirth: moment(response.dateOfBirth).format('YYYY-MM-DD'),
            residenceStatusId: values.residenceStatusId,
            isUsCitizen: values.isUsCitizen === 'Yes',
            token,
          };
          window.sessionStorage.setItem('step1', JSON.stringify(step1));
          setStep(2);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function ResendActivationCode(): void {
    const payload = { token: tokenValue?.split(' ').join('+') };
    handleRequest(
      ParticipantSignUpService.postApiCustomerApiAppParticipantSignUpResendParticipantSignUpActivationCode(
        payload
      )
    )
      .then((response: any) => {
        if (response != null) {
          toast.success(<ToastComponent label='Success' message={'Resend activation code.'} />);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <ContentContainer>
      <ContentHeader>
        <Heading variant={'h4'} title={'Verify Your Identity'} className='title' />
      </ContentHeader>
      <ContentBody>
        <div>
          <Formik
            enableReinitialize
            // initialValues={{
            //   ...InitialValuesForm.SignUp.stepOne,
            //   ...JSON.parse(formValues as any),
            //   isUsCitizen: ` ${
            //     JSON.parse(formValues as any)?.isUsCitizen &&
            //     JSON.parse(formValues as any)?.isUsCitizen
            //   } ? 'Yes' : 'No'
            //     }`,
            // }}
            initialValues={{
              ...InitialValuesForm.SignUp.stepOne,
              ...JSON.parse(formValues as any),
              isUsCitizen:
                JSON.parse(formValues as any)?.isUsCitizen &&
                JSON.parse(formValues as any)?.isUsCitizen === false
                  ? 'No'
                  : 'Yes',
            }}
            validationSchema={
              !isCitizen
                ? Yup.object().shape({
                    ...STEPONE_VALIDATION,
                    employeeId: Yup.string()
                      .required('Required field')
                      .matches(/^[a-zA-Z0-9-_ ]+$/, 'Must be only digits')
                      .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces'),
                  })
                : Yup.object().shape({
                    ...STEPONE_VALIDATION,
                    socialSecurityNumber: Yup.string()
                      .max(4, 'Maximum 4 dight only')
                      .required('Required field')
                      .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
                      .nullable(),
                  })
            }
            onSubmit={handleSubmit}>
            {({ handleChange, errors, touched, values, setFieldValue }) => {
              return (
                <Form>
                  <ModalBodyComponent>
                    <div className=''>
                      <div className=''>
                        <div className='mb-7'>
                          <Label>Are you a U.S. Citizen or Resident Alien?</Label>
                          <div className='flex gap-x-8'>
                            {YesNoOptions?.map((item) => (
                              <CustomerRadio
                                name={'isUsCitizen'}
                                label={item.label}
                                onChange={handleChange}
                                value={item.value}
                                errors={errors}
                                touched={touched}
                                checked={values.isUsCitizen === item.value}
                                id={item.label}
                              />
                            ))}
                          </div>
                          <ErrorMessageWrapper className='mt-2'>
                            <ErrorMessage
                              name={'residenceStatusId'}
                              component='div'
                              className='field-error-message mt-1'
                            />
                          </ErrorMessageWrapper>
                        </div>
                        <div className='flex flex-col sm:flex-row gap-x-6'>
                          {
                            (checkResident(values),
                            values.isUsCitizen === 'No' && (
                              <div>
                                <InputText
                                  type='text'
                                  label='Employee ID'
                                  name='employeeId'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  width={'110px'}
                                  required
                                />
                              </div>
                            ))
                          }
                          {values.isUsCitizen === 'Yes' && (
                            <div>
                              <InputText
                                type='text'
                                label='Last 4 digit of SSN'
                                name='socialSecurityNumber'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                width={'130px'}
                                required
                              />
                            </div>
                          )}
                          <div className='mb-7'>
                            <CustomDatePicker
                              label='Date of Birth'
                              name='dateOfBirth'
                              // onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              values={values}
                              setFieldValue={setFieldValue}
                              required
                            />
                          </div>
                          <CustomDatePicker
                            label='Hire Date'
                            name='hireDate'
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            values={values}
                            width={'184px'}
                            required
                          />
                        </div>
                        <div className='flex flex-col sm:flex-row gap-4 sm:gap-x-6'>
                          <InputText
                            type='text'
                            className='mb-0'
                            label='Activation Code'
                            name='code'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            width={'115px'}
                            required
                          />
                          <InputText
                            type='text'
                            className='mb-0'
                            label='Company Ticker'
                            name='companyTickerSymbol'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            width={'184px'}
                            required
                          />
                        </div>
                        {data.data?.codeExpiryTime != null && (
                          <div className='flex gap-x-2 mt-5'>
                            <Heading
                              variant='body-s'
                              title={`Your activation code will expire in ${
                                data.data?.codeExpiryTime
                              }  ${data.data?.codeExpiryTime === 1 ? 'hour' : 'hours'}.`}
                              color={COLORS.Gray}
                            />
                            <Button
                              // as='Create'
                              variant={'link'}
                              title={'Resend Code'}
                              type='button'
                              onClick={() => {
                                ResendActivationCode();
                              }}
                              // loading={isLoading}
                            />
                          </div>
                        )}
                      </div>

                      <div className='mt-12'>
                        <Button
                          as='Create'
                          variant={'primary'}
                          title={'Verify & Continue'}
                          type='submit'
                          loading={isLoading}
                        />
                      </div>
                    </div>
                  </ModalBodyComponent>
                </Form>
              );
            }}
          </Formik>
        </div>
      </ContentBody>
    </ContentContainer>
  );
};

export default StepOne;
