import styled from 'styled-components';
import { COLORS } from '../../constants/style/color';
import { rem } from '../../utils/style';

export const ListContainer = styled.div`
  width: 100%;
  border-radius: 10px;
  padding: 27px 25px;
  background-color: ${COLORS.White};
`;

export const ListWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 27px;
  border-bottom: 1px solid ${COLORS.Grey500Transparent};
  padding: 23px 13px;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  &:last-child {
    border-bottom: none;
  }

  .icon-wrap {
    padding: 1px;
    background-color: ${COLORS.White};
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    span {
      background-color: ${COLORS.Gray50};
      border-radius: 50%;
      height: 34px;
      width: 34px;

      padding: 5px;

      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        height: 20px;
        width: 20px;
      }

      &.active {
        background-color: ${COLORS.Secondary};
      }
    }
  }

  .process-details {
    h2 {
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      color: ${COLORS.Gray60};
      margin-bottom: 6px;

      &.active {
        color: ${COLORS.Secondary};
        font-weight: 700;
      }
    }

    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: ${COLORS.Gray1000};
      margin-bottom: 6px;
      text-align: justify;

      @media (min-width: 768px) {
        text-align: left;
      }
    }
  }
`;

export const StepperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 40px 10% 0 10%;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    border: 1px dashed #5bbcc9;
    width: 100%;
    height: 1px;
    transform: translate(0, -50%);
  }

  .check-icon {
    position: absolute;
    left: 14px;
    top: -55%;
  }
`;

export const FormContainer = styled.div`
  margin: auto;
  margin-top: 20px;
  width: 100%;
  background-color: ${COLORS.White};
  border-radius: 10px;
  overflow: hidden;
  padding-bottom: 30px;
`;

export const LineStep = styled.div`
  display: flex;
  gap: 0 2px;

  div {
    height: 10px;
    width: 100%;
    background: ${COLORS.Gray80};

    &.active {
      background: ${COLORS.Secondary};
    }
  }
`;

export const StepWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #ededed;
  border-radius: 6px;
  padding: 28px 24px;
  margin-top: 42px;
  margin-bottom: 18px;
`;
export const StepContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: ${rem(10)};

  @media (min-width: 992px) {
    flex-direction: row;
    align-items: center;
    gap: ${rem(10)};
  }
`;
export const StepTitle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const StepNumber = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: #717171;
  color: white;
  border-radius: 20px;
  margin-right: 6px;
  line-height: 4px;
`;
export const StepText = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: #717171;
  color: white;
  border-radius: 20px;
  margin-right: 6px;
  line-height: 4px;
`;

export const Content = styled.div`
  padding: 20px;

  @media (min-width: 512px) {
    padding: 52px 118px 0;
  }
  .registration-sub {
    letter-spacing: 0.24rem;
  }
  .step {
    padding-right: 34px;
    position: relative;
    .step-icon {
      display: none;
    }
    &:not(:first-child) {
      padding-left: 0px;

      @media (min-width: 992px) {
        padding-left: 34px;
      }
    }
    &:not(:last-child) {
      .step-icon {
        display: block;
        position: absolute;
        right: -12px;
        top: 50%;
        transform: translateY(-50%);
        color: #969696;

        font-weight: 900;
      }
    }

    &.active {
      /* .step-icon {
        color: #384dd6;
      } */
      .step-number {
        background: #384dd6;
      }
      .step-text {
        h6 {
          color: #161616;
          font-weight: 500;
        }
      }
    }
    &.prev {
      .step-icon {
        color: #384dd6;
      }
      .step-number {
        background: #384dd6;
      }
      .step-text {
        h6 {
          color: #384dd6;
          font-weight: 500;
        }
      }
    }
  }
`;

export const SettingFooter = styled.div`
  margin-top: ${rem(18)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  ul {
    display: flex;
    li {
      a {
        display: inline-block;
        margin-left: 14px;
      }
    }
  }
`;
