import { type UseQueryResult, useQuery } from 'react-query';
import {
  MyHoldingsService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_MyHoldings_AwardSummaryListDto,
} from '../../services/wealthlane-award-services';

const useGetAwardSummaryList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  //  eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_MyHoldings_AwardSummaryListDto | undefined,
  unknown
> => {
  const holdingList = useQuery(['getAwardSummaryList'], async () => {
    return await handleRequest(MyHoldingsService.getApiAwardApiAppMyHoldingsAwardSummariesList());
  });
  return holdingList;
};

export default useGetAwardSummaryList;
