import { type UseQueryResult, useQuery } from 'react-query';
import {
  ParticipantTaskService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_ParticipantTask_ParticipantCurrentCashBalanceDto,
} from '../../services/wealthlane-award-services';

const useGetParticipantCurrentCashBalance = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_ParticipantTask_ParticipantCurrentCashBalanceDto | undefined,
  unknown
> => {
  const participantCurrentCashBalance = useQuery(['getParticipantCurrentCashBalance'], async () => {
    return await handleRequest(
      ParticipantTaskService.getApiAwardApiAppParticipantTaskParticipantCurrentCashBalance()
    );
  });
  return participantCurrentCashBalance;
};

export default useGetParticipantCurrentCashBalance;
