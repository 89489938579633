import { useEffect, useState } from 'react';
import { useBoolean, useWealthlaneCustomerApi, useWealthlaneIdentityApi } from './hooks';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from './components/navbar';
import Spinners from './components/spinner';
import { selectAuthRoutesList, setRouteList } from './pages/configuration/routeSlice';
import { InnerContainer } from './constants/style/common.styled';
import RoutesContainer from './routes/RoutesContainer.js';
import AuthService from './services/authService';
import { AbpApplicationConfigurationService } from './services/wealthlane-identity-services';
import { getFilteredPermissionData } from './utils';
import pingBg from '../src/assets/images/pink_background.png';
import { ParticipantLoginLogService } from './services/wealthlane-customer-services';
import { isEmpty } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectToken, setCredentials } from './auth/authSlice';
import { selectNotify } from './auth/notify';
import AlertPopup from './components/alert-popup';
import { REDIRECT_PERMISSION_MESSAGE, RELOAD_PERMISSION_MESSAGE } from './constants/common';

function App(): JSX.Element {
  const location = useLocation();
  const [isNotify, setNotify] = useState(false);
  const authService = new AuthService();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector(selectToken);
  const showNotify = useSelector(selectNotify);
  const userAuthList = useSelector(selectAuthRoutesList);
  const { isLoading, handleRequest } = useWealthlaneIdentityApi();
  const [permissionStatus, setPermissionStatus] = useState<string>('');
  const { handleRequest: CustomerHandleRequest } = useWealthlaneCustomerApi();
  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false);

  const signInApi = (): void => {
    CustomerHandleRequest(ParticipantLoginLogService.postApiCustomerApiAppParticipantLoginLogLog())
      .then((data: any) => {
        window.sessionStorage.setItem('uniqueLogin', data);
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };

  useEffect((): void => {
    authService
      .getUser()
      .then((user) => {
        if (user != null) {
          dispatch(
            setCredentials({
              isAuthenticated: authService.isAuthenticated(),
              token: authService.getToken(),
              user,
            })
          );
        }
      })
      .catch((err) => {
        if (err == null) void authService.signOutRedirectCallback();
      });
    setNotify(true);
  }, []);

  useEffect(() => {
    if (token != null) {
      handleRequest(
        AbpApplicationConfigurationService.getApiIdentityApiAbpApplicationConfiguration()
      )
        .then((response) => {
          const { nonFieldLevelPermissionsGroups } = getFilteredPermissionData(
            response?.auth?.grantedPolicies
          );

          const RouteList = Object.keys(nonFieldLevelPermissionsGroups).map((item) => item);
          dispatch(
            setRouteList({
              currentUserAuthList: response ?? {},
              currentUserRoutesList: RouteList ?? [],
            })
          );
        })

        .catch((err) => {
          console.log('err', err);
        });
    }
  }, [token]);

  useEffect(() => {
    token != null && isEmpty(sessionStorage.getItem('uniqueLogin')) && signInApi();
  }, [token]);

  useEffect(() => {
    if (token != null && userAuthList.currentUser?.isAuthenticated === false) {
      void authService.logout();
    }
  }, [userAuthList, token]);

  useEffect(() => {
    document.getElementsByTagName('html')[0].style.overflow = 'unset';
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  window.addEventListener('PERMISSION_CHANGED', (evt: any) => {
    setPermissionStatus(evt.detail);
    openModal();
  });

  const handleCloseModal = () => {
    closeModal();
    if (permissionStatus === 'SIGN_IN') {
      authService.signInSilent();
    } else if (permissionStatus === 'REFRESH') {
      navigate('/');
      navigate(0);
    } else {
      navigate(0);
    }
  };

  return (
    <div>
      {isLoading ? (
        <Spinners containerClassName='h-screen' />
      ) : (
        <>
          <Navbar
            isAuth={authService.isAuthenticated()}
            showNotify={showNotify}
            isNotify={isNotify}
          />
          <InnerContainer img={pingBg} hasNotify={showNotify}>
            <RoutesContainer />
          </InnerContainer>
          {isModalOpen && (
            <AlertPopup
              isShow={isModalOpen}
              ConfirmTitle='Ok'
              customTitle={
                permissionStatus === 'RELOAD'
                  ? RELOAD_PERMISSION_MESSAGE
                  : REDIRECT_PERMISSION_MESSAGE
              }
              handleSubmit={() => handleCloseModal()}
            />
          )}
        </>
      )}
    </div>
  );
}

export default App;
