import { type UseQueryResult, useQuery } from 'react-query';
import {
  MyHoldingsService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_OptionAward_OptionAwardVestingScheduleDto,
} from '../../services/wealthlane-award-services';

const useGetOptionAwardVestingSchedule = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_OptionAward_OptionAwardVestingScheduleDto | undefined,
  unknown
> => {
  const optionAwardVestingSchedule = useQuery(
    ['getOptionAwardVestingSchedule', id],
    async () => {
      if (id !=null && id !== '') {
        return await handleRequest(
          MyHoldingsService.getApiAwardApiAppMyHoldingsOptionAwardVestingSchedule(id)
        );
      }
    },
    {
      enabled: typeof id === 'string' && id !== '',
    }
  );

  return optionAwardVestingSchedule;
};

export default useGetOptionAwardVestingSchedule;
