export const NotificationRouteList = [
  {
    title: 'Participant Portfolio',
    url: '/holdings',
  },
  {
    title: 'Pre-Clearance Request',
    url: '/pre-clearance-request',
  },
];
