import styled from 'styled-components';
import { COLORS } from '../../constants/style/color';
import { rem } from '../../utils/style';

// type ProfileWrapperType = {
//   variant: 'large' | 'medium' | 'small';
//   width?: string;
//   height?: string;
//   padding?: string;
// };

export const OtpFormWrapper = styled.div`
  .otpWrapper {
    /* width: 366px; */
    margin: 0 auto;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;

    .otp-invalid {
      border: ${rem(1)} solid ${COLORS.Red};
    }

    .otp-valid {
      border: ${rem(1)} solid ${COLORS.Green};
    }

    figure {
      height: 80px;
      width: 80px;
      background: linear-gradient(0deg, #384dd6 0%, #38b0d6 100%);
      border-radius: 50%;
    }
    form {
      .otpInputGrid {
        display: flex;
        gap: 7px;
        margin-top: 24px;
      }
      input {
        width: 42px !important;
        height: 42px;
        border-radius: 8px;
      }
      .resendBtn {
        color: #595959;
        font-weight: 400;
      }
    }
  }
`;

export const OtpErrorMessage = styled.div`
  font-weight: 500;
  font-size: ${rem(12)};
  line-height: ${rem(14)};
  color: ${COLORS.Red};
  margin-top: ${rem(12)};
`;
