import { Form, Formik } from 'formik';
import React from 'react';
import _ from 'lodash';
import Button from '../../../components/button';
import FormSelect from '../../../components/dropdown/formSelect';
import InputText from '../../../components/input-text/inputText';
import Heading from '../../../components/typography';
import InitialValuesForm from '../../../constants/initialValues';
import { ContentBody, ContentContainer, ContentHeader } from './signUpSteps.styled';
import { ParticipantSignUpService } from '../../../services/wealthlane-customer-services';
import { useNavigate } from 'react-router-dom';
import { useGetSecurityQuestion, useWealthlaneCustomerApi } from '../../../hooks';
import { STEP_FOUR_VALIDATION } from '../../../utils/validations/validations';

const StepFour = (): JSX.Element => {
  const { handleRequest, isLoading } = useWealthlaneCustomerApi();
  const { data: allQuestions } = useGetSecurityQuestion(handleRequest);
  const groupedQuestions = _.groupBy(allQuestions, (item) => item.category);
  const stepFourValues = window.sessionStorage.getItem('step4');
  const navigate = useNavigate();

  function handleSubmit(values: any): void {
    const formValues = window.sessionStorage.getItem('step3');
    const payload = {
      ...values,
      ...JSON.parse(formValues as any),
    };

    handleRequest(
      ParticipantSignUpService.postApiCustomerApiAppParticipantSignUpRegisterParticipantAccount(
        payload
      )
    )
      .then((response: any) => {
        if (response != null) {
          const step4 = {
            ...payload,
            ...response,
            ...values,
          };
          window.sessionStorage.setItem('step4', JSON.stringify(step4));
          navigate('/sign-up-confirm');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <ContentContainer>
      <ContentHeader>
        <Heading variant={'h4'} title={'Set Up Your Security Questions'} className='title' />
      </ContentHeader>
      <ContentBody>
        <div>
          <Formik
            enableReinitialize
            initialValues={{
              ...(JSON.parse(stepFourValues as any) ?? InitialValuesForm.SignUp.stepFour),
            }}
            validationSchema={STEP_FOUR_VALIDATION}
            onSubmit={handleSubmit}>
            {({ handleChange, errors, touched, values, setFieldValue }) => {
              return (
                <Form>
                  <>
                    <div className='grid lg:grid-cols-3'>
                      <div className=''>
                        <FormSelect
                          label='Security Question 1'
                          name='securityQuestion1'
                          placeholder='Select Question'
                          containerClassName='formGroup'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          options={groupedQuestions['1']}
                          values={values}
                          setFieldValue={setFieldValue}
                          required
                        />
                        <InputText
                          type='text'
                          label='Your Answer'
                          name='securityAnswer1'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          required
                        />
                        <FormSelect
                          label='Security Question 2'
                          name='securityQuestion2'
                          placeholder='Select Question'
                          containerClassName='formGroup'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          options={groupedQuestions['2']}
                          values={values}
                          setFieldValue={setFieldValue}
                          required
                        />
                        <InputText
                          type='text'
                          label='Your Answer'
                          name='securityAnswer2'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          required
                        />
                        <FormSelect
                          label='Security Question 3'
                          name='securityQuestion3'
                          placeholder='Select Question'
                          containerClassName='formGroup'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          options={groupedQuestions['3']}
                          values={values}
                          setFieldValue={setFieldValue}
                          required
                        />
                        <InputText
                          type='text'
                          label='Your Answer'
                          className='mb-0'
                          name='securityAnswer3'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          required
                        />
                      </div>
                    </div>
                    <div className='mt-12'>
                      <Button
                        as='Create'
                        variant={'primary'}
                        title={'Register Account'}
                        type='submit'
                        loading={isLoading}
                      />
                    </div>
                  </>
                </Form>
              );
            }}
          </Formik>
        </div>
      </ContentBody>
    </ContentContainer>
  );
};

export default StepFour;
