import { useEffect } from 'react';
import Button from '../../components/button';
import Spinners from '../../components/spinner';
import { ReactComponent as Tick } from '../../assets/svg/check-square.svg';
import AuthService from '../../services/authService';
import { useNavigate } from 'react-router-dom';
import { useGetToken, usePostConfirmEmail, useWealthlaneCustomerApi } from '../../hooks';

const EmailConfirmation = (): JSX.Element => {
  const { handleRequest } = useWealthlaneCustomerApi();
  const query = useGetToken();
  const tokenValue1 = query.get('token1');
  const tokenValue2 = query.get('token2');
  const token1 = tokenValue1?.split(' ').join('+');
  const token2 = tokenValue2?.split(' ').join('+');
  const authService = new AuthService();
  const navigate = useNavigate();

  const { mutate, status } = usePostConfirmEmail(handleRequest, token1 as string, token2 as string);

  useEffect(() => {
    mutate();
  }, []);

  useEffect(() => {
    if (authService.isAuthenticated()) {
      navigate('/');
    }
  }, []);

  return (
    <div className='bg-white py-[55px] px-[88px] h-[100%] rounded-lg shadow-lg mt-20 w-[650px]'>
      {status === 'loading' && <Spinners />}
      {status === 'success' && (
        <div className='flex justify-center '>
          <div className='flex flex-col items-center'>
            <Tick />
            <p className='mt-5 mb-5 w-[200px] text-center text-[14px] font-semibold leading-5'>
              {' '}
              Your Email Address has been confirmed
            </p>
            <h1 className='text-[32px] font-bold leading-10 text-[#3671CC]'>Thank you.</h1>
            <p className='mt-5 mb-[35px] w-[400px] text-center text-[14px] font-semibold leading-5'>
              We request you to fill up all the remaining required information after login.
            </p>
            <div>
              <Button
                title={'Login'}
                type='button'
                variant={'primary'}
                onClick={async () => {
                  await authService.signInRedirect();
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailConfirmation;
