import { type ReactNode } from 'react';
import { COLORS } from '../../constants/style/color';
import Heading from '../typography';
import { FlashMessageWrapper } from './flashMessage.styled';

interface NoDataFoundType {
  message?: string | ReactNode;
  type?: 'success' | 'error' | 'warning' | 'info' | 'chat' | 'notification';
  size: 'sm' | 'md' | 'lg';
  popoverClassName?: string; // special class for popovers
}

const FlashMessage = ({ message, size, type, popoverClassName }: NoDataFoundType): JSX.Element => {
  const getIcon = (): string => {
    if (type === 'info') {
      return 'info';
    } else if (type === 'warning') {
      return 'warning';
    } else if (type === 'error') {
      return 'emergency_home';
    } else if (type === 'chat') {
      return 'sms_failed';
    } else if (type === 'notification') {
      return 'notification_important';
    } else {
      return 'new_releases';
    }
  };

  return (
    <FlashMessageWrapper size={size} className={popoverClassName}>
      {type != null && (
        <span className={`material-symbols-outlined icon-${size} ${popoverClassName ?? ''}`}>
          {getIcon()}
        </span>
      )}
      {message != null && (
        <Heading
          variant={size === 'lg' ? 'body' : size === 'md' ? 'body-s' : 'body-xs'}
          title={message}
          color={`${COLORS.Gray}`}
          className={popoverClassName}
        />
      )}
    </FlashMessageWrapper>
  );
};

export default FlashMessage;
