import { type UseMutationResult, useMutation } from 'react-query';
import {
  MyHoldingsService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_RSUAward_RSUAwardBlobDto,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_RSUAward_RSUAwardBlobRequestDto,
} from '../../services/wealthlane-award-services';
import { handleDownloadFile } from '../../utils/global';

const usePostOptionAwardDownloadDocument = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  setCardIndex: (value: number) => void
): UseMutationResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_RSUAward_RSUAwardBlobDto | undefined,
  unknown,
  // eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_RSUAward_RSUAwardBlobRequestDto,
  unknown
> => {
  return useMutation(
    // eslint-disable-next-line camelcase
    async (payload: Wealthlane_AwardService_Dtos_RSUAward_RSUAwardBlobRequestDto) => {
      return await handleRequest(
        MyHoldingsService.postApiAwardApiAppMyHoldingsDownloadDocument(payload)
      );
    },
    {
      // eslint-disable-next-line camelcase
      onSuccess: (data?: Wealthlane_AwardService_Dtos_RSUAward_RSUAwardBlobDto) => {
        if (data !== undefined && data !== null) {
          handleDownloadFile(`${data.name ?? ''}`, data.content ?? '');
          setCardIndex(0);
        }
      },
    }
  );
};

export default usePostOptionAwardDownloadDocument;
