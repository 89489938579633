import { type UseQueryResult, useQuery } from 'react-query';

import {
  PsuAwardService,
  RsuAwardService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_RSUAward_RSUAwardOverviewDto,
  type Wealthlane_AwardService_Dtos_PSUAward_PSUAwardOverviewDto,
  type Wealthlane_AwardService_Dtos_OptionAward_OptionAwardOverviewDto,
  MyHoldingsService,
} from '../../services/wealthlane-award-services';

const useGetAwardDetailsById = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string,
  awardType?: string
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Wealthlane_AwardService_Dtos_PSUAward_PSUAwardOverviewDto
  | Wealthlane_AwardService_Dtos_RSUAward_RSUAwardOverviewDto
  | Wealthlane_AwardService_Dtos_OptionAward_OptionAwardOverviewDto
  | undefined,
  unknown
> => {
  const awardDetails = useQuery(['awardDetails', id], async () => {
    if (id && awardType === 'RSU') {
      return await handleRequest(
        RsuAwardService.getApiAwardApiAppRSUAwardRSUAwardDetailByRSuVestingDateIdForParticipant(id)
      );
    }
    if (id && awardType === 'PSU') {
      return await handleRequest(
        PsuAwardService.getApiAwardApiAppPSUAwardPSUAwardDetailByPSuVestingDateIdForParticipant(id)
      );
    }
    if (id && (awardType === 'ESOP' || awardType === 'ISO')) {
      return await handleRequest(
        MyHoldingsService.getApiAwardApiAppMyHoldingsOptionAwardOverview(id)
      );
    }
  });

  return awardDetails;
};

export default useGetAwardDetailsById;
