import { type ReactNode } from 'react';
import { BadgeWrapper } from './badge.styled';

interface BadgeType {
  icon: ReactNode | string;
  variant?: 'primary' | 'secondary';
  title?: string;
  count?: number;
  isActive?: boolean;
  onClick?: () => void;
}

const Badge = ({ icon, count, variant, isActive, title, onClick }: BadgeType): JSX.Element => {
  return (
    <BadgeWrapper
      onClick={onClick}
      variant={variant ?? 'primary'}
      className={`flex items-center ${isActive === true ? 'active' : ''}`}>
      <span className='material-symbols-outlined'>{icon}</span>
      {count != null ? <div className='count'>{count}</div> : ''}
      {title != null ? <div className='badge-title'>{title}</div> : ''}
    </BadgeWrapper>
  );
};

export default Badge;
