import { type UseQueryResult, useQuery } from 'react-query';
import { MyHoldingsService } from '../../services/wealthlane-award-services';

const useGetRsuAwardNumber = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string;
    }>
  | undefined,
  unknown
> => {
  const rsuAwardNumberOptions = useQuery(
    ['getRsuAwardNumberOptions'],
    async () => {
      return await handleRequest(MyHoldingsService.getApiAwardApiAppMyHoldingsRSUAwardNumbers());
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.awardNumber ?? '',
            value: item.rsuAwardId ?? '',
          };
        });
      },
    }
  );

  return rsuAwardNumberOptions;
};

export default useGetRsuAwardNumber;
