import { useQuery } from 'react-query';
import { OptionExerciseService, ReleaseService } from '../../services/wealthlane-award-services';
import { DisbursementService } from '../../services/wealthlane-award-settlement-services';

const useGetDisbursementDetails = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: string
) => {
  const disbursementDetails = useQuery(['getDisbursementDetails', id], async () => {
    if (id) {
      return await handleRequest(
        DisbursementService.getApiAwardSettlementApiAppDisbursementDisbursementDetail(id)
      );
    }
  });
  return disbursementDetails;
};

export default useGetDisbursementDetails;
