import { rem } from '../../utils/style';
import styled from 'styled-components';
import { COLORS } from '../../constants/style/color';
import { Display } from '../../constants/style/common.styled';

interface FlashMessageWrapperType {
  size: 'sm' | 'md' | 'lg';
}

export const FlashMessageWrapper = styled(Display)<FlashMessageWrapperType>`
  flex-direction: column;
  gap: ${(props) => (props.size === 'lg' ? '24.5px' : props.size === 'md' ? '10px' : '8px')};
  padding: ${(props) => (props.size === 'lg' ? '20px' : props.size === 'md' ? '10px' : '5px')};

  .material-symbols-outlined {
    color: ${COLORS.LightGray};
  }

  .icon-lg {
    font-size: ${rem(200)};
  }

  .icon-md {
    font-size: ${rem(50)};
  }

  .icon-sm {
    font-size: ${rem(20)};
  }
`;
