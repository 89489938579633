import { type UseQueryResult, useQuery } from 'react-query';
import {
  ParticipantService,
  // eslint-disable-next-line camelcase
  type Wealthlane_CustomerService_Dtos_Participant_ViewParticipantPersonalDetailDto,
} from '../../services/wealthlane-customer-services';

const useGetParticipantPersonalDetails = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  onboarding?: boolean
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_CustomerService_Dtos_Participant_ViewParticipantPersonalDetailDto | undefined,
  unknown
> => {
  const participantPersonalDetails = useQuery(
    ['getParticipantPersonalDetails'],
    async () => {
      return await handleRequest(
        ParticipantService.getApiCustomerApiAppParticipantParticipantPersonalDetail()
      );
    },
    {
      enabled: typeof onboarding === 'boolean' && onboarding,
      cacheTime: 1000 * 60 * 5,
    }
  );

  return participantPersonalDetails;
};

export default useGetParticipantPersonalDetails;
