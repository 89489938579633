import { type UseQueryResult, useQuery } from 'react-query';
import {
  ParticipantSignUpService,
  // eslint-disable-next-line camelcase
  type Wealthlane_Shared_Hosting_Dtos_ParticipantSignup_ParticipantSignUpTokenAndCodeExpiryTimeDto,
} from '../../services/wealthlane-customer-services';

const useGetCodeExpiryTime = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Wealthlane_Shared_Hosting_Dtos_ParticipantSignup_ParticipantSignUpTokenAndCodeExpiryTimeDto
  | undefined,
  unknown
> => {
  const codeExpiryTime = useQuery(['getCodeExpiryTime'], async () => {
    return await handleRequest(
      ParticipantSignUpService.getApiCustomerApiAppParticipantSignUpParticipantSignUpTokenAndCodeExpiryTime()
    );
  });

  return codeExpiryTime;
};

export default useGetCodeExpiryTime;
