import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  ParticipantBankService,
  //  eslint-disable-next-line camelcase
  type Wealthlane_FinanceIntegrationService_Dtos_Response_ResponseDto,
} from '../../services/wealthlane-finance-integration-services';

const usePostBankAccountManualToken = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  setIsManual: any
): UseMutationResult<
  //  eslint-disable-next-line camelcase
  Wealthlane_FinanceIntegrationService_Dtos_Response_ResponseDto | undefined,
  unknown,
  any,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      return await handleRequest(
        ParticipantBankService.postApiFinanceIntegrationApiAppParticipantBankVerifyManualBankAccount(
          payload
        )
      );
    },
    {
      onSuccess: async (
        // eslint-disable-next-line camelcase
        response: Wealthlane_FinanceIntegrationService_Dtos_Response_ResponseDto | undefined
      ) => {
        if (response !=null) {
          await queryClient.invalidateQueries('getCompanyBankList');
          await queryClient.invalidateQueries('getCompanyBankToken');
          toast.success(`Bank Account Verified!`);
          setIsManual(false);
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostBankAccountManualToken;
