import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import Button from '../../../components/button';
import InputText from '../../../components/input-text/inputText';
import ModalBodyComponent from '../../../components/modal/modalBodyComponent';
import Heading from '../../../components/typography';
import InitialValuesForm from '../../../constants/initialValues';
import { COLORS } from '../../../constants/style/color';
import { useWealthlaneCustomerApi } from '../../../hooks';
import { ParticipantSignUpService } from '../../../services/wealthlane-customer-services';
import { STEP_THREE_VALIDATION } from '../../../utils/validations/validations';
import {
  ContentBody,
  ContentContainer,
  ContentHeader,
  PasswordDetailsContainer,
} from './signUpSteps.styled';
import { handleStringCheck } from '../../../utils/global';

interface StepTwoType {
  setStep: (value: number) => void;
}

const StepThree = ({ setStep }: StepTwoType): JSX.Element => {
  const [check, setCheck] = useState({
    longer: false,
    camelCase: false,
    isNumber: false,
    hasSymbol: false,
  });
  const { handleRequest, isLoading } = useWealthlaneCustomerApi();
  const formValues = window.sessionStorage.getItem('step2');
  const stepThreeValues = window.sessionStorage.getItem('step3');
  const otpToken = window.sessionStorage.getItem('otpVerification');

  const icon = <span className='material-symbols-outlined text-3xl'>check_circle</span>;

  const handleSubmit = (values: any): void => {
    const payload = {
      ...values,
      ...JSON.parse(formValues as any),
      otpToken: JSON.parse(otpToken as any)?.token,
      code: JSON.parse(formValues as any)?.code,
      token: JSON.parse(formValues as any)?.token,
    };

    handleRequest(
      ParticipantSignUpService.postApiCustomerApiAppParticipantSignUpRegisterParticipantLogInCredential(
        payload
      )
    )
      .then((response: any) => {
        if (response != null) {
          const step3 = {
            ...payload,
            ...response,
            ...values,
            token: payload?.token,
            code: payload?.code,
            otpToken: payload?.otpToken,
          };
          window.sessionStorage.setItem('step3', JSON.stringify(step3));
          setStep(4);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <ContentContainer>
      <ContentHeader>
        <Heading variant={'h4'} title={'Setup Your Credentials'} className='title' />
      </ContentHeader>
      <ContentBody>
        <div>
          <Formik
            enableReinitialize
            initialValues={{
              ...JSON.parse(stepThreeValues as any),
              userName: JSON.parse(formValues as any)?.email ?? InitialValuesForm.SignUp.stepThree,
              password: '',
              confirmPassword: '',
            }}
            validationSchema={STEP_THREE_VALIDATION}
            onSubmit={handleSubmit}>
            {({ handleChange, errors, touched, setFieldValue }) => {
              return (
                <Form>
                  <ModalBodyComponent>
                    <div className='grid lg:grid-cols-3'>
                      <div className=''>
                        <InputText
                          type='text'
                          label='Personal Email (Username)'
                          name='userName'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          disabled
                          required
                        />
                        <InputText
                          type='password'
                          className='mb-5'
                          label='Password'
                          name='password'
                          onChange={(e: any) => {
                            handleStringCheck(e.target.value, setCheck);
                            setFieldValue('password', e.target.value);
                          }}
                          errors={errors}
                          touched={touched}
                          required
                        />

                        {errors.password != null && errors.password !== '' && (
                          <div className='mb-7'>
                            <PasswordDetailsContainer>
                              <>
                                <div className=''>
                                  <div className='flex gap-x-4'>
                                    <div className={`icon ${check.longer ? 'icon-success' : ''}`}>
                                      {icon}
                                    </div>
                                    <Heading
                                      variant='body-xs'
                                      title='Longer than 8 characters'
                                      className='info'
                                      color={check.longer ? `${COLORS.Green}` : `${COLORS.Red}`}
                                    />
                                  </div>
                                  <div className='flex gap-x-4'>
                                    <div
                                      className={`icon ${check.camelCase ? 'icon-success' : ''}`}>
                                      {icon}
                                    </div>
                                    <Heading
                                      variant='body-xs'
                                      title='Must contain at least 1 Uppercase and 1 Lowercase'
                                      className='info'
                                      color={check.camelCase ? `${COLORS.Green}` : `${COLORS.Red}`}
                                    />
                                  </div>
                                  <div className='flex gap-x-4'>
                                    <div className={`icon ${check.isNumber ? 'icon-success' : ''}`}>
                                      {icon}
                                    </div>
                                    <Heading
                                      variant='body-xs'
                                      title='Must contain at least  1 Number'
                                      className='info'
                                      color={check.isNumber ? `${COLORS.Green}` : `${COLORS.Red}`}
                                    />
                                  </div>
                                  <div className='flex gap-x-4'>
                                    <div
                                      className={`icon ${check.hasSymbol ? 'icon-success' : ''}`}>
                                      {icon}
                                    </div>
                                    <Heading
                                      variant='body-xs'
                                      title='Must contain at least 1 Special Symbol (@$#)'
                                      className='info'
                                      color={check.hasSymbol ? `${COLORS.Green}` : `${COLORS.Red}`}
                                    />
                                  </div>
                                </div>
                              </>
                            </PasswordDetailsContainer>
                          </div>
                        )}
                        <InputText
                          type='password'
                          label='Confirm Password'
                          name='confirmPassword'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          onPaste={(e: any) => e.preventDefault()}
                          required
                        />
                      </div>
                    </div>
                    <div className='mt-12'>
                      <Button
                        as='Create'
                        variant={'primary'}
                        title={'Continue'}
                        type='submit'
                        loading={isLoading}
                      />
                    </div>
                  </ModalBodyComponent>
                </Form>
              );
            }}
          </Formik>
        </div>
      </ContentBody>
    </ContentContainer>
  );
};

export default StepThree;
