import { toast } from 'react-toastify';
import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import ToastComponent from '../../components/toast';
import {
  NotificationSettingsService,
  // eslint-disable-next-line camelcase
  type Wealthlane_NotificationService_DTOs_ResponseDto,
} from '../../services/wealthlane-notification-services';

const usePostNotificationSettings = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseMutationResult<
  // eslint-disable-next-line camelcase
  Wealthlane_NotificationService_DTOs_ResponseDto | undefined,
  unknown,
  any,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      return await handleRequest(
        NotificationSettingsService.postApiNotificationApiAppNotificationSettingsSaveNotificationSettings(
          payload
        )
      );
    },
    {
      // eslint-disable-next-line camelcase
      onSuccess: async (response: Wealthlane_NotificationService_DTOs_ResponseDto | undefined) => {
        if (response != null) {
          await queryClient.invalidateQueries('getNotificationSettings');
          toast.success(<ToastComponent label='Success' message='Notification settings saved.' />);
        }
      },
    }
  );
};

export default usePostNotificationSettings;
