import { type UseQueryResult, useQuery } from 'react-query';
import { StateService } from '../../services/wealthlane-customer-services';

const useGetStateOptions = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string,
  isUS?: boolean
): UseQueryResult<
  | Array<{
      label: string;
      value: string;
    }>
  | undefined,
  unknown
> => {
  const isCountryUs = isUS ? isUS : true;
  const stateOptions = useQuery(
    ['getStates', id],
    async () => {
      if (isCountryUs) {
        if (id !== undefined && id !== null && id !== '') {
          return await handleRequest(StateService.getApiCustomerApiAppStateByCountry(id));
        }
        return await handleRequest(StateService.getApiCustomerApiAppStateStates());
      }
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.name ?? '',
            value: item.id ?? '',
          };
        });
      },
    }
  );

  return stateOptions;
};

export default useGetStateOptions;
