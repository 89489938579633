export const TransactionsList = [
  {
    url: '/transactions/transaction-history',

    name: 'Transaction History',
  },
  {
    url: '/transactions/cash',
    name: 'Cash',
  },
  {
    url: '/transactions/Share',
    name: 'Share',
  },
];
