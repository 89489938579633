import { type UseQueryResult, useQuery } from 'react-query';
import {
  ParticipantBankService,
  // eslint-disable-next-line camelcase
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_FinanceIntegrationService_Dtos_ParticipantBank_ParticipantBankListDto_Wealthlane_FinanceIntegrationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
} from '../../services/wealthlane-finance-integration-services';
import { type TableParamsType } from '../../types/common';

const useGetCompanyBankList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_FinanceIntegrationService_Dtos_ParticipantBank_ParticipantBankListDto_Wealthlane_FinanceIntegrationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
  | undefined,
  unknown
> => {
  const { filter, sorting, skipCount, maxResultCount } = tableInfo;
  const companyBankList = useQuery(
    ['getCompanyBankList', filter, sorting, skipCount, maxResultCount],
    async () => {
      return await handleRequest(
        ParticipantBankService.getApiFinanceIntegrationApiAppParticipantBankParticipantBankList(
          filter,
          sorting,
          skipCount,
          maxResultCount
        )
      );
    },
    {
      select: (data) => {
        if (data != null) {
          return data;
        }
      },
      cacheTime: 1000 * 60 * 5,
    }
  );

  return companyBankList;
};

export default useGetCompanyBankList;
