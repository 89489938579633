import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { ReactComponent as PadlockIcon } from '../../assets/svg/padlock.svg';
import Heading from '../typography';
import Button from '../button';
import { OtpErrorMessage, OtpFormWrapper } from './otpFormStyled';
import OtpInput from 'react-otp-input';
import { COLORS } from '../../constants/style/color';
import { isEmpty } from 'lodash';

interface OtpType {
  width?: string;
  padding?: string;
  text?: string;
  isLoading?: boolean;
  handleRequest?: any;
  formData?: any;
  setStep?: any;
  handleSubmit?: any;
  handleResendOtp?: any;
  errorMessage?: string;
  setDefaultError?: any;
}

const OtpForm = ({
  width,
  padding,
  text,
  handleRequest,
  formData,
  setStep,
  isLoading,
  handleSubmit,
  handleResendOtp,
  errorMessage,
  setDefaultError,
  ...rest
}: OtpType): JSX.Element => {
  const [errorState, setErrorState] = useState<any>(errorMessage);
  const [time, setTime] = useState<any>(300);
  const [resend, setResend] = useState(false);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setTime((time: any) => {
  //       if (time === 0) {
  //         setResend(false);
  //         clearInterval(timer);
  //         return 0;
  //       } else return time - 1;
  //     });
  //   }, 1000);
  // }, [resend]);

  // const timer: any = `${Math.floor(time / 60)}: ${time % 60}`;

  useEffect(() => {
    setErrorState(errorMessage);
  }, [errorMessage]);

  const initialTime = 5 * 60; // 5 minutes in seconds
  const [seconds, setSeconds] = useState(initialTime);

  useEffect(() => {
    let interval: any;
    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [seconds]);

  const formatTime = (timeInSeconds: any) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <OtpFormWrapper>
      <div className='otpWrapper mb-6'>
        <figure className='mb-6'>
          <PadlockIcon />
        </figure>
        <Heading variant='h4' className='mb-2' title='OTP Verification' />
        <Heading variant='h6' className='' title='We sent an OTP to your email.' />
        <Formik
          enableReinitialize
          initialValues={{
            otp: '',
          }}
          onSubmit={(values: any) => handleSubmit(values)}>
          {({ values, setFieldValue }) => (
            <Form>
              <div className='otpInputGrid '>
                <OtpInput
                  value={values.otp}
                  onChange={(e: string) => {
                    setFieldValue('otp', e);
                    setDefaultError('');
                    setErrorState('');
                  }}
                  separator={<span style={{ width: '8px' }}></span>}
                  numInputs={6}
                  isInputNum={true}
                  inputStyle={{
                    border:
                      values.otp.length === 6 && isEmpty(errorState)
                        ? `1px solid ${COLORS.Green} `
                        : errorState != null && errorState != ''
                        ? `1px solid ${COLORS.Red} `
                        : `1px solid ${COLORS.LightGray} `,
                    borderRadius: '8px',
                    width: '50px',
                    height: '40px',
                    fontSize: '12px',
                    color: '#000',
                    fontWeight: '400',
                    // caretColor: 'blue',
                  }}
                />
              </div>

              {errorState !== undefined && errorState !== null && errorState !== '' && (
                <OtpErrorMessage>{errorState}</OtpErrorMessage>
              )}

              <Button
                title={`Verify & Proceed`}
                type='submit'
                variant={'primary'}
                btnClassName='mt-6 mx-auto mb-5'
                loading={isLoading}
              />
            </Form>
          )}
        </Formik>
        {isLoading !== null && isLoading !== undefined && !isLoading && (
          <Heading
            variant='body-s'
            title={
              <>
                Your code will expire in {/* <p> */}
                {/* {timer} */}
                {formatTime(seconds)}
                {/* </p> */}
              </>
            }
          />
        )}

        <Button
          as='Create'
          variant='link'
          title='Resend OTP'
          btnClassName='resendBtn'
          onClick={() => {
            handleResendOtp();
            // setTime(300);
            setSeconds(initialTime);
            formatTime(initialTime);
            setResend(true);
          }}
          disabled={formatTime(seconds) !== '00:00'}
        />
      </div>
    </OtpFormWrapper>
  );
};

export default OtpForm;
