import { getViewHeight, rem } from '../../utils/style';
import styled, { css } from 'styled-components';
import { COLORS } from '../../constants/style/color';

interface SpinnerType {
  borderColor: string;
  size?: 'sm' | 'md' | 'lg';
}

export const SpinnerWrapper = styled.div`
  min-height: ${getViewHeight('67px')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Spinner = styled.div<SpinnerType>`
  pointer-events: none;
  border: 3px solid ${COLORS.LightGray};
  border-radius: 50%;
  display: inline-block;
  animation: rotation 1.5s linear infinite;

  ${(props: { borderColor: string }) => {
    return css`
      border-bottom-color: ${props.borderColor};
    `;
  }}

  ${(props) => {
    if (props.size === 'sm') {
      return css`
        width: ${rem(16)};
        height: ${rem(16)};
      `;
    }
    if (props.size === 'md') {
      return css`
        width: ${rem(32)};
        height: ${rem(32)};
      `;
    }
    if (props.size === 'lg') {
      return css`
        width: ${rem(52)};
        height: ${rem(52)};
      `;
    }
  }};

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const SpinnerPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  h2 {
    margin-bottom: ${rem(8)};
  }

  h5 {
    margin-bottom: ${rem(40)};
    font-weight: 300;
    color: ${COLORS.Gray};
  }
`;
