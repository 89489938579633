import { type UseQueryResult, useQuery } from 'react-query';
import {
  TermsAndConditionsService,
  // eslint-disable-next-line camelcase
  type Wealthlane_CustomerService_Dtos_TermsAndConditions_TermsAndConditionsListDto,
} from '../../services/wealthlane-customer-services';

const useGetTermAndCondition = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  pageName: string
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_CustomerService_Dtos_TermsAndConditions_TermsAndConditionsListDto[] | undefined,
  unknown
> => {
  const participantTermAndCondition = useQuery(['getTermAndCondition'], async () => {
    return await handleRequest(
      TermsAndConditionsService.getApiCustomerApiAppTermsAndConditionsActiveTermsAndConditions(
        pageName
      )
    );
  });

  return participantTermAndCondition;
};

export default useGetTermAndCondition;
