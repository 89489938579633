import styled from 'styled-components';
import { SecondaryButton } from '../../components/button/button.styled';
import { getViewHeight, rem } from '../../utils/style';
import { COLORS } from './color';

interface Types {
  alignItems?: any;
  justifyContent?: any;
  leading?: any;
  bgColor?: any;
  img?: any;
  paddingInfo?: any;
  padding?: any;
  hasNotify?: boolean;
}

export const Display = styled.div<Types>`
  display: flex;
  align-items: ${(props) => (props.alignItems != null ? props.alignItems : 'center')};
  justify-content: ${(props) => (props.justifyContent != null ? props.justifyContent : 'center')};
`;

export const Loading = styled.div`
  margin-right: 10px;
`;

export const ModalHeading = styled.h4`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: ${COLORS.Blue};
  margin-bottom: 24px;
`;

export const SignUpHeadingMain = styled.h3`
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: ${COLORS.Blue};

  @media (min-width: 768px) {
    font-size: 25px;
    line-height: 32px;
  }
`;

export const BigHeadingMain = styled.h3`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${COLORS.Blue};

  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 26px;
  }
`;

export const HeadingMain = styled.h5<Types>`
  font-weight: 600;
  font-size: 12px;
  line-height: ${(props) => (props.leading != null ? props.leading : '15px')};
  color: ${(props) => (props.color != null ? props.color : `${COLORS.Black}`)};
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: 15px;
    line-height: 19px;
  }
`;

export const PlainText = styled.h5<Types>`
  font-weight: 500;
  font-size: 15px;
  line-height: ${(props) => (props.leading != null ? props.leading : '19px')};
  color: ${(props) => (props.color != null ? props.color : `${COLORS.Gray60}`)};
  text-align: center;
  @media (min-width: 768px) {
    font-size: 15px;
    line-height: 19px;
  }
`;

export const SubHeadingMain = styled.h6<Types>`
  font-weight: 700;
  font-size: 12px;
  line-height: ${(props) => (props.leading != null ? props.leading : '15px')};
  color: ${(props) => (props.color != null ? props.color : `${COLORS.Secondary}`)};
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: 13px;
    line-height: 12px;
  }
`;

export const WrapperContainer = styled.div<Types>`
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  position: relative;
  &:before {
    content: '';
    position: fixed;
    top: 67px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: ${(props) => `url(${props.img as string})`};
    height: ${getViewHeight()};
  }

  @media (min-width: 768px) {
    padding: 37px;
  }

  @media (min-width: 1200px) {
    padding: ${(props) => (props.paddingInfo != null ? props.paddingInfo : '43px 105px')};
  }
`;

export const Container = styled.div`
  width: calc(100% - 20px);
  margin: 0 auto;

  @media (min-width: 768px) {
    width: 720px;
  }

  @media (min-width: 992px) {
    width: 960px;
  }

  @media (min-width: 1200px) {
    width: calc(100% - 240px);
  }
`;

export const InnerContainer = styled.div<Types>`
  min-height: ${(props) =>
    props.hasNotify != null ? `${getViewHeight('101px')}` : `${getViewHeight('67px')}`};
  position: relative;

  &:before {
    content: '';
    position: fixed;
    top: 67px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: ${(props) => `url(${props.img as string}) no-repeat`};
    height: ${getViewHeight()};
  }
`;

export const ErrorMessageWrapper = styled.div`
  font-size: 12px;
  color: ${COLORS.Gray};
`;

export const TooltipWrapper = styled.div`
  .tooltip {
    position: relative;
    display: inline-block;
  }
  .tooltip .tooltiptext {
    visibility: hidden;
    position: absolute;
    z-index: 1;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`;

export const FormDivider = styled.div`
  margin: 2.4rem 0;
  border-top: 1px solid ${COLORS.CulturedWhite};
`;

export const GoBackBlock = styled.div`
  display: inline-flex;
  margin-bottom: 12px;
  cursor: pointer;
  .goBack {
    transition: all 0.2s linear;
  }
  .material-symbols-outlined {
    transition: all 0.2s linear;
    font-size: 20px;
    padding-right: 5px;
  }
  &:hover {
    color: ${COLORS.Purple};
    .material-symbols-outlined {
      transform: translateX(-2px);
    }
    .goBack {
      text-decoration: underline;
      color: ${COLORS.Purple};
    }
  }
`;

export const PageNotFoundWrapper = styled.div`
  /* background-color: red; */
  height: ${getViewHeight('101px')};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  span {
    font-size: ${rem(127)};
    font-weight: 400;
    color: ${COLORS.LightGray};
    margin-bottom: ${rem(35)};
  }

  .page-btn {
    @include ${SecondaryButton(COLORS.Iris)};
  }

  h3 {
    color: ${COLORS.Gray};
    /* font-weight: 600; */
    margin-bottom: ${rem(12)};
  }

  h5 {
    color: ${COLORS.Gray};
    margin-bottom: ${rem(24)};
    font-weight: 400;
  }
`;

export const ResetFilterWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: ${rem(6)};
  margin-top: ${rem(8)};
  cursor: pointer;
  gap: ${rem(2)};
  transition: all 0.2s linear;
  &,
  & span {
    color: ${COLORS.Gray};
  }

  &:hover,
  &:hover span {
    color: ${COLORS.Purple};
  }
`;
