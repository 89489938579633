import { ErrorMessage } from 'formik';
import React, { useEffect, useState } from 'react';
import { FormWrapper, Label } from '../input-text/input.styled';
import { DateWrapper, DatePickerInput } from './datePicker.styled';
import { DATE_FORMAT } from '../../constants/common';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { isEmpty } from 'lodash';

interface DatePickerTypeProps {
  label?: string;
  name: string;
  placeholder?: string;
  touched?: any;
  labelClassName?: string;
  onChange?: any;
  errors?: any;
  errorValue?: any;
  touchedValue?: any;
  required?: boolean;
  disabled?: boolean;
  setFieldValue?: any;
  values?: any;
  arrValue?: any;
  as?: string;
  min?: string | Date;
  onKeyUp?: any;
  defaultValue?: any;
  max?: string | Date;
  inputType?: 'View' | string;
  width?: string;
  setFieldTouched?: any;
}

const CustomDatePicker = React.memo((props: DatePickerTypeProps) => {
  const [title, setTitle] = useState<string>('');
  const [datePickerActive, setDatePickerActive] = useState<boolean>(false);
  const {
    label,
    name,
    onChange,
    touched,
    placeholder,
    labelClassName,
    errors,
    errorValue,
    touchedValue,
    disabled,
    required,
    onKeyUp,
    values,
    arrValue,
    min = null,
    defaultValue,
    setFieldValue,
    inputType,
    max = null,
    width,
    setFieldTouched,
  } = props;

  useEffect(() => {
    if (
      (Boolean(arrValue) && Boolean(arrValue)) ||
      (Boolean(values) && Boolean(values[name]) && Boolean(values[name]))
    ) {
      if (setFieldTouched != null) {
        setFieldTouched(name, true);
      }
    }
  }, [values, arrValue]);

  return (
    <FormWrapper>
      <div className='flex flex-col'>
        {label != null && (
          <Label
            htmlFor={name}
            className={`${labelClassName ?? ''} ${inputType === 'View' ? 'label-view' : ''}`}>
            {label} {!(required ?? false) && <span>(Optional)</span>}
          </Label>
        )}
        <DateWrapper
          className={`${name === title ? 'date-active' : ''}`}
          onClick={() => {
            setDatePickerActive(true);
          }}>
          <DatePickerInput
            className={`${inputType === 'View' ? 'input-view' : ''} customDatePicker ${
              datePickerActive ? 'customDatePicker-active' : ''
            }`}
            onClick={() => {
              setTitle(name);
            }}>
            <DatePicker
              name={name}
              onClickOutside={() => {
                setDatePickerActive(false);
              }}
              label={label}
              autoComplete='off'
              className={
                (Boolean(errors) &&
                  Boolean(errors[name]) &&
                  Boolean(touched) &&
                  Boolean(touched[name])) ||
                (Boolean(errorValue) && Boolean(touchedValue))
                  ? 'is-invalid'
                  : ''
              }
              format={DATE_FORMAT}
              minDate={min != null ? moment(min).toDate() : moment().subtract(100, 'year').toDate()}
              maxDate={moment(max).toDate() ?? null}
              onKeyUp={onKeyUp}
              placeholderText={placeholder ?? `${DATE_FORMAT}`}
              onChange={async (date: Date | null) => {
                if (setFieldValue) {
                  if (date) {
                    Boolean(setFieldValue) &&
                      (await setFieldValue(name, moment(date).format('YYYY-MM-DD')));
                  } else {
                    setFieldValue(name, '');
                  }
                } else {
                  Boolean(await onChange) && onChange(date);
                }
              }}
              defaultValue={defaultValue}
              selected={
                !isEmpty(arrValue) && arrValue != null && arrValue !== 'Invalid date'
                  ? moment(arrValue).toDate()
                  : !isEmpty(values)
                  ? Boolean(values[name]) && values[name] !== 'Invalid date'
                    ? moment(values[name]).toDate()
                    : null
                  : null
              }
              disabled={disabled}
              style={{ width: width ?? '100%' }}
            />
            {inputType !== 'View' && (
              <span className='material-symbols-outlined'>calendar_month</span>
            )}
          </DatePickerInput>
        </DateWrapper>

        {(Boolean(errorValue) || Boolean(errors)) && (
          <ErrorMessage name={name} component='div' className='error-message' />
        )}
      </div>
    </FormWrapper>
  );
});

export default CustomDatePicker;
