/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_ResidenceStatus_ResidenceStatusDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_ResidenceStatus_ResidenceStatusDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_CustomerService_Dtos_ResidenceStatus_CreateUpdateResidenceStatusDto } from '../models/Wealthlane_CustomerService_Dtos_ResidenceStatus_CreateUpdateResidenceStatusDto';
import type { Wealthlane_CustomerService_Dtos_ResidenceStatus_ResidenceStatusDto } from '../models/Wealthlane_CustomerService_Dtos_ResidenceStatus_ResidenceStatusDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ResidenceStatusService {

    /**
     * @returns Wealthlane_CustomerService_Dtos_ResidenceStatus_ResidenceStatusDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppResidenceStatusResidenceStatusList(): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_ResidenceStatus_ResidenceStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/residence-status/residence-status-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_ResidenceStatus_ResidenceStatusDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppResidenceStatus(
requestBody?: Wealthlane_CustomerService_Dtos_ResidenceStatus_CreateUpdateResidenceStatusDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_ResidenceStatus_ResidenceStatusDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/residence-status',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_ResidenceStatus_ResidenceStatusDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppResidenceStatus(
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_ResidenceStatus_ResidenceStatusDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/residence-status',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_ResidenceStatus_ResidenceStatusDto Success
     * @throws ApiError
     */
    public static putApiCustomerApiAppResidenceStatus(
id: string,
requestBody?: Wealthlane_CustomerService_Dtos_ResidenceStatus_CreateUpdateResidenceStatusDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_ResidenceStatus_ResidenceStatusDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/customer/api/app/residence-status/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiCustomerApiAppResidenceStatus(
id: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/customer/api/app/residence-status/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_CustomerService_Dtos_ResidenceStatus_ResidenceStatusDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppResidenceStatus1(
id: string,
): CancelablePromise<Wealthlane_CustomerService_Dtos_ResidenceStatus_ResidenceStatusDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/residence-status/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
