/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_AwardService_Dtos_Common_CommonGuidStatusDto } from '../models/Wealthlane_AwardService_Dtos_Common_CommonGuidStatusDto';
import type { Wealthlane_AwardService_Dtos_Common_CommonStatusDto } from '../models/Wealthlane_AwardService_Dtos_Common_CommonStatusDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StatusDropdownAppServicesService {

    /**
     * @returns Wealthlane_AwardService_Dtos_Common_CommonStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppStatusDropdownAppServicesTradePreClearanceTransactionPeriodType(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Common_CommonStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/status-dropdown-app-services/trade-pre-clearance-transaction-period-type',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Common_CommonStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppStatusDropdownAppServicesEmployeeStatus(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Common_CommonStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/status-dropdown-app-services/employee-status',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Common_CommonStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppStatusDropdownAppServicesOptionAcceptance(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Common_CommonStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/status-dropdown-app-services/option-acceptance',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Common_CommonStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppStatusDropdownAppServicesOptionAwardStatus(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Common_CommonStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/status-dropdown-app-services/option-award-status',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Common_CommonStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppStatusDropdownAppServicesOptionDisposition(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Common_CommonStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/status-dropdown-app-services/option-disposition',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Common_CommonStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppStatusDropdownAppServicesOptionStatus(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Common_CommonStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/status-dropdown-app-services/option-status',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Common_CommonStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppStatusDropdownAppServicesParticipantApprovalStatus(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Common_CommonStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/status-dropdown-app-services/participant-approval-status',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Common_CommonStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppStatusDropdownAppServicesPlanApprovalStatus(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Common_CommonStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/status-dropdown-app-services/plan-approval-status',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Common_CommonStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppStatusDropdownAppServicesRSUAcceptance(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Common_CommonStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/status-dropdown-app-services/r-sUAcceptance',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Common_CommonStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppStatusDropdownAppServicesRSUAwardStatus(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Common_CommonStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/status-dropdown-app-services/r-sUAward-status',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Common_CommonStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppStatusDropdownAppServicesRSUStatus(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Common_CommonStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/status-dropdown-app-services/r-sUStatus',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Common_CommonStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppStatusDropdownAppServicesPSUAcceptance(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Common_CommonStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/status-dropdown-app-services/p-sUAcceptance',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Common_CommonStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppStatusDropdownAppServicesPSUAwardStatus(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Common_CommonStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/status-dropdown-app-services/p-sUAward-status',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Common_CommonStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppStatusDropdownAppServicesPSUStatus(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Common_CommonStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/status-dropdown-app-services/p-sUStatus',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Common_CommonStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppStatusDropdownAppServicesTradePreClearanceApprovalStatus(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Common_CommonStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/status-dropdown-app-services/trade-pre-clearance-approval-status',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Common_CommonStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppStatusDropdownAppServicesRoundingTypes(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Common_CommonStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/status-dropdown-app-services/rounding-types',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Common_CommonGuidStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppStatusDropdownAppServicesExerciseRecordTypes(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Common_CommonGuidStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/status-dropdown-app-services/exercise-record-types',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Common_CommonGuidStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppStatusDropdownAppServicesReleaseRecordTypes(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Common_CommonGuidStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/status-dropdown-app-services/release-record-types',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Common_CommonGuidStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppStatusDropdownAppServicesRecordTypes(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Common_CommonGuidStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/status-dropdown-app-services/record-types',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
