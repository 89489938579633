import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/authService';
import SpinnerPageComponent from '../components/spinner/spinnerPage';

const Callback = (): JSX.Element => {
  const navigate = useNavigate();
  const authService = new AuthService();

  useEffect(() => {
    const handleSignInRedirectCallback = async (): Promise<void> => {
      try {
        await authService.signInRedirectCallback();
      } catch (error) {}
    };

    if (!authService.isAuthenticated()) {
      void handleSignInRedirectCallback();
    } else {
      navigate('/');
    }
  }, []);
  return <SpinnerPageComponent />;
};

export default Callback;
