import { COLORS } from '../../../constants/style/color';
import styled from 'styled-components';
import { rem } from '../../../utils/style';

export const NotificationContainer = styled.div`
  .notification {
    padding: ${rem(15)} ${rem(32)};
    border-bottom: 1px solid ${COLORS.CulturedWhite};
    position: relative;

    &:last-child {
      border-bottom: none;
    }

    &::before {
      content: '';
      position: absolute;
      top: ${rem(20)};
      left: ${rem(32)};
      width: 9px;
      height: 9px;
      border-radius: ${rem(2)};
      background-color: ${COLORS.Purple};
    }

    &.notification-seen::before {
      background-color: ${COLORS.Green};
    }

    &-title {
      margin-bottom: ${rem(5)};
      padding-left: ${rem(14)};
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    &-date {
      color: ${COLORS.SpanishGray};
      padding-left: ${rem(14)};
    }

    &-delete {
      color: ${COLORS.Red};
      font-size: ${rem(20)};
      cursor: pointer;
      margin-top: ${rem(2.5)};
    }

    &-loader {
      padding-top: ${rem(16)};
    }

    &-view {
      margin-top: 6px;
      padding: ${rem(24)};
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
