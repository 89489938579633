import { rem } from '../../utils/style';
import styled, { css, keyframes } from 'styled-components';
import { COLORS } from '../../constants/style/color';

interface BadgeWrapperType {
  variant: 'primary' | 'secondary';
}

const breatheAnimation = keyframes`
 100%,
    75% {
      transform: scale(1);
      opacity: 0;
    }
`;

export const BadgeWrapper = styled.div<BadgeWrapperType>`
  position: relative;
  padding: ${rem(7)} ${rem(12.3)};
  cursor: pointer;
  border-radius: 6px;
  transition: all 300ms ease;

  &.active,
  &:hover {
    background-color: ${(props) =>
      props.variant.length > 0 ? `${COLORS.LightPurple}` : `${COLORS.Iris}`};
  }

  .material-symbols-outlined {
    width: ${rem(34)};
    height: ${rem(34)};
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: ${rem(18)};
    font-weight: 900;

    ${(props) => {
      if (props.variant === 'primary') {
        return css`
          border: 2px solid ${COLORS.Purple};
          color: ${COLORS.Purple};
        `;
      }
      if (props.variant === 'secondary') {
        return css`
          border: 2px solid ${COLORS.Iris};
          color: ${COLORS.Iris};
        `;
      }
    }}
  }

  .count {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${COLORS.Red};
    width: 13px;
    height: 13px;
    border-radius: 50%;
    top: 28px;
    right: 12px;
    font-size: ${rem(8)};
    color: ${COLORS.White};

    &:before {
      /* content: '';
      position: absolute;
      animation: ${breatheAnimation} 1s cubic-bezier(0, 0, 0.2, 1) infinite;
      top: 2px;
      right: 2px;
      opacity: 1;
      background-color: rgba(216, 69, 69, 0.5);
      padding: 0.56rem;
      border-radius: 999px; */
    }
  }

  .badge-title {
    padding-left: ${rem(8)};
    font-size: ${rem(16)};
    font-weight: 400;
  }
`;
