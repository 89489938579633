/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_TransferAllocate_GetTransferAllocateDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_TransferAllocate_GetTransferAllocateDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateDetailDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateDetailDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_AwardService_Dtos_OptionExercise_UpdateTaxesDto } from '../models/Wealthlane_AwardService_Dtos_OptionExercise_UpdateTaxesDto';
import type { Wealthlane_AwardService_Dtos_Release_StatusDto } from '../models/Wealthlane_AwardService_Dtos_Release_StatusDto';
import type { Wealthlane_AwardService_Dtos_Response_ResponseDto } from '../models/Wealthlane_AwardService_Dtos_Response_ResponseDto';
import type { Wealthlane_AwardService_Dtos_TransferAllocate_CreateDWACRequestDto } from '../models/Wealthlane_AwardService_Dtos_TransferAllocate_CreateDWACRequestDto';
import type { Wealthlane_AwardService_Dtos_TransferAllocate_CreateUpdateTransferAllocateDto } from '../models/Wealthlane_AwardService_Dtos_TransferAllocate_CreateUpdateTransferAllocateDto';
import type { Wealthlane_AwardService_Dtos_TransferAllocate_ExportPendingTransferAllocatedRequestDto } from '../models/Wealthlane_AwardService_Dtos_TransferAllocate_ExportPendingTransferAllocatedRequestDto';
import type { Wealthlane_AwardService_Dtos_TransferAllocate_TransactionDetailOutputDto } from '../models/Wealthlane_AwardService_Dtos_TransferAllocate_TransactionDetailOutputDto';
import type { Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateDto } from '../models/Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateDto';
import type { Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateIdsDto } from '../models/Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateIdsDto';
import type { Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateItemDto } from '../models/Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateItemDto';
import type { Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateResponseDto } from '../models/Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateResponseDto';
import type { Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateSummaryDto } from '../models/Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateSummaryDto';
import type { Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateTransactionDetailDto } from '../models/Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateTransactionDetailDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TransferAllocateService {

    /**
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @param searchKeyWord 
     * @param name 
     * @param containerStatusIds 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_TransferAllocate_GetTransferAllocateDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAwardApiAppTransferAllocateTransferAllocateList(
sorting?: string,
skipCount?: number,
maxResultCount?: number,
searchKeyWord?: string,
name?: string,
containerStatusIds?: Array<number>,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_TransferAllocate_GetTransferAllocateDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/transfer-allocate/transfer-allocate-list',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'SearchKeyWord': searchKeyWord,
                'Name': name,
                'ContainerStatusIds': containerStatusIds,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param transferAllocateName 
     * @returns Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppTransferAllocateCheckDuplicateTransferAllocateName(
transferAllocateName?: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/transfer-allocate/check-duplicate-transfer-allocate-name',
            query: {
                'transferAllocateName': transferAllocateName,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param transferAllocateId 
     * @param name 
     * @returns Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateDto Success
     * @throws ApiError
     */
    public static putApiAwardApiAppTransferAllocateTransferAllocateName(
transferAllocateId: string,
name?: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/award/api/app/transfer-allocate/transfer-allocate-name/{transferAllocateId}',
            path: {
                'transferAllocateId': transferAllocateId,
            },
            query: {
                'name': name,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @param searchKeyWord 
     * @param grantId 
     * @param awardTypeId 
     * @param fromDate 
     * @param toDate 
     * @param settlementTypeIds 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateDetailDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAwardApiAppTransferAllocateForAllocateRequests(
sorting?: string,
skipCount?: number,
maxResultCount?: number,
searchKeyWord?: string,
grantId?: string,
awardTypeId?: string,
fromDate?: string,
toDate?: string,
settlementTypeIds?: Array<string>,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateDetailDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/transfer-allocate/for-allocate-requests',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'SearchKeyWord': searchKeyWord,
                'GrantId': grantId,
                'AwardTypeId': awardTypeId,
                'FromDate': fromDate,
                'ToDate': toDate,
                'SettlementTypeIds': settlementTypeIds,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppTransferAllocateTransfer(
requestBody?: Wealthlane_AwardService_Dtos_TransferAllocate_CreateUpdateTransferAllocateDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/transfer-allocate/transfer',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @param searchKeyWord 
     * @param grantId 
     * @param awardTypeId 
     * @param fromDate 
     * @param toDate 
     * @param settlementTypeIds 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateDetailDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAwardApiAppTransferAllocateAllocatedList(
id: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
searchKeyWord?: string,
grantId?: string,
awardTypeId?: string,
fromDate?: string,
toDate?: string,
settlementTypeIds?: Array<string>,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateDetailDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/transfer-allocate/{id}/allocated-list',
            path: {
                'id': id,
            },
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'SearchKeyWord': searchKeyWord,
                'GrantId': grantId,
                'AwardTypeId': awardTypeId,
                'FromDate': fromDate,
                'ToDate': toDate,
                'SettlementTypeIds': settlementTypeIds,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param transferAllocateId 
     * @returns boolean Success
     * @throws ApiError
     */
    public static deleteApiAwardApiAppTransferAllocateTransfer(
transferAllocateId: string,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/award/api/app/transfer-allocate/transfer/{transferAllocateId}',
            path: {
                'transferAllocateId': transferAllocateId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param transferAllocateId 
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateDto Success
     * @throws ApiError
     */
    public static putApiAwardApiAppTransferAllocateTransfer(
transferAllocateId: string,
requestBody?: Wealthlane_AwardService_Dtos_TransferAllocate_CreateUpdateTransferAllocateDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/award/api/app/transfer-allocate/transfer/{transferAllocateId}',
            path: {
                'transferAllocateId': transferAllocateId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns boolean Success
     * @throws ApiError
     */
    public static deleteApiAwardApiAppTransferAllocateTransferAllocateItem(
requestBody?: Array<Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateIdsDto>,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/award/api/app/transfer-allocate/transfer-allocate-item',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param transferAllocateItemId 
     * @returns Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateItemDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppTransferAllocateTransferAllocateItemDetail(
transferAllocateItemId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateItemDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/transfer-allocate/transfer-allocate-item-detail/{transferAllocateItemId}',
            path: {
                'transferAllocateItemId': transferAllocateItemId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param transferAllocateItemId 
     * @returns Wealthlane_AwardService_Dtos_TransferAllocate_TransactionDetailOutputDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppTransferAllocateTransactionDetailByTransferAllocateItemId(
transferAllocateItemId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_TransferAllocate_TransactionDetailOutputDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/transfer-allocate/transaction-detail-by-transfer-allocate-item-id/{transferAllocateItemId}',
            path: {
                'transferAllocateItemId': transferAllocateItemId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param transferAllocateItemId 
     * @returns Wealthlane_AwardService_Dtos_OptionExercise_UpdateTaxesDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppTransferAllocateTaxDetailsByTransferAllocateItemId(
transferAllocateItemId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_OptionExercise_UpdateTaxesDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/transfer-allocate/tax-details-by-transfer-allocate-item-id/{transferAllocateItemId}',
            path: {
                'transferAllocateItemId': transferAllocateItemId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiAwardApiAppTransferAllocateTransferAllocate(
requestBody?: Wealthlane_AwardService_Dtos_TransferAllocate_CreateDWACRequestDto,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/transfer-allocate/transfer-allocate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param transferAllocateId 
     * @returns Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppTransferAllocateTransferAllocateById(
transferAllocateId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/transfer-allocate/transfer-allocate-by-id/{transferAllocateId}',
            path: {
                'transferAllocateId': transferAllocateId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Release_StatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppTransferAllocateTransferStatuses(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Release_StatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/transfer-allocate/transfer-statuses',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Release_StatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppTransferAllocateContainerStatuses(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Release_StatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/transfer-allocate/container-statuses',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param transferAllocateId 
     * @returns Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateSummaryDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppTransferAllocateTransferAllocateSummaryByTransferAllocateId(
transferAllocateId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateSummaryDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/transfer-allocate/transfer-allocate-summary-by-transfer-allocate-id/{transferAllocateId}',
            path: {
                'transferAllocateId': transferAllocateId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static postApiAwardApiAppTransferAllocateExportPendingTransferAllocatedShares(
requestBody?: Wealthlane_AwardService_Dtos_TransferAllocate_ExportPendingTransferAllocatedRequestDto,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/transfer-allocate/export-pending-transfer-allocated-shares',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param transactionId 
     * @returns Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateTransactionDetailDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppTransferAllocateTransferAllocateDetailByTransactionId(
transactionId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateTransactionDetailDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/transfer-allocate/transfer-allocate-detail-by-transaction-id/{transactionId}',
            path: {
                'transactionId': transactionId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppTransferAllocateImportTransferAllocates(
formData?: {
File?: Blob;
},
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/transfer-allocate/import-transfer-allocates',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
