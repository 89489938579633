import { type UseQueryResult, useQuery } from 'react-query';
import {
  StockService,
  // eslint-disable-next-line camelcase
  type Wealthlane_FinanceIntegrationService_Dtos_StockDto_StockDetailDto,
} from '../../services/wealthlane-finance-integration-services';
import { type SortByType } from '../../types/common';

const useGetStockForCustomer = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  stockFilter?: SortByType
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_FinanceIntegrationService_Dtos_StockDto_StockDetailDto[] | undefined,
  unknown
> => {
  const stockApi = useQuery(['getStockForCustomerData', stockFilter], async () => {
    if (stockFilter !=null && Boolean(stockFilter)) {
      if (stockFilter === '1M') {
        return await handleRequest(
          StockService.getApiFinanceIntegrationApiAppStockOneMonthStockData()
        );
      }
      if (stockFilter === 'YTD') {
        return await handleRequest(
          StockService.getApiFinanceIntegrationApiAppStockYearToDateStockData()
        );
      }
      if (stockFilter === '1Y') {
        return await handleRequest(
          StockService.getApiFinanceIntegrationApiAppStockOneYearStockData()
        );
      }
      if (stockFilter === '3Y') {
        return await handleRequest(
          StockService.getApiFinanceIntegrationApiAppStockThreeYearStockData()
        );
      }
      if (stockFilter === '5Y') {
        return await handleRequest(
          StockService.getApiFinanceIntegrationApiAppStockFiveYearStockData()
        );
      }
    }
    // else {
    //   return await handleRequest(
    //     StockService.getApiFinanceIntegrationApiAppStockStocksForCustomer()
    //   );
    // }
  });
  return stockApi;
};

export default useGetStockForCustomer;
