/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_ParticipantLiquidNetWorth_ParticipantLiquidNetWorthDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_ParticipantLiquidNetWorth_ParticipantLiquidNetWorthDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_CustomerService_Dtos_ParticipantLiquidNetWorth_CreatUpdateParticipantLiquidNetWorthDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantLiquidNetWorth_CreatUpdateParticipantLiquidNetWorthDto';
import type { Wealthlane_CustomerService_Dtos_ParticipantLiquidNetWorth_ParticipantLiquidNetWorthDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantLiquidNetWorth_ParticipantLiquidNetWorthDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ParticipantLiquidNetWorthService {

    /**
     * @returns Wealthlane_CustomerService_Dtos_ParticipantLiquidNetWorth_ParticipantLiquidNetWorthDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppParticipantLiquidNetWorthParticipantLiquidNetWorthsList(): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_ParticipantLiquidNetWorth_ParticipantLiquidNetWorthDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/participant-liquid-net-worth/participant-liquid-net-worths-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_ParticipantLiquidNetWorth_ParticipantLiquidNetWorthDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppParticipantLiquidNetWorth(
requestBody?: Wealthlane_CustomerService_Dtos_ParticipantLiquidNetWorth_CreatUpdateParticipantLiquidNetWorthDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_ParticipantLiquidNetWorth_ParticipantLiquidNetWorthDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/participant-liquid-net-worth',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_ParticipantLiquidNetWorth_ParticipantLiquidNetWorthDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppParticipantLiquidNetWorth(
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_ParticipantLiquidNetWorth_ParticipantLiquidNetWorthDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/participant-liquid-net-worth',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_ParticipantLiquidNetWorth_ParticipantLiquidNetWorthDto Success
     * @throws ApiError
     */
    public static putApiCustomerApiAppParticipantLiquidNetWorth(
id: string,
requestBody?: Wealthlane_CustomerService_Dtos_ParticipantLiquidNetWorth_CreatUpdateParticipantLiquidNetWorthDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_ParticipantLiquidNetWorth_ParticipantLiquidNetWorthDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/customer/api/app/participant-liquid-net-worth/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiCustomerApiAppParticipantLiquidNetWorth(
id: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/customer/api/app/participant-liquid-net-worth/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_CustomerService_Dtos_ParticipantLiquidNetWorth_ParticipantLiquidNetWorthDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppParticipantLiquidNetWorth1(
id: string,
): CancelablePromise<Wealthlane_CustomerService_Dtos_ParticipantLiquidNetWorth_ParticipantLiquidNetWorthDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/participant-liquid-net-worth/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
