import { type UseQueryResult, useQuery } from 'react-query';
import {
  TransactionService,
  TransferAllocateService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_Transactions_TransactionDto,
} from '../../services/wealthlane-award-services';

const useGetTransactionHistoryTransactionDetails = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_Transactions_TransactionDto | undefined,
  unknown
> => {
  const transactionHistoryTransactionDetails = useQuery(
    ['getTransactionHistoryTransactionDetails', id],
    async () => {
      if (id) {
        return await handleRequest(
          TransactionService.getApiAwardApiAppTransactionTransactionById(id)
        );
      }
    }
  );

  return transactionHistoryTransactionDetails;
};

export default useGetTransactionHistoryTransactionDetails;
