import styled from 'styled-components';
import { COLORS } from '../../constants/style/color';
import { getRgbaValue, rem } from '../../utils/style';

export const UploadWrapper = styled.div`
  position: relative;
  border-radius: ${rem(4)};
  background-color: ${COLORS.White};
  padding: ${rem(10)} ${rem(8)};

  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: ${rem(100)};

  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-size: 1.5px 100%, 100% 1.5px, 1.5px 100%, 100% 1.5px;
  background-repeat: no-repeat;
  &.is-invalid {
    background-image: repeating-linear-gradient(
        0deg,
        ${COLORS.Red},
        ${COLORS.Red} 7px,
        transparent 7px,
        transparent 14px,
        ${COLORS.Red} 14px
      ),
      repeating-linear-gradient(
        90deg,
        ${COLORS.Red},
        ${COLORS.Red} 7px,
        transparent 7px,
        transparent 14px,
        ${COLORS.Red} 14px
      ),
      repeating-linear-gradient(
        180deg,
        ${COLORS.Red},
        ${COLORS.Red} 7px,
        transparent 7px,
        transparent 14px,
        ${COLORS.Red} 14px
      ),
      repeating-linear-gradient(
        270deg,
        ${COLORS.Red},
        ${COLORS.Red} 7px,
        transparent 7px,
        transparent 14px,
        ${COLORS.Red} 14px
      );
  }

  background-image: repeating-linear-gradient(
      0deg,
      ${COLORS.LightGray},
      ${COLORS.LightGray} 7px,
      transparent 7px,
      transparent 14px,
      ${COLORS.LightGray} 14px
    ),
    repeating-linear-gradient(
      90deg,
      ${COLORS.LightGray},
      ${COLORS.LightGray} 7px,
      transparent 7px,
      transparent 14px,
      ${COLORS.LightGray} 14px
    ),
    repeating-linear-gradient(
      180deg,
      ${COLORS.LightGray},
      ${COLORS.LightGray} 7px,
      transparent 7px,
      transparent 14px,
      ${COLORS.LightGray} 14px
    ),
    repeating-linear-gradient(
      270deg,
      ${COLORS.LightGray},
      ${COLORS.LightGray} 7px,
      transparent 7px,
      transparent 14px,
      ${COLORS.LightGray} 14px
    );
  input {
    position: absolute;
    max-width: 100%;
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
  }

  .uploadDetail {
    display: flex;
    justify-content: center;
    position: relative;
    padding: 10px 0 35px 0;
    gap: ${rem(5)};
    margin-top: 28px;
    /* margin-bottom: 35px; */

    .material-symbols-outlined {
      /* position: absolute; */
      color: ${COLORS.Iris};
      width: ${rem(21)};
      height: ${rem(15)};
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
`;
export const SelectWrapper = styled.div`
  margin-bottom: 18px;
`;
export const FormWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: left;
  flex-direction: column;
  position: relative;
  margin-bottom: 18px;
  input,
  textArea {
    background-color: ${COLORS.White};
    border: ${rem(1)} solid ${COLORS.LightGray};
    border-radius: ${rem(6)};
    padding: ${rem(11)} ${rem(12)};

    font-weight: 400;
    font-size: ${rem(15)};
    line-height: ${rem(16)};

    filter: drop-shadow(${rem(0)} ${rem(2)} ${rem(4)} ${getRgbaValue(`${COLORS.Gray400}`, 0.2)});
    transition: all 0.2s 0s ease;
    &:hover,
    &:focus {
      border: ${rem(1)} solid ${COLORS.Blue};
      outline: none;
    }

    &:not(:disabled).is-invalid {
      border: ${rem(1)} solid ${COLORS.Red};
    }
    &:not(:disabled).is-valid {
      border: ${rem(1)} solid ${COLORS.Green};
    }

    &:disabled {
      background-color: ${COLORS.OffWhite};
      border: ${rem(1)} solid ${COLORS.LightGray};
    }

    &.input-password {
      padding: ${rem(8.5)} ${rem(45)} ${rem(8.5)} ${rem(12)};
    }

    &::placeholder {
      font-weight: 400;
      color: ${COLORS.LightGray};
    }
  }

  .password-icon {
    font-size: ${rem(20)};
    content: '';
    position: absolute;
    right: ${rem(14)};
    top: ${rem(36)};
    cursor: pointer;
  }
  .year {
    font-size: ${rem(14)};
    padding: ${rem(8)};
    content: '';
    position: absolute;
    right: ${rem(3)};
    top: ${rem(30)};
    background: ${COLORS.OffWhite} !important;
    span {
      color: ${COLORS.Gray} !important;
    }
    /* cursor: pointer; */
  }

  .inputNote {
    margin-top: ${rem(8)};
  }
`;

export const LabelNormal = styled.label`
  display: block;
  font-weight: 400;
  line-height: ${rem(22)};
  margin-bottom: ${rem(4)};
  font-size: ${(props: any) => (props?.fontSize != null ? props.fontSize : `${rem(16)}`)};

  span {
    font-weight: 400;
  }
`;

export const Label = styled.label`
  display: block;
  font-weight: 400;
  line-height: ${rem(22)};
  margin-bottom: ${rem(4)};
  font-size: ${(props: any) => (props.fontSize != null ? props.fontSize : `${rem(16)}`)};

  @media (min-width: 512px) {
    white-space: nowrap;
  }

  span {
    font-weight: 400;
  }

  &.tooltipView {
    display: flex;
    align-items: center;
    gap: ${rem(4)};

    .tooltip-text {
      display: flex;
    }

    .material-symbols-outlined,
    .material-symbols-sharp {
      font-size: ${rem(16)};
      color: ${COLORS.Silver};
    }
  }
`;

// Old Design
export const ImagePreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr)); */
`;

export const ImageViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* display: grid; */
  /* grid-template-columns: repeat(2, minmax(0, 1fr)); */

  .view-wrapper {
    border: 1px solid ${COLORS.Gray800};
    border-radius: 3px;
    padding: 6px 14px;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: ${COLORS.Gray600};
    /* flex justify-between items-center bg-gray-100 px-3 py-1  */
  }

  p {
    width: 80%;
  }
`;

export const ImagePreview = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: ${rem(7)};
  z-index: 2;
  /* width: 65px; */
  /* padding:8px; */
  /* margin-bottom:28px; */
  background-color: ${COLORS.OffWhite};
  padding: 8px;
  .image-icon {
    font-size: 44px;
    line-height: 38px;
    margin: 0;
  }
  .image-filename {
    font-weight: 400;
    font-size: 14px;
    /* line-height: 16px; */
    /* word-break: break-all; */
    color: ${COLORS.Gray};
    /* margin-top: 5px; */

    /* display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; */
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 8px;
  }
  .image-delete {
    color: ${COLORS.Red};
    cursor: pointer;
  }

  .image-wrapper {
    /* height: 65px; */
    border-radius: 5px;
    overflow: hidden;
    background-color: ${COLORS.OffWhite};
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    /* img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 2;
      background-color: ${COLORS.Gray600};
      position: relative;
      padding: 17px;
    } */

    /* .image-cross {
      content: ' ';
      position: absolute;
      top: 5px;
      right: 5px;
      height: 12px;
      width: 12px;
      cursor: pointer;
      z-index: 3;
    } */

    /* .load-image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;

      svg {
        padding: 5px;
        width: 30px;
        height: 30px;
      }
    } */
  }

  .image-wrap {
    padding: 0 !important;
  }
`;
