import { type UseQueryResult, useQuery } from 'react-query';
import {
  ParticipantLogInAndSecurityService,
  // eslint-disable-next-line camelcase
  type Wealthlane_CustomerService_Dtos_ParticipantLogInAndSecurity_ParticipantLogInAndSecurityDto,
} from '../../services/wealthlane-customer-services';

const useGetParticipantLoginAndSecurity = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Wealthlane_CustomerService_Dtos_ParticipantLogInAndSecurity_ParticipantLogInAndSecurityDto
  | undefined,
  unknown
> => {
  const participantAndLogin = useQuery(['getParticipantAndLogin'], async () => {
    return await handleRequest(
      ParticipantLogInAndSecurityService.getApiCustomerApiAppParticipantLogInAndSecurityParticipantLogInSecurity()
    );
  });
  return participantAndLogin;
};

export default useGetParticipantLoginAndSecurity;
