/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_AwardSettlementService_Dtos_ElectionWindowConfiguration_DefaultSettlementConfigDto } from '../models/Wealthlane_AwardSettlementService_Dtos_ElectionWindowConfiguration_DefaultSettlementConfigDto';
import type { Wealthlane_AwardSettlementService_Dtos_ElectionWindowConfiguration_ElectionWindowConfigurationDto } from '../models/Wealthlane_AwardSettlementService_Dtos_ElectionWindowConfiguration_ElectionWindowConfigurationDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PsuElectionWindowConfigurationService {

    /**
     * @returns Wealthlane_AwardSettlementService_Dtos_ElectionWindowConfiguration_ElectionWindowConfigurationDto Success
     * @throws ApiError
     */
    public static getApiAwardSettlementApiAppPSUElectionWindowConfigurationPSUElectionWindowConfigurations(): CancelablePromise<Wealthlane_AwardSettlementService_Dtos_ElectionWindowConfiguration_ElectionWindowConfigurationDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award-settlement/api/app/p-sUElection-window-configuration/p-sUElection-window-configurations',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardSettlementService_Dtos_ElectionWindowConfiguration_ElectionWindowConfigurationDto Success
     * @throws ApiError
     */
    public static postApiAwardSettlementApiAppPSUElectionWindowConfigurationSavePSuElectionWindowConfiguration(
requestBody?: Wealthlane_AwardSettlementService_Dtos_ElectionWindowConfiguration_ElectionWindowConfigurationDto,
): CancelablePromise<Wealthlane_AwardSettlementService_Dtos_ElectionWindowConfiguration_ElectionWindowConfigurationDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award-settlement/api/app/p-sUElection-window-configuration/save-pSUElection-window-configuration',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardSettlementService_Dtos_ElectionWindowConfiguration_DefaultSettlementConfigDto Success
     * @throws ApiError
     */
    public static getApiAwardSettlementApiAppPSUElectionWindowConfigurationDefaultSettlementForPSu(): CancelablePromise<Array<Wealthlane_AwardSettlementService_Dtos_ElectionWindowConfiguration_DefaultSettlementConfigDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award-settlement/api/app/p-sUElection-window-configuration/default-settlement-for-pSU',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardSettlementService_Dtos_ElectionWindowConfiguration_DefaultSettlementConfigDto Success
     * @throws ApiError
     */
    public static getApiAwardSettlementApiAppPSUElectionWindowConfigurationDefaultSettlementForPSuForParticipant(): CancelablePromise<Array<Wealthlane_AwardSettlementService_Dtos_ElectionWindowConfiguration_DefaultSettlementConfigDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award-settlement/api/app/p-sUElection-window-configuration/default-settlement-for-pSUFor-participant',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
