import { type UseMutationResult, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import {
  ParticipantSignUpService,
  // eslint-disable-next-line camelcase
  type Wealthlane_CustomerService_Dtos_Response_ResponseDto,
} from '../../services/wealthlane-customer-services';

const usePostConfirmEmail = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  token1: string,
  token2: string
): UseMutationResult<
  // eslint-disable-next-line camelcase
  Wealthlane_CustomerService_Dtos_Response_ResponseDto | undefined,
  unknown,
  void,
  unknown
> => {
  const payload = {
    emailConfirmationToken: token1,
    participantPersonToken: token2,
  };
  return useMutation(
    async () => {
      return await handleRequest(
        ParticipantSignUpService.postApiCustomerApiAppParticipantSignUpParticipantEmailConfirmation(
          payload
        )
      );
    },
    {
      // eslint-disable-next-line camelcase
      onSuccess: (response: Wealthlane_CustomerService_Dtos_Response_ResponseDto | undefined) => {
        if (response != null) {
          toast.success(`Email Confirmed`);
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostConfirmEmail;
