import { type ReactNode } from 'react';
import { HEADING_TAGS } from '../../constants/common';
import { type TypographyType } from '../../types/common';
import { Text } from './typography.styled';

interface HeadingType {
  variant: TypographyType;
  icon?: string | ReactNode;
  title: string | number | ReactNode;
  className?: string;
  disabled?: boolean;
  color?: string;
  tooltipTitle?: string | null;
  fontWeight?: 400 | 500 | 600 | 700;
  onClick?: any;
}

const Heading = ({
  variant,
  icon,
  title,
  className,
  disabled,
  color,
  tooltipTitle,
  fontWeight,
  onClick,
  ...rest
}: HeadingType): JSX.Element => {
  const textTag = HEADING_TAGS.includes(variant) ? variant : 'span';
  return (
    <Text
      className={className}
      variant={variant}
      as={textTag}
      color={color}
      fontWeight={fontWeight}
      onClick={onClick}
      title={tooltipTitle ?? ''}
      {...rest}>
      {icon}
      {title}
    </Text>
  );
};

export default Heading;
