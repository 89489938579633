/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_Country_CountryDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_Country_CountryDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_CustomerService_Dtos_Country_CountryDto } from '../models/Wealthlane_CustomerService_Dtos_Country_CountryDto';
import type { Wealthlane_CustomerService_Dtos_Country_CreateUpdateCountryDto } from '../models/Wealthlane_CustomerService_Dtos_Country_CreateUpdateCountryDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CountryService {

    /**
     * @returns Wealthlane_CustomerService_Dtos_Country_CountryDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCountryCountries(): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_Country_CountryDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/country/countries',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_Country_CountryDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppCountry(
requestBody?: Wealthlane_CustomerService_Dtos_Country_CreateUpdateCountryDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_Country_CountryDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/country',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_Country_CountryDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCountry(
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_Country_CountryDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/country',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_Country_CountryDto Success
     * @throws ApiError
     */
    public static putApiCustomerApiAppCountry(
id: string,
requestBody?: Wealthlane_CustomerService_Dtos_Country_CreateUpdateCountryDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_Country_CountryDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/customer/api/app/country/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiCustomerApiAppCountry(
id: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/customer/api/app/country/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_CustomerService_Dtos_Country_CountryDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppCountry1(
id: string,
): CancelablePromise<Wealthlane_CustomerService_Dtos_Country_CountryDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/country/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
