import { type UseMutationResult, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import {
  OptionExerciseService,
  Wealthlane_AwardService_Dtos_OptionExercise_OptionExerciseDto,
  Wealthlane_AwardService_Dtos_Response_ResponseDto,
} from '../../services/wealthlane-award-services';
import { useNavigate } from 'react-router-dom';
import ToastComponent from '../../components/toast';

const usePostOptionExerciseSubmit = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseMutationResult<
  // eslint-disable-next-line camelcase
  any | undefined,
  unknown,
  void,
  unknown
> => {
  const navigate = useNavigate();

  return useMutation(
    async (payload: any) => {
      return await handleRequest(
        OptionExerciseService.postApiAwardApiAppOptionExerciseOptionExerciseRequest(payload)
      );
    },
    {
      // eslint-disable-next-line camelcase
      onSuccess: (response: any) => {
        if (response != null) {
          toast.success(<ToastComponent label='Success' message={`Option exercise requested.`} />);
          navigate('/holdings');
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostOptionExerciseSubmit;
