import { type UseQueryResult, useQuery } from 'react-query';
import {
  StockService,
  // eslint-disable-next-line camelcase
  type Wealthlane_FinanceIntegrationService_Dtos_StockDto_StockDetailDto,
} from '../../services/wealthlane-finance-integration-services';
import { type ChartFilter } from '../../types/common';

const useGetClosingStockData = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  ticker?: any
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_FinanceIntegrationService_Dtos_StockDto_StockDetailDto | undefined,
  unknown
> => {
  const closingStockApi = useQuery(['getClosingStockData', ticker], async () => {
    if (ticker) {
      return await handleRequest(
        StockService.getApiFinanceIntegrationApiAppStockClosingStockData(ticker)
      );
    }
  });

  return closingStockApi;
};

export default useGetClosingStockData;
