/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_ReportService_Dtos_AdminDashboardDto } from '../models/Wealthlane_ReportService_Dtos_AdminDashboardDto';
import type { Wealthlane_ReportService_Dtos_MicroServiceResponseDto } from '../models/Wealthlane_ReportService_Dtos_MicroServiceResponseDto';
import type { Wealthlane_ReportService_Dtos_ParticipantDashboardDto } from '../models/Wealthlane_ReportService_Dtos_ParticipantDashboardDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BffService {

    /**
     * @param id 
     * @returns string Success
     * @throws ApiError
     */
    public static getApiReportApiAppBFF(
id?: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/report/api/app/b-fF',
            query: {
                'Id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns string Success
     * @throws ApiError
     */
    public static getApiReportApiAppBFFPsu(
id: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/report/api/app/b-fF/psu/{Id}',
            path: {
                'Id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_ReportService_Dtos_ParticipantDashboardDto Success
     * @throws ApiError
     */
    public static getApiReportApiAppBFFParticipantDashboard(): CancelablePromise<Wealthlane_ReportService_Dtos_ParticipantDashboardDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/report/api/app/b-fF/participant-dashboard',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_ReportService_Dtos_AdminDashboardDto Success
     * @throws ApiError
     */
    public static getApiReportApiAppBFFAdminDashboard(): CancelablePromise<Wealthlane_ReportService_Dtos_AdminDashboardDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/report/api/app/b-fF/admin-dashboard',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiReportApiAppBFFGetMicroServiceTaskStatus(
requestBody?: Wealthlane_ReportService_Dtos_MicroServiceResponseDto,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/report/api/app/b-fF/get-micro-service-task-status',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns string Success
     * @throws ApiError
     */
    public static getApiReportApiAppBFFOptionAwardById(
id: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/report/api/app/b-fF/option-award-by-id/{Id}',
            path: {
                'Id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
