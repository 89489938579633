export const DATE_FORMAT = 'MM/DD/YYYY';
export const INPUT_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_WITH_TIME = 'MM/DD/YYYY LT';

// Global font size
export const DEFAULT_FONT_SIZE = 10;

export const US = 'United States Of America';
export const AWARD_TYPE = 'awardType';
export const PLAN_TYPE = 'planType';

// Heading Tags
export const HEADING_TAGS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p'];

export const SUPPORTED_IMAGE_FORMATS = ['jpg', 'jpeg', 'gif', 'png'];
export const SORTED_AWARDS_LIST = [
  'RSUs',
  'PSUs',
  'ISO',
  'NQSO',
  'Long Share(RSUs)',
  'Long Share(PSUs)',
  'Long Share(Options)',
  'Cash',
];
export const FILE_SIZE = 1024 * 1024 * 10;
export const SUPPORTED_FORMATS = [
  { type: 'jpg', format: 'image/jpg' },
  { type: 'jpeg', format: 'image/jpeg' },
  { type: 'png', format: 'image/png' },
  { type: 'pdf', format: 'application/pdf' },
  { type: 'doc', format: 'application/msword' },
  {
    type: 'docx',
    format: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  },
];
export const SUPPORTED_GOV_FORMATS = [
  { type: 'jpg', format: 'image/jpg' },
  { type: 'jpeg', format: 'image/jpeg' },
  { type: 'png', format: 'image/png' },
  { type: 'pdf', format: 'application/pdf' },
];

export const initialHeight = window.innerHeight;
export const RELOAD_PERMISSION_MESSAGE =
  'Your permission has changed. The current page will be refreshed to load your new permissions.';
export const REDIRECT_PERMISSION_MESSAGE =
  'Your permission has changed. You currently do not have permission to this features. You will be redirected to the overview page. Please work with your access control team if you would like to have access to this feature.';

// yesNo option
export const YesNoOptions = [
  {
    label: 'Yes',
    value: 'Yes',
  },
  {
    label: 'No',
    value: 'No',
  },
];

export const ElectionList = [
  {
    label: 'Sell All',
    value: 'sellAll',
  },
  {
    label: 'Sell To Cover',
    value: 'sellToCover',
  },
  {
    label: 'Withhold To Cover',
    value: 'holdToCover',
  },
];
export const OrderType = [
  {
    label: 'Market Order',
    value: 'marketOrder',
  },
  {
    label: 'Stock Order',
    value: 'stockOrder',
  },
  {
    label: 'Limit Order',
    value: 'limitOrder',
  },
];

export const TRANSFER_METHOD_OPTIONS = [
  {
    label: 'ACH',
    value: '0',
  },
  {
    label: 'Wired',
    value: '1',
    isDisabled: true,
  },
];

// Term And Condition Pages
export const TermAndConditionPage = {
  ParticipantOnboarding: { name: 'Participant Onboarding' },
  RSUAcceptance: { name: 'RSU Acceptance' },
  OptionsAcceptance: { name: 'Options Acceptance' },
  RSUElect: { name: 'RSU Elect' },
  OptionsExercise: { name: 'Options Exercise' },
  PreClearanceRequest: { name: 'Pre-Clearance Request' },
};
