import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Button from '../../../components/button';
import InputText from '../../../components/input-text/inputText';
import ModalBodyComponent from '../../../components/modal/modalBodyComponent';
import OtpForm from '../../../components/otp-form';
import ToastComponent from '../../../components/toast';
import Heading from '../../../components/typography';
import InitialValuesForm from '../../../constants/initialValues';
import { useWealthlaneCustomerApi } from '../../../hooks';
import { ParticipantSignUpService } from '../../../services/wealthlane-customer-services';
import { STEPTWO_VALIDATION } from '../../../utils/validations/validations';
import { ContentBody, ContentContainer, ContentHeader } from './signUpSteps.styled';

interface StepTwoType {
  setStep: (value: number) => void;
}

const StepTwo = ({ setStep }: StepTwoType): JSX.Element => {
  const { handleRequest, isLoading, error } = useWealthlaneCustomerApi();

  const stepTwoValues = window.sessionStorage.getItem('step2');

  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [formData, setFormData] = useState([]);
  const [defaultError, setDefaultError] = useState('');

  useEffect(() => {
    if (error?.body?.error?.validationErrors[0]?.message.includes('OTP'))
      setDefaultError(error?.body?.error?.validationErrors[0]?.message);
  }, [error]);

  function handleSubmit(values: any): void {
    const token = window.sessionStorage.getItem('token');
    const formValues = window.sessionStorage.getItem('step1');
    const payload = {
      ...values,
      socialSecurityNumber: JSON.parse(formValues as any)?.socialSecurityNumber,
      employeeId: JSON.parse(formValues as any)?.employeeId,
      dateOfBirth: JSON.parse(formValues as any)?.dateOfBirth,
      hireDate: JSON.parse(formValues as any)?.hireDate,
      companyTickerSymbol: JSON.parse(formValues as any)?.companyTickerSymbol,
      isUsCitizen: JSON.parse(formValues as any)?.isUsCitizen,
      token,
      code: JSON.parse(formValues as any)?.code,
    };

    handleRequest(
      ParticipantSignUpService.postApiCustomerApiAppParticipantSignUpParticipantEmailVerification(
        payload
      )
    )
      .then((response: any) => {
        if (response != null) {
          const step2 = {
            ...payload,
            ...response,
            ...values,
            code: payload?.code,
            token: payload?.token,
          };
          window.sessionStorage.setItem('step2', JSON.stringify(step2));
          setFormData({ ...payload });
          setIsEmailVerified(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const resendOtp = (): void => {
    handleRequest(
      ParticipantSignUpService.postApiCustomerApiAppParticipantSignUpResendParticipantOTp({
        ...formData,
      } as any)
    )
      .then((response: any) => {
        if (response != null) {
          toast.success(<ToastComponent label='Success' message='OTP resend.' />);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  function sentOtpValue(values: any): void {
    const payload = {
      ...formData,
      otp: values?.otp,
    };
    handleRequest(
      ParticipantSignUpService.postApiCustomerApiAppParticipantSignUpParticipantOTpVerification(
        payload as any
      )
    )
      .then((response: any) => {
        if (response != null) {
          const otpVerification = {
            ...response,
          };
          window.sessionStorage.setItem('otpVerification', JSON.stringify(otpVerification));
          setStep(3);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  return (
    <ContentContainer>
      <ContentHeader>
        <Heading variant={'h4'} title={'Verify Your Email'} className='title' />
      </ContentHeader>
      <ContentBody>
        {isEmailVerified ? (
          <div>
            <OtpForm
              handleRequest={handleRequest}
              formData={formData}
              setStep={setStep}
              isLoading={isLoading}
              handleSubmit={sentOtpValue}
              handleResendOtp={resendOtp}
              errorMessage={defaultError}
              setDefaultError={setDefaultError}
            />
          </div>
        ) : (
          <div>
            <Formik
              enableReinitialize
              initialValues={{
                ...(JSON.parse(stepTwoValues as any) ?? InitialValuesForm.SignUp.stepTwo),
              }}
              validationSchema={STEPTWO_VALIDATION}
              onSubmit={handleSubmit}>
              {({ handleChange, errors, touched, values }) => {
                return (
                  <Form>
                    <ModalBodyComponent>
                      <div className='grid md:grid-cols-3'>
                        <div className=''>
                          <InputText
                            type='text'
                            label='Enter Your Personal Email (Username)'
                            name='email'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                          />
                          <InputText
                            type='text'
                            label='Re-Enter Your Personal Email (Username)'
                            name='confirmemail'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            onPaste={(e: any) => e.preventDefault()}
                            required
                          />
                        </div>
                      </div>
                      <div className='mt-12 w-full flex'>
                        <Button
                          as='Create'
                          variant={'primary'}
                          title={'Request Authorization Code'}
                          type='submit'
                          loading={isLoading}
                          btnClassName='whitespace-normal sm:whitespace-nowrap'
                        />
                      </div>
                    </ModalBodyComponent>
                  </Form>
                );
              }}
            </Formik>
          </div>
        )}
      </ContentBody>
    </ContentContainer>
  );
};

export default StepTwo;
