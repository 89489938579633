import { type UseQueryResult, useQuery } from 'react-query';
import {
  TransferAllocateService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateTransactionDetailDto,
} from '../../services/wealthlane-award-services';

const useGetTransferAndAllocateByTransactionId = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_TransferAllocate_TransferAllocateTransactionDetailDto | undefined,
  unknown
> => {
  const transferAndAllocateByTransactionId = useQuery(
    ['getTransferAndAllocateByTransactionId', id],
    async () => {
      if (id) {
        return await handleRequest(
          TransferAllocateService.getApiAwardApiAppTransferAllocateTransferAllocateDetailByTransactionId(
            id
          )
        );
      }
    }
  );

  return transferAndAllocateByTransactionId;
};

export default useGetTransferAndAllocateByTransactionId;
