import { useSelector } from 'react-redux';
import Spinners from '.';
import Heading from '../typography';
import { SpinnerPage } from './spinner.styled';
import { selectNotify } from '../../auth/notify';

interface SpinnerPageComponentType {
  title?: string;
  subtitle?: string;
}

const SpinnerPageComponent = ({ title, subtitle }: SpinnerPageComponentType): JSX.Element => {
  const showNotify = useSelector(selectNotify);

  return (
    <SpinnerPage className={`h-normal-${showNotify ? 'notify-screen' : 'screen'}`}>
      {title != null && <Heading variant='h2' title={title} />}
      {subtitle != null && <Heading variant='h5' title={subtitle} />}
      <Spinners />
    </SpinnerPage>
  );
};

export default SpinnerPageComponent;
