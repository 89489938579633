import { type UseQueryResult, useQuery } from 'react-query';
import {
  LongShareService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_LongShareService_ParticipantLongSharesListDto,
} from '../../services/wealthlane-award-services';

const useGetParticipantAllLongShares = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  type?: 'RSU' | 'ESOP' | 'PSU'
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_LongShareService_ParticipantLongSharesListDto[] | undefined,
  unknown
> => {
  const participantLongShares = useQuery(
    ['getParticipantLongShares'],
    async () => {
      return await handleRequest(
        LongShareService.getApiAwardApiAppLongShareParticipantLongSharesList(type)
      );
    }
    // {
    //   enabled: type === 'RSU',
    // }
  );

  return participantLongShares;
};

export default useGetParticipantAllLongShares;
