import { type UseQueryResult, useQuery } from 'react-query';
import {
  MyHoldingsService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_MyHoldings_AwardSummaryDto,
} from '../../services/wealthlane-award-services';

const useGetHoldingAwardSummary = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_MyHoldings_AwardSummaryDto[] | undefined,
  unknown
> => {
  const holdingAwardSummary = useQuery(['getHoldingAwardSummary'], async () => {
    return await handleRequest(MyHoldingsService.getApiAwardApiAppMyHoldingsAwardSummaries());
  });
  return holdingAwardSummary;
};

export default useGetHoldingAwardSummary;
