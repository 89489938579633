/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_Dtos_LoginSecurityOptions_ChangeLoginSecurityOptionResponseDto } from '../models/Wealthlane_Dtos_LoginSecurityOptions_ChangeLoginSecurityOptionResponseDto';
import type { Wealthlane_Dtos_LoginSecurityOptions_LoginSecurityOptionsDto } from '../models/Wealthlane_Dtos_LoginSecurityOptions_LoginSecurityOptionsDto';
import type { Wealthlane_Dtos_LoginSecurityOptions_LoginSecurityOptionsForLoggedInUserDto } from '../models/Wealthlane_Dtos_LoginSecurityOptions_LoginSecurityOptionsForLoggedInUserDto';
import type { Wealthlane_Dtos_ParticipantLogInAndSecurity_ChangeParticipantLoginPasswordDto } from '../models/Wealthlane_Dtos_ParticipantLogInAndSecurity_ChangeParticipantLoginPasswordDto';
import type { Wealthlane_Shared_Hosting_Dtos_ParticipantLogInSecurity_ParticipantLogInSecurityResponseDto } from '../models/Wealthlane_Shared_Hosting_Dtos_ParticipantLogInSecurity_ParticipantLogInSecurityResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ParticipantLogInAndSecurityService {

    /**
     * @param requestBody 
     * @returns Wealthlane_Shared_Hosting_Dtos_ParticipantLogInSecurity_ParticipantLogInSecurityResponseDto Success
     * @throws ApiError
     */
    public static postApiIdentityApiAppParticipantLogInAndSecurityChangeParticipantLogInPassword(
requestBody?: Wealthlane_Dtos_ParticipantLogInAndSecurity_ChangeParticipantLoginPasswordDto,
): CancelablePromise<Wealthlane_Shared_Hosting_Dtos_ParticipantLogInSecurity_ParticipantLogInSecurityResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/identity/api/app/participant-log-in-and-security/change-participant-log-in-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_Dtos_LoginSecurityOptions_LoginSecurityOptionsDto Success
     * @throws ApiError
     */
    public static getApiIdentityApiAppParticipantLogInAndSecurityTwoFactorAuthenticationOptions(): CancelablePromise<Array<Wealthlane_Dtos_LoginSecurityOptions_LoginSecurityOptionsDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/identity/api/app/participant-log-in-and-security/two-factor-authentication-options',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param optionId 
     * @returns Wealthlane_Dtos_LoginSecurityOptions_ChangeLoginSecurityOptionResponseDto Success
     * @throws ApiError
     */
    public static postApiIdentityApiAppParticipantLogInAndSecurityChangeLoginSecurityOption(
optionId: number,
): CancelablePromise<Wealthlane_Dtos_LoginSecurityOptions_ChangeLoginSecurityOptionResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/identity/api/app/participant-log-in-and-security/change-login-security-option/{optionId}',
            path: {
                'optionId': optionId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_Dtos_LoginSecurityOptions_LoginSecurityOptionsForLoggedInUserDto Success
     * @throws ApiError
     */
    public static getApiIdentityApiAppParticipantLogInAndSecurityLoginSecurityOptions(): CancelablePromise<Array<Wealthlane_Dtos_LoginSecurityOptions_LoginSecurityOptionsForLoggedInUserDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/identity/api/app/participant-log-in-and-security/login-security-options',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
