import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import { FILE_SIZE, SUPPORTED_FORMATS, SUPPORTED_GOV_FORMATS } from '../../constants/common';
import { GlobalValidation } from './commonValidation';
import { commonValidation } from './validationRule';

const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export const STEPONE_VALIDATION = {
  // isUsCitizen: Yup.string().required('Required field'),

  dateOfBirth: GlobalValidation.Date,
  hireDate: GlobalValidation.Date,
  companyTickerSymbol: GlobalValidation.RequiredNoBlank.matches(
    /^[a-zA-Z ]*$/,
    'Invalid Ticker'
  ).nullable(),
  code: commonValidation({ required: true, digits: true, noBlank: true }),
};

export const STEPTWO_VALIDATION = Yup.object().shape({
  email: GlobalValidation.Email,
  confirmemail: Yup.string()
    .required('Required field')
    .test('match', 'Email do not match', function (confirmemail) {
      return confirmemail?.toLocaleLowerCase() === this.parent.email.toLowerCase();
    }),
});
export const STEP_THREE_VALIDATION = Yup.object().shape({
  password: commonValidation({ required: true }).matches(passwordRegExp, 'Invalid Password'),
  confirmPassword: Yup.string()
    .required('Required field')
    .test('match', 'Password do not match', function (confirmPassword) {
      return confirmPassword === this.parent.password;
    }),
});
export const STEP_FOUR_VALIDATION = Yup.object().shape({
  securityQuestion1: commonValidation({ required: true }).nullable(),
  securityQuestion2: commonValidation({ required: true }).nullable(),
  securityQuestion3: commonValidation({ required: true }).nullable(),
  securityAnswer1: GlobalValidation.RequiredNoBlank,
  securityAnswer2: GlobalValidation.RequiredNoBlank,
  securityAnswer3: GlobalValidation.RequiredNoBlank,
});

export const ON_BOARDING_STEP_ONE_VALIDATION = {
  // Personal Details
  firstName: GlobalValidation.Name.matches(/^[a-zA-Z-'' ]*$/, 'Invalid First Name'),
  // firstName: Yup.string()
  //   .required('Required field')
  //   .matches(/^[a-zA-Z ]*$/, 'Invalid First Name')
  //   .max(100, 'Exceeds max characters')
  //   .min(0, 'Exceeds min characters')
  //   .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
  //   .nullable(),
  middleName: commonValidation({
    required: false,
    textLimit: 100,
    noBlank: true,
    doubleSpace: true,
  }).matches(/^[a-zA-Z-'' . ]*$/, 'Invalid Middle Name'),
  // middleName: Yup.string()
  //   .matches(/^[a-zA-Z.]*$/, 'Invalid Middle Name')
  //   .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
  //   .nullable(),
  lastName: GlobalValidation.Name.matches(/^[a-zA-Z-'' ]*$/, 'Invalid Last Name'),

  // lastName: Yup.string()
  //   .required('Required field')
  //   .matches(/^[a-zA-Z ]*$/, 'Invalid Last Name')
  //   .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
  //   .nullable(),
  dateOfBirth: GlobalValidation.Date,
  // dateOfBirth: Yup.string().required('Required field').nullable(),
  genderId: commonValidation({ required: true }).nullable(),
  // genderId: Yup.string().required('Required field').nullable(),
  maritalStatusId: commonValidation({ required: true }).nullable(),
  // maritalStatusId: Yup.string().required('Required field').nullable(),
  // US Residency and Citizenship Information
  fullLegalName: GlobalValidation.Name.matches(/^[a-zA-Z-'' ]*$/, 'Invalid Legal Name').nullable(),
  // fullLegalName: Yup.string()
  //   .required('Required field')
  //   .matches(/^[a-zA-Z ]*$/, 'Invalid Legal Name')
  //   .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
  //   .nullable(),
  countryOfLegalResidencyId: commonValidation({ required: true }).nullable(),
  // countryOfLegalResidencyId: Yup.string().required('Required field').nullable(),
  governmentDocumentIdNo: Yup.string()
    .nullable()
    .when(['isUsCitizen', 'isUsResidentAlien', 'isCountryOfLegalResidencyOtherCountry'], {
      is: (
        isUsCitizen: boolean,
        isUsResidentAlien: boolean,
        isCountryOfLegalResidencyOtherCountry: boolean
      ) => {
        if (!isUsCitizen && !isUsResidentAlien && isCountryOfLegalResidencyOtherCountry) {
          return true;
        }
      },
      then: (schema) =>
        schema
          .required('Required field')
          .matches(/^[0-9]*$/, 'Must be only digits')
          .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
          .nullable(),
    }),
  governmentDocumentIdIssueDate: Yup.string()
    .nullable()
    .when(['isUsCitizen', 'isUsResidentAlien', 'isCountryOfLegalResidencyOtherCountry'], {
      is: (
        isUsCitizen: boolean,
        isUsResidentAlien: boolean,
        isCountryOfLegalResidencyOtherCountry: boolean
      ) => {
        if (!isUsCitizen && !isUsResidentAlien && isCountryOfLegalResidencyOtherCountry) {
          return true;
        }
      },
      then: (schema) => schema.required('Required field').nullable(),
    }),
  governmentDocumentExpirationDate: Yup.string()
    .nullable()
    .when(['isUsCitizen', 'isUsResidentAlien', 'isCountryOfLegalResidencyOtherCountry'], {
      is: (
        isUsCitizen: boolean,
        isUsResidentAlien: boolean,
        isCountryOfLegalResidencyOtherCountry: boolean
      ) => {
        if (!isUsCitizen && !isUsResidentAlien && isCountryOfLegalResidencyOtherCountry) {
          return true;
        }
      },
      then: (schema) => schema.required('Required field').nullable(),
    }),
  governmentDocumentTypeId: Yup.string()
    .nullable()
    .when(['isUsCitizen', 'isUsResidentAlien', 'isCountryOfLegalResidencyOtherCountry'], {
      is: (
        isUsCitizen: boolean,
        isUsResidentAlien: boolean,
        isCountryOfLegalResidencyOtherCountry: boolean
      ) => {
        if (!isUsCitizen && !isUsResidentAlien && isCountryOfLegalResidencyOtherCountry) {
          return true;
        }
      },
      then: (schema) => schema.required('Required field').nullable(),
    }),
  governmentDocuments: Yup.array()
    .of(
      Yup.mixed()
        .test('fileFormat', 'Unsupported file format', (value) => {
          if (value != null) {
            return (
              Boolean(value) &&
              SUPPORTED_GOV_FORMATS.some((item) => item.format === value.file.type)
            );
          } else {
            return (
              Boolean(value) && SUPPORTED_GOV_FORMATS.some((item) => item.format === value.type)
            );
          }
        })
        .test('fileSize', 'File is too large', (value) => value.size <= FILE_SIZE)
    )
    .min(1, 'Required field')
    .when(['isUsCitizen', 'isUsResidentAlien', 'isCountryOfLegalResidencyOtherCountry'], {
      is: (
        isUsCitizen: boolean,
        isUsResidentAlien: boolean,
        isCountryOfLegalResidencyOtherCountry: boolean
      ) => {
        if (!isUsCitizen && !isUsResidentAlien && isCountryOfLegalResidencyOtherCountry) {
          return true;
        }
      },
      otherwise: (d) =>
        Yup.array().of(
          Yup.mixed()
            .test('fileFormat', 'Unsupported file format', (value) => {
              if (value.file != null) {
                return (
                  Boolean(value) &&
                  SUPPORTED_FORMATS.some((item) => item.format === value.file.type)
                );
              } else {
                return (
                  Boolean(value) && SUPPORTED_FORMATS.some((item) => item.format === value.type)
                );
              }
            })
            .test('fileSize', 'File is too large', (value) => value.size <= FILE_SIZE)
        ),
    }),

  // governmentDocumentIdIssueDate: Yup.string().required('Required field').nullable(),
  // governmentDocumentExpirationDate: Yup.string().required('Required field').nullable(),
  // governmentDocumentTypeId: Yup.string().required('Required field').nullable(),
  // governmentDocumentIdIssuedStateText: Yup.string()
  //   .matches(/^[a-zA-Z ]*$/, 'Invalid State Name')
  //   .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
  //   .nullable(),
  // governmentDocumentIdIssuedCityText: Yup.string()
  //   .matches(/^[a-zA-Z ]*$/, 'Invalid City Name')
  //   .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
  //   .nullable(),
  // governmentDocumentIdZipOrPostalCode: Yup.string()
  //   .min(5, 'Must be 5 digits')
  //   .max(5, 'Must be 5 digits')
  //   .matches(/^[0-9]*$/, 'Must be only digits')
  //   .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
  //   .nullable(),

  // Home Address
  currentHomeAddressCountryId: commonValidation({ required: true }).nullable(),
  // currentHomeAddressCountryId: Yup.string().required('Required field').nullable(),
  currentHomeAddressCityText: commonValidation({ required: true }).nullable(),
  // currentHomeAddressCityText: Yup.string().required('Required field').nullable(),
  addressLine1: GlobalValidation.AddressLine1.nullable(),
  // addressLine1: Yup.string()
  //   .required('Required field')
  //   // .matches(/^[a-zA-Z0-9 ]+$/, 'Invalid Address One')
  //   .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
  //   .nullable(),
  currentHomeAddressLine2: GlobalValidation.AddressLine2.nullable(),
  // currentHomeAddressLine2: Yup.string()
  //   // .required('Required field')
  //   // .matches(/^[a-zA-Z0-9 ]+$/, 'Invalid Address Two')
  //   .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
  //   .nullable(),

  currentHomeAddressMobilePhone: GlobalValidation.Phone.min(12, 'Must be 10 digits').when(
    'isOtherCountryPhone',
    {
      is: false,
      otherwise: (d: any) => GlobalValidation.Phone,
    }
  ),
  // currentHomeAddressMobilePhone: commonValidation({
  //   required: true,
  //   numericPhone: true,
  //   noBlank: true,
  // })
  //   .min(12, 'Must be 10 digits')
  //   .when('isOtherCountryPhone', {
  //     is: false,
  //     otherwise: (d: any) =>
  //       commonValidation({
  //         required: true,
  //         numericPhone: true,
  //         noBlank: true,
  //       }),
  //   }),
  currentHomeAddressHomePhone: GlobalValidation.NonRequiredPhone.min(12, 'Must be 10 digits').when(
    'isHomePhoneOtherCountryPhone',
    {
      is: false,
      otherwise: (d: any) => GlobalValidation.NonRequiredPhone,
    }
  ),
  currentHomeAddressStartDate: GlobalValidation.Date,

  // currentHomeAddressHomePhone: commonValidation({
  //   required: false,
  //   numericPhone: true,
  //   noBlank: true,
  // })
  //   .min(12, 'Must be 10 digits')
  //   .when('isHomePhoneOtherCountryPhone', {
  //     is: false,
  //     otherwise: (d: any) =>
  //       commonValidation({
  //         required: false,
  //         numericPhone: true,
  //         noBlank: true,
  //       }),
  //   }),
  // workPhone: Yup.string().required('Required field').nullable(),
  workPhone: GlobalValidation.NonRequiredPhone.min(12, 'Must be 10 digits').when(
    'isWorkPhoneOtherCountryPhone',
    {
      is: false,
      otherwise: (d: any) => GlobalValidation.NonRequiredPhone,
    }
  ),
  // workPhone: commonValidation({
  //   required: false,
  //   numericPhone: true,
  //   noBlank: true,
  // })
  //   .min(12, 'Must be 10 digits')
  //   .when('isWorkPhoneOtherCountryPhone', {
  //     is: false,
  //     otherwise: (d: any) =>
  //       commonValidation({
  //         required: false,
  //         numericPhone: true,
  //         noBlank: true,
  //       }),
  //   }),
  currentHomeAddressZipPostalCode: GlobalValidation.ZipCode.when('isOtherHomeCountry', {
    is: false,
    otherwise: (d: any) =>
      commonValidation({
        required: true,
        digits: true,
      }),
  }),
  // currentHomeAddressZipPostalCode: commonValidation({
  //   required: true,
  //   digits: true,
  // })
  //   .min(5, 'Must be 5 digits')
  //   .max(5, 'Must be 5 digits')
  //   .when('isOtherHomeCountry', {
  //     is: false,
  //     otherwise: (d: any) =>
  //       commonValidation({
  //         required: true,
  //         digits: true,
  //       }),
  //   }),
  // currentHomeAddressMobilePhone: Yup.string().required('Required field').nullable(),

  employerName: Yup.string()
    .required('Required field')
    .matches(/^[a-zA-Z-'' ]*$/, 'Invalid Legal Name')
    .nullable(),
  // employerName: GlobalValidation.Name.matches(/^[a-zA-Z ]*$/, 'Invalid Employer Name').nullable(),
  // employerName: Yup.string()
  //   .required('Required field')
  //   .matches(/^[a-zA-Z ]*$/, 'Invalid Employer Name')
  //   .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
  //   .nullable(),
  employmentAddressLine1: GlobalValidation.AddressLine1.nullable(),
  // employmentAddressLine1: Yup.string()
  //   .required('Required field')
  //   // .matches(/^[a-zA-Z0-9 ]+$/, 'Invalid Employer Name')
  //   .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
  //   .nullable(),
  employmentAddressLine2: GlobalValidation.AddressLine2.nullable(),
  // employmentAddressLine2: Yup.string()
  //   // .required('Required field')
  //   // .matches(/^[a-zA-Z0-9 ]+$/, 'Invalid Employer Name')
  //   .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
  //   .nullable(),
  employmentCountryId: commonValidation({ required: true }),
  employmentCityText: commonValidation({ required: true }),
  /* residenceStatus: commonValidation({ required: true }), */
  // employmentStateId: commonValidation({ required: true }).when('isOtherCountry', {
  //   is: false,
  //   otherwise: (d: any) => Yup.string().nullable(),
  // }),
  // employmentCityId: commonValidation({ required: true }).when('isOtherCountry', {
  //   is: false,
  //   otherwise: (d: any) => Yup.string().nullable(),
  // }),
  // employmentCityText: commonValidation({ required: true }).when('isOtherCountry', {
  //   is: true,
  //   otherwise: (d: any) => Yup.string().nullable(),
  // }),
  // employmentStateText: commonValidation({ required: true }).when('isOtherCountry', {
  //   is: true,
  //   otherwise: (d: any) => Yup.string().nullable(),
  // }),
  mailingAddressZipPostalCode: GlobalValidation.ZipCode.when(
    ['isMailingAddress', 'isMailingAddressOtherCountry'],
    {
      is: (isMailingAddress: boolean, isMailingAddressOtherCountry: boolean) => {
        if (!isMailingAddress && !isMailingAddressOtherCountry) {
          return true;
        }
      },
      otherwise: (d: any) =>
        commonValidation({
          digits: true,
        }),
    }
  ),
  // mailingAddressZipPostalCode: commonValidation({
  //   required: true,
  //   digits: true,
  // })
  //   .min(5, 'Must be 5 digits')
  //   .max(5, 'Must be 5 digits')
  //   .when(['isMailingAddress', 'isMailingAddressOtherCountry'], {
  //     is: (isMailingAddress: boolean, isMailingAddressOtherCountry: boolean) => {
  //       if (isMailingAddress === false && isMailingAddressOtherCountry === false) {
  //         return true;
  //       }
  //     },
  //     otherwise: (d: any) =>
  //       commonValidation({
  //         digits: true,
  //       }),
  //   }),
  employmentZipPostalCode: GlobalValidation.ZipCode.when('isEmploymentOtherCountry', {
    is: false,
    otherwise: (d: any) =>
      commonValidation({
        required: true,
        digits: true,
      }),
  }),
  employmentStateId: commonValidation({ required: true }).when(['employmentZipPostalCode'], {
    is: (employmentZipPostalCode: string) => {
      if (isEmpty(employmentZipPostalCode)) {
        return true;
      }
    },
    otherwise: (d: any) =>
      commonValidation({
        required: false,
      }),
  }),
  // employmentZipPostalCode: commonValidation({
  //   required: true,
  //   digits: true,
  // })
  //   .min(5, 'Must be 5 digits')
  //   .max(5, 'Must be 5 digits')
  //   .when('isEmploymentOtherCountry', {
  //     is: false,
  //     otherwise: (d: any) =>
  //       commonValidation({
  //         digits: true,
  //       }),
  //   }),
};

export const ON_BOARDING_STEP_TWO_VALIDATION = Yup.object().shape({
  accountTypeId: commonValidation({ required: true }).nullable(),
  registrationTypeId: commonValidation({ required: true }).nullable(),
  // Investment Activities
  participantInvestmentObjectiveId: commonValidation({ required: true }).nullable(),
  participantInvestmentExperienceId: commonValidation({ required: true }).nullable(),
  participantAnnualIncomeId: commonValidation({ required: true }).nullable(),
  participantLiquidNetWorthId: commonValidation({ required: true }).nullable(),
  participantTotalNetWorthId: commonValidation({ required: true }).nullable(),
  numberOfDependents: commonValidation({ required: true })
    .matches(/^[0-9]*$/, 'Must be whole number')
    .nullable(),
  // beneficiary
  beneficiaryEmail: GlobalValidation.Email,
  beneficiaryFirstName: GlobalValidation.Name.matches(
    /^[a-zA-Z-'' ]*$/,
    'Invalid First Name'
  ).nullable(),
  beneficiaryLastName: GlobalValidation.Name.matches(
    /^[a-zA-Z-'' ]*$/,
    'Invalid Last Name'
  ).nullable(),
  beneficiaryMiddleName: commonValidation({
    required: false,
    textLimit: 100,
    noBlank: true,
    doubleSpace: true,
  })
    .matches(/^[a-zA-Z-'' . ]*$/, 'Invalid Middle Name')
    .nullable(),
  beneficiaryRelationship: commonValidation({ required: true, noBlank: true }).nullable(),
  beneficiaryMobileCallingCodeId: commonValidation({ required: true }).nullable(),
  beneficiaryMobileNumber: GlobalValidation.Phone.min(12, 'Must be 10 digits').when(
    'isOtherCountryPhone',
    {
      is: false,
      otherwise: (d: any) => GlobalValidation.Phone,
    }
  ),
});
// export const ON_BOARDING_STEP_FOUR_VALIDATION = Yup.object().shape({
//   employeedByARegisteredBrokerDealer: Yup.boolean()
//     .oneOf([true], 'You must accept the terms and conditions')
//     .nullable(),
// });

// export const ON_BOARDING_VALIDATION = {
//   // Personal Details
//   firstName: Yup.string()
//     .required('Required field')
//     .matches(/^[a-zA-Z ]*$/, 'Invalid First Name')
//     .max(100, 'Exceeds max characters')
//     .min(0, 'Exceeds min characters')
//     .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
//     .nullable(),
//   middleName: Yup.string()
//     .matches(/^[a-zA-Z .]*$/, 'Invalid Middle Name')
//     .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
//     .nullable(),
//   lastName: Yup.string()
//     .required('Required field')
//     .matches(/^[a-zA-Z ]*$/, 'Invalid Last Name')
//     .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
//     .nullable(),
//   dateOfBirth: Yup.string().required('Required field').nullable(),

//   // US Residency and Citizenship Information
//   fullLegalName: Yup.string()
//     .required('Required field')
//     .matches(/^[a-zA-Z ]*$/, 'Invalid Legal Name')
//     .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
//     .nullable(),
//   countryOfLegalResidencyId: Yup.string().required('Required field').nullable(),

//   // Home Address
//   currentHomeAddressCountryId: Yup.string().required('Required field').nullable(),
//   addressLine1: Yup.string()
//     .required('Required field')
//     .matches(/^[a-zA-Z ]*$/, 'Invalid Address One')
//     .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
//     .nullable(),
//   currentHomeAddressLine2: Yup.string()
//     .required('Required field')
//     .matches(/^[a-zA-Z ]*$/, 'Invalid Address Two')
//     .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
//     .nullable(),
//   currentHomeAddressHomePhone: Yup.string().required('Required field').nullable(),
//   currentHomeAddressZipPostalCode: Yup.string()
//     .required('Required field')
//     .max(5, 'Maximum 5 dight only')
//     .matches(/^[0-9]*$/, 'Must be only digits')
//     .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
//     .nullable(),
//   currentHomeAddressMobilePhone: Yup.string()
//     .required('Required field')
//     .max(10, 'Maximum 10 dight only')
//     .matches(/^[0-9]*$/, 'Must be only digits')
//     .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
//     .nullable(),

//   // Employment Information
//   employerName: Yup.string()
//     .required('Required field')
//     .matches(/^[a-zA-Z ]*$/, 'Invalid Employer Name')
//     .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
//     .nullable(),
//   employmentZipPostalCode: commonValidation({
//     required: true,
//     digits: true,
//   })
//     .min(5, 'Must be 5 digits')
//     .max(5, 'Must be 5 digits')
//     .when('isEmploymentOtherCountry', {
//       is: false,
//       otherwise: (d: any) => Yup.string().nullable(),
//     }),

//   // Account details
//   accountTypeId: Yup.string().required('Required field').nullable(),
//   registrationTypeId: Yup.string().required('Required field').nullable(),

//   // Investment Activities
//   participantInvestmentObjectiveId: Yup.string().required('Required field').nullable(),
//   participantAnnualIncomeId: Yup.string().required('Required field').nullable(),
//   participantLiquidNetWorthId: Yup.string().required('Required field').nullable(),
//   participantTotalNetWorthId: Yup.string().required('Required field').nullable(),
// };

export const PARTICIPANT_ADDRESS_DETAILS_VALIDATION = {
  firstName: GlobalValidation.Name.matches(/^[a-zA-Z-'' ]*$/, 'Invalid First Name').nullable(),
  middleName: commonValidation({
    required: false,
    textLimit: 100,
    noBlank: true,
    doubleSpace: true,
  })
    .matches(/^[a-zA-Z-'' . ]*$/, 'Invalid Middle Name')
    .nullable(),
  lastName: GlobalValidation.Name.matches(/^[a-zA-Z-'' ]*$/, 'Invalid Last Name').nullable(),
  dateOfBirth: commonValidation({ required: true }),
  maritalStatusId: commonValidation({ required: true }),
  genderId: commonValidation({ required: true }),
  currentHomeAddress: Yup.object().shape({
    addressLine1: GlobalValidation.AddressLine1.nullable(),
    addressLine2: GlobalValidation.AddressLine2.nullable(),
    countryId: commonValidation({ required: true }),
    stateId: commonValidation({ required: true }).when('isOtherCountry', {
      is: false,
      otherwise: (d: any) => Yup.string().nullable(),
    }),
    state: commonValidation({ required: true }).when('isOtherCountry', {
      is: true,
      otherwise: (d: any) => Yup.string().nullable(),
    }),
    city: commonValidation({ required: true }),
    zipCode: GlobalValidation.ZipCode.min(5, 'Must be 5 digits')
      .max(5, 'Must be 5 digits')
      .when('isOtherCountry', {
        is: false,
        otherwise: (d: any) =>
          commonValidation({
            required: true,
            digits: true,
          }),
      }),
  }),
  mailingAddress: Yup.object().shape({
    addressLine1: GlobalValidation.AddressLine1.nullable(),
    addressLine2: GlobalValidation.AddressLine2.nullable(),
    countryId: commonValidation({ required: true }),
    stateId: commonValidation({ required: true }).when('isOtherCountry', {
      is: false,
      otherwise: (d: any) => Yup.string().nullable(),
    }),
    state: commonValidation({ required: true }).when('isOtherCountry', {
      is: true,
      otherwise: (d: any) => Yup.string().nullable(),
    }),
    city: commonValidation({ required: true }),

    zipCode: GlobalValidation.ZipCode.when('isOtherCountry', {
      is: false,
      otherwise: (d: any) =>
        commonValidation({
          required: true,
          digits: true,
          noBlank: true,
        }),
    }).when('isMailingAddressDifferent', {
      is: true,
      otherwise: (d: any) => Yup.object().nullable(),
    }),
  }),
  previousHomeAddress: Yup.array()
    .of(
      Yup.object().shape({
        addressLine1: GlobalValidation.AddressLine1.nullable(),
        addressLine2: GlobalValidation.AddressLine2.nullable(),
        countryId: commonValidation({ required: true }),
        city: commonValidation({ required: true }),
        stateId: commonValidation({ required: true }).when('isOtherCountry', {
          is: false,
          otherwise: (d: any) => Yup.string().nullable(),
        }),
        state: commonValidation({ required: true, noBlank: true }).when('isOtherCountry', {
          is: true,
          otherwise: (d: any) => Yup.string().nullable(),
        }),
        startDate: commonValidation({ required: true }),
        endDate: commonValidation({ required: true }),
        zipCode: GlobalValidation.ZipCode.when('isOtherCountry', {
          is: false,
          otherwise: (d: any) => commonValidation({ required: true, noBlank: true }).nullable(),
        }),
      })
    )
    .nullable(),
};

// export const PARTICIPANT_DETAILS_VALIDATION = Yup.object().shape({
//   dateOfBirth: Yup.string().required('Required field').nullable(),
//   genderId: Yup.string().required('Required field').nullable(),
//   maritalStatusId: Yup.string().required('Required field').nullable(),
//   currentLegalResidenceId: Yup.string()
//     .max(100, 'Maximum characters exceeds')
//     .required('Required field')
//     .nullable(),
//   residenceStatusId: Yup.string().max(100, 'Maximum characters exceeds').nullable(),
//   phoneNumber: Yup.string()
//     .max(100, 'Maximum characters exceeds')
//     .required('Required field')
//     .nullable(),
//   homeNumber: Yup.string()
//     .max(100, 'Maximum characters exceeds')
//     .required('Required field')
//     .nullable(),
// });

// export const PARTICIPANT_EMPLOYMENT_DETAILS_VALIDATION = Yup.object().shape({
//   currentEmployment: Yup.object().shape({
//     employeeId: Yup.string()
//       .max(100, 'Maximum characters exceeds')
//       .required('Required field')
//       .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
//       .nullable(),
//     employerName: Yup.string()
//       .max(100, 'Maximum characters exceeds')
//       .required('Required field')
//       .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
//       .nullable(),
//     employmentCountryId: Yup.string().nullable(),
//     employmentStateId: Yup.string().nullable(),
//     employmentCityId: Yup.string().nullable(),
//     employmentState: Yup.string().nullable(),
//     employmentCity: Yup.string().nullable(),
//     jobTitle: Yup.string().nullable(),
//     workEmail: Yup.string().email().nullable(),
//     costCentre: Yup.string().nullable(),
//     hireDate: Yup.string().required('Required field').nullable(),
//     employmentAddressLine1: Yup.string().nullable(),
//     employmentAddressLine2: Yup.string().nullable(),
//   }),
//   previousEmployments: Yup.array().of(
//     Yup.object().shape({
//       jobTitle: Yup.string().required('Required field').nullable(),
//       hireDate: Yup.string().required('Required field').nullable(),
//       endDate: Yup.string().required('Required field').nullable(),
//     })
//   ),
// });

// export const PARTICIPANT_TAX_DETAILS_VALIDATION = {
//   fullLegalName: Yup.string()
//     .max(100, 'Maximum characters exceeds')
//     .required('Required field')
//     .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
//     .nullable(),
//   ssn: Yup.string()
//     .max(100, 'Maximum characters exceeds')
//     .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blank spaces')
//     .required('Required field')
//     .nullable(),
//   countryLegalResidencyId: Yup.string().required('Required field').nullable(),
//   governmentDocumentTypeId: Yup.string().required('Required field').nullable(),
//   governmentDocumentIssuedCountryId: Yup.string().required('Required field').nullable(),
//   governmentDocumentIdIssuedCityId: Yup.string().required('Required field').nullable(),
//   governmentDocumentIdIssuedStateId: Yup.string().required('Required field').nullable(),
//   governmentDocumentIdNo: Yup.string()
//     .max(100, 'Maximum characters exceeds')
//     .required('Required field')
//     .nullable(),
//   residenceStatusId: Yup.string().max(100, 'Maximum characters exceeds').nullable(),
//   governmentDocumentIdIssueDate: Yup.string()
//     .max(100, 'Maximum characters exceeds')
//     .required('Required field')
//     .nullable(),
//   governmentDocumentIdZipOrPostalCode: Yup.string()
//     .max(100, 'Maximum characters exceeds')
//     .required('Required field')
//     .nullable(),
// };

export const CHANGE_CONTACT_NUMBER_VALIDATION = Yup.object().shape({
  contactNumber: GlobalValidation.Phone.min(12, 'Must be 10 digits').when('callingCodeStatus', {
    is: false,
    otherwise: (d: any) => GlobalValidation.Phone,
  }),
  callingCodeId: commonValidation({ required: true }),
});

export const CHANGE_PASSWORD_VALIDATION = Yup.object().shape({
  currentPassword: commonValidation({ required: true }),
  newPassword: Yup.string().required('Required field').matches(passwordRegExp, 'Invalid Password'),
  repeatPassword: Yup.string()
    .required('Required field')
    .test('match', 'Password do not match', function (repeatPassword) {
      return repeatPassword === this.parent.newPassword;
    }),
});

export const ELECT_FORM_VALIDATION = Yup.object().shape({
  signature: commonValidation({ required: true, noBlank: true, doubleSpace: true }),
});

export const SECURITY_QUESTIONS_VALIDATION = Yup.object().shape({
  securityQuestion1: commonValidation({ required: true }).nullable(),
  securityQuestion2: commonValidation({ required: true }).nullable(),
  securityQuestion3: commonValidation({ required: true }).nullable(),
  securityAnswer1: GlobalValidation.RequiredNoBlank,
  securityAnswer2: GlobalValidation.RequiredNoBlank,
  securityAnswer3: GlobalValidation.RequiredNoBlank,
});

export const W9_FORM_VALIDATION = Yup.object().shape({
  name: GlobalValidation.Name.matches(/^[a-zA-Z-'' ]*$/, 'Invalid Name'),
  federalTax: commonValidation({ required: true }),
  signature: commonValidation({ required: true }),
  accountNumbers: commonValidation({ required: false, noBlank: true }),
  initial: commonValidation({ required: false, noBlank: true }),
  limitedLiabilityCompanyText: GlobalValidation.RequiredNoBlank.when('federalTax', {
    is: 'limitedLiabilityCompany',
    otherwise: (d: any) => Yup.string().nullable(),
  }),
  federalTaxClassificationOtherText: GlobalValidation.RequiredNoBlank.when('federalTax', {
    is: 'federalTaxClassificationOther',
    otherwise: (d: any) => Yup.string().nullable(),
  }),
  address: GlobalValidation.AddressLine1,
  cityStateZipCode: GlobalValidation.RequiredNoBlank,
  certificationDate: GlobalValidation.RequiredNoBlank,
  ssn: commonValidation({ required: true, digits: true })
    .min(9, 'Must be 9 digits')
    .max(9, 'Must be 9 digits'),
  accept: commonValidation({ required: true, isBoolean: true }),
});

export const W8_FORM_VALIDATION = {
  name: GlobalValidation.Name.matches(/^[a-zA-Z-'' ]*$/, 'Invalid Name'),
  signature: commonValidation({ required: true }),
  // name: commonValidation({ required: true, noBlank: true }),
  citizenshipCountryId: commonValidation({ required: true }),
  permanentAddress: commonValidation({ required: true, noBlank: true }),
  permanentCity: commonValidation({ required: true, noBlank: true }),
  permanentCountryId: commonValidation({ required: true }),
  hasDifferentMailingAddress: commonValidation({ isBoolean: true }),
  beneficialArticle: GlobalValidation.RequiredNoBlank,
  // beneficialArticle: commonValidation({ required: true, noBlank: true }),
  beneficialIncomeType: commonValidation({ required: true, noBlank: true }),
  beneficialAdditionalCondition: commonValidation({ required: true, noBlank: true }),
  printName: GlobalValidation.RequiredNoBlank,
  // printName: commonValidation({ required: true, noBlank: true }),
  dateOfBirth: commonValidation({ required: true }),
  ssnOrIdentificationNumber: commonValidation({ digits: true })
    .min(9, 'Must be 9 digits')
    .max(9, 'Must be 9 digits'),
  foreignTaxIdentifyingNumber: commonValidation({ required: true, digits: true }).when(
    'ftinLegallyRequired',
    {
      is: false,
      otherwise: (d: any) => commonValidation({ digits: true }).nullable(),
    }
  ),
  referenceNumber: commonValidation({ digits: true }),
  // ftinLegallyRequired: commonValidation({ required: true, isBoolean: true }),
  beneficialCountryId: commonValidation({ required: true }),
  beneficialPercentage: commonValidation({ required: true, digits: true }),
  capacitySignCertify: commonValidation({ required: true, isBoolean: true }),
  certificationDate: commonValidation({ required: true }),
  accept: commonValidation({ required: true, isBoolean: true }),
};

export const CASH_REQUEST_MODAL_VALIDATION = Yup.object().shape({
  transferDate: commonValidation({ required: true }),
  transferMode: commonValidation({ required: true }),
  remarks: commonValidation({ required: false, noBlank: true }),
  participantBankId: commonValidation({ required: true }),
  amount: commonValidation({ digits: true, required: true }),
  accountNumber: commonValidation({ digits: true, required: true }),
});

export const PRE_CLEARANCE_REQUEST_VALIDATION = Yup.object().shape({
  awardDetails: Yup.array().of(
    Yup.object().shape({
      transactionQty: commonValidation({ digits: true, required: false }).when(
        ['availableQty', 'isRSU'],
        (availableQty: number, isRSU: boolean, schema: any) => {
          if (!isRSU) {
            return schema.test(
              'match',
              `Must be less than or equal to ${availableQty}`,
              (value: any) => {
                return value != null
                  ? Number(value) === 0
                    ? false
                    : Number(value) <= Number(availableQty)
                  : true;
              }
            );
          }
        }
      ),
      isChecked: commonValidation({ required: false, isBoolean: true }),
      isSellAll: commonValidation({ isBoolean: true, required: false }),
      isSellToCover: commonValidation({ isBoolean: true }).when(
        ['isRSU', 'isPSU', 'isSellAll', 'isTransactionQty'],
        (
          isRSU: boolean,
          isPSU: boolean,
          isSellAll: boolean,
          isTransactionQty: boolean,
          schema: any,
          isSellCoverValue: any
        ) => {
          if (
            isTransactionQty &&
            !isRSU &&
            !isPSU &&
            !isSellAll &&
            isSellCoverValue.value === false
          ) {
            return commonValidation({ isBoolean: true }).test(
              'match',
              'Either field must have value',
              (value: any) => {
                if (typeof value === 'boolean') {
                  return value;
                }
                return false;
              }
            );
          }
          return schema;
        }
      ),
    })
  ),
  requestRemarks: commonValidation({ required: true }),
  isVerified: commonValidation({ isBoolean: true }).test(
    'match',
    'Required field',
    (value: any) => {
      if (typeof value === 'boolean') {
        return value;
      }
      return false;
    }
  ),
});
