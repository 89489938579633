/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_FinanceIntegrationService_Dtos_ParticipantBank_ParticipantBankListDto_Wealthlane_FinanceIntegrationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_FinanceIntegrationService_Dtos_ParticipantBank_ParticipantBankListDto_Wealthlane_FinanceIntegrationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_ResponseCreateLinkToken } from '../models/Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_ResponseCreateLinkToken';
import type { Wealthlane_FinanceIntegrationService_Dtos_ParticipantBank_GetDropdownParticipantBankListAsync } from '../models/Wealthlane_FinanceIntegrationService_Dtos_ParticipantBank_GetDropdownParticipantBankListAsync';
import type { Wealthlane_FinanceIntegrationService_Dtos_ParticipantBank_ParticipantBankForView } from '../models/Wealthlane_FinanceIntegrationService_Dtos_ParticipantBank_ParticipantBankForView';
import type { Wealthlane_FinanceIntegrationService_Dtos_ParticipantBank_ParticipantBankListDto } from '../models/Wealthlane_FinanceIntegrationService_Dtos_ParticipantBank_ParticipantBankListDto';
import type { Wealthlane_FinanceIntegrationService_Dtos_ParticipantBank_SavePublicTokenMetaDataForParticipantDto } from '../models/Wealthlane_FinanceIntegrationService_Dtos_ParticipantBank_SavePublicTokenMetaDataForParticipantDto';
import type { Wealthlane_FinanceIntegrationService_Dtos_Response_ResponseDto } from '../models/Wealthlane_FinanceIntegrationService_Dtos_Response_ResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ParticipantBankService {

    /**
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_FinanceIntegrationService_Dtos_ParticipantBank_ParticipantBankListDto_Wealthlane_FinanceIntegrationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiFinanceIntegrationApiAppParticipantBankParticipantBankList(
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_FinanceIntegrationService_Dtos_ParticipantBank_ParticipantBankListDto_Wealthlane_FinanceIntegrationService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/finance-integration/api/app/participant-bank/participant-bank-list',
            query: {
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_FinanceIntegrationService_Dtos_ParticipantBank_ParticipantBankListDto Success
     * @throws ApiError
     */
    public static getApiFinanceIntegrationApiAppParticipantBankParticipantBankAllList(): CancelablePromise<Array<Wealthlane_FinanceIntegrationService_Dtos_ParticipantBank_ParticipantBankListDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/finance-integration/api/app/participant-bank/participant-bank-all-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_FinanceIntegrationService_Dtos_ParticipantBank_GetDropdownParticipantBankListAsync Success
     * @throws ApiError
     */
    public static getApiFinanceIntegrationApiAppParticipantBankDropdownParticipantBankList(): CancelablePromise<Array<Wealthlane_FinanceIntegrationService_Dtos_ParticipantBank_GetDropdownParticipantBankListAsync>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/finance-integration/api/app/participant-bank/dropdown-participant-bank-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_ResponseCreateLinkToken Success
     * @throws ApiError
     */
    public static getApiFinanceIntegrationApiAppParticipantBankLinkToken(): CancelablePromise<Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_ResponseCreateLinkToken> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/finance-integration/api/app/participant-bank/link-token',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param participantBankId 
     * @returns Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_ResponseCreateLinkToken Success
     * @throws ApiError
     */
    public static getApiFinanceIntegrationApiAppParticipantBankLinkTokenForManualBankAccountVerification(
participantBankId: string,
): CancelablePromise<Wealthlane_FinanceIntegrationService_Dtos_CompanyBank_ResponseCreateLinkToken> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/finance-integration/api/app/participant-bank/link-token-for-manual-bank-account-verification/{participantBankId}',
            path: {
                'participantBankId': participantBankId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param participantBankId 
     * @returns Wealthlane_FinanceIntegrationService_Dtos_ParticipantBank_ParticipantBankForView Success
     * @throws ApiError
     */
    public static getApiFinanceIntegrationApiAppParticipantBankParticipantBankForView(
participantBankId: string,
): CancelablePromise<Wealthlane_FinanceIntegrationService_Dtos_ParticipantBank_ParticipantBankForView> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/finance-integration/api/app/participant-bank/participant-bank-for-view/{participantBankId}',
            path: {
                'participantBankId': participantBankId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_FinanceIntegrationService_Dtos_ParticipantBank_SavePublicTokenMetaDataForParticipantDto Success
     * @throws ApiError
     */
    public static postApiFinanceIntegrationApiAppParticipantBankSavePublicTokenMetaData(
requestBody?: Wealthlane_FinanceIntegrationService_Dtos_ParticipantBank_SavePublicTokenMetaDataForParticipantDto,
): CancelablePromise<Wealthlane_FinanceIntegrationService_Dtos_ParticipantBank_SavePublicTokenMetaDataForParticipantDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/finance-integration/api/app/participant-bank/save-public-token-meta-data',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_FinanceIntegrationService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiFinanceIntegrationApiAppParticipantBankVerifyManualBankAccount(
requestBody?: Wealthlane_FinanceIntegrationService_Dtos_ParticipantBank_SavePublicTokenMetaDataForParticipantDto,
): CancelablePromise<Wealthlane_FinanceIntegrationService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/finance-integration/api/app/participant-bank/verify-manual-bank-account',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param participantBankId 
     * @returns Wealthlane_FinanceIntegrationService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static deleteApiFinanceIntegrationApiAppParticipantBankParticipantBankAccount(
participantBankId: string,
): CancelablePromise<Wealthlane_FinanceIntegrationService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/finance-integration/api/app/participant-bank/participant-bank-account/{participantBankId}',
            path: {
                'participantBankId': participantBankId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
