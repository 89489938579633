import { Field } from 'formik';
import React, { type ReactNode } from 'react';
import { Label } from '../input-text/input.styled';
import { RadioButtonWrapper } from './radioButtonStyled';

export interface RadioButtonProps {
  label?: string | ReactNode;
  name: string;
  className?: string;
  errors?: any;
  touched?: any;
  labelClassName?: string;
  required?: boolean;
  value?: any;
  id?: string;
  arrValue?: Record<string, unknown>;
  disabled?: boolean;
  checked?: boolean;
  validation?: boolean;
  errorValue?: any;
  touchedValue?: any;
  onChange?: any;
}

const CustomerRadio = React.memo((props: RadioButtonProps) => {
  const {
    label,
    name,
    className,
    errors,
    touched,
    labelClassName,
    required,
    value,
    id,
    disabled,
    arrValue,
    checked,
    ...rest
  } = props;

  return (
    <RadioButtonWrapper label={label} className={className}>
      <Field
        name={name}
        id={id}
        label={label}
        type='radio'
        className={
          Boolean(errors) && Boolean(touched) && Boolean(errors[name]) && Boolean(touched[name])
            ? 'is-invalid'
            : ''
        }
        disabled={disabled}
        checked={checked ?? arrValue}
        {...(Boolean(value) && { value })}
        {...rest}
      />
      {label != null && (
        <Label htmlFor={id} className={labelClassName}>
          {label} {(required ?? false) && <span className='required'>*</span>}
        </Label>
      )}
    </RadioButtonWrapper>
  );
});

export default CustomerRadio;
