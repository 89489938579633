import { type PropsWithChildren } from 'react';
import { motion } from 'framer-motion';

interface ActionAnimationType {
  isOpen: boolean;
  className?: string;
}

const SlideLeftAnimation = ({
  isOpen,
  className,
  children,
}: PropsWithChildren<ActionAnimationType>): JSX.Element => {
  const slideLeft = {
    hidden: {
      x: 0,
      opacity: 1,
    },
    visible: {
      x: -350,
      opacity: 0,
      display: 'none',
      transition: {
        x: {
          duration: 0.8,
        },
        opacity: {
          duration: 0.8,
          delay: 0.1,
        },
        display: {
          duration: 0.2,
          delay: 0.8,
        },
      },
    },
  };

  return (
    <motion.div
      variants={slideLeft}
      initial='hidden'
      animate={isOpen && 'visible'}
      className={className}>
      {children}
    </motion.div>
  );
};

export default SlideLeftAnimation;
