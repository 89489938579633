/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_AwardService_Dtos_LongShareService_LongSharesSellRequestListResponseDto } from '../models/Wealthlane_AwardService_Dtos_LongShareService_LongSharesSellRequestListResponseDto';
import type { Wealthlane_AwardService_Dtos_LongShareService_ParticipantLongSharesListDto } from '../models/Wealthlane_AwardService_Dtos_LongShareService_ParticipantLongSharesListDto';
import type { Wealthlane_AwardService_Dtos_LongShareService_PostSellLongSharesDto } from '../models/Wealthlane_AwardService_Dtos_LongShareService_PostSellLongSharesDto';
import type { Wealthlane_AwardService_Dtos_Response_ResponseDto } from '../models/Wealthlane_AwardService_Dtos_Response_ResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LongShareService {

    /**
     * @param awardType 
     * @returns Wealthlane_AwardService_Dtos_LongShareService_ParticipantLongSharesListDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppLongShareParticipantLongSharesList(
awardType?: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_LongShareService_ParticipantLongSharesListDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/long-share/participant-long-shares-list',
            query: {
                'awardType': awardType,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppLongShareSellLongShares(
requestBody?: Wealthlane_AwardService_Dtos_LongShareService_PostSellLongSharesDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/long-share/sell-long-shares',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param openlotId 
     * @returns Wealthlane_AwardService_Dtos_LongShareService_LongSharesSellRequestListResponseDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppLongShareLongSharesSellRequestList(
openlotId?: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_LongShareService_LongSharesSellRequestListResponseDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/long-share/long-shares-sell-request-list',
            query: {
                'OpenlotId': openlotId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param closeLotId 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppLongShareCancelLongSharesSellRequest(
closeLotId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/long-share/cancel-long-shares-sell-request/{closeLotId}',
            path: {
                'closeLotId': closeLotId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
