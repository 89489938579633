import { type UseQueryResult, useQuery } from 'react-query';
import {
  TaxCertificationService,
  // eslint-disable-next-line camelcase
  type Wealthlane_CustomerService_Dtos_TaxCertificationFormDTO_TaxCetificationViewDTO,
} from '../../services/wealthlane-customer-services';

const useGetW8Form = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string,
  isTaxCertified?: boolean | null
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_CustomerService_Dtos_TaxCertificationFormDTO_TaxCetificationViewDTO | undefined,
  unknown
> => {
  const w8Form = useQuery(
    ['getW8Form', id, isTaxCertified],
    async () => {
      if (
        isTaxCertified !== null &&
        isTaxCertified !== undefined &&
        isTaxCertified &&
        id !== null &&
        id !== undefined &&
        id !== ''
      ) {
        return await handleRequest(
          TaxCertificationService.getApiCustomerApiAppTaxCertificationTaxCertificationForm(id)
        );
      }
    },
    {
      enabled: !!(
        isTaxCertified !== null &&
        isTaxCertified !== undefined &&
        isTaxCertified &&
        id !== null &&
        id !== undefined &&
        id !== ''
      ),
      cacheTime: 1000 * 60 * 5,
    }
  );

  return w8Form;
};

export default useGetW8Form;
