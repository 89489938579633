/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_ParticipantBroadcastMessage_ParticipantBroadcastMessageDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_ParticipantBroadcastMessage_ParticipantBroadcastMessageDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_CustomerService_Dtos_ParticipantBroadcastMessage_CreateParticipantBroadcastMessageDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantBroadcastMessage_CreateParticipantBroadcastMessageDto';
import type { Wealthlane_CustomerService_Dtos_ParticipantBroadcastMessage_CreateParticipantBroadcastMessageTemplateDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantBroadcastMessage_CreateParticipantBroadcastMessageTemplateDto';
import type { Wealthlane_CustomerService_Dtos_ParticipantBroadcastMessage_ParticipantBroadcastMessageDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantBroadcastMessage_ParticipantBroadcastMessageDto';
import type { Wealthlane_CustomerService_Dtos_ParticipantBroadcastMessage_ParticipantBroadcastMessageTemplateDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantBroadcastMessage_ParticipantBroadcastMessageTemplateDto';
import type { Wealthlane_CustomerService_Dtos_ParticipantBroadcastMessage_UpdateParticipantBroadcastMessageDto } from '../models/Wealthlane_CustomerService_Dtos_ParticipantBroadcastMessage_UpdateParticipantBroadcastMessageDto';
import type { Wealthlane_CustomerService_Dtos_Response_ResponseDto } from '../models/Wealthlane_CustomerService_Dtos_Response_ResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ParticipantBroadcastMessageService {

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppParticipantBroadcastMessageParticipantBroadcastMessage(
requestBody?: Wealthlane_CustomerService_Dtos_ParticipantBroadcastMessage_CreateParticipantBroadcastMessageDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/participant-broadcast-message/participant-broadcast-message',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static putApiCustomerApiAppParticipantBroadcastMessageParticipantBroadcastMessage(
requestBody?: Wealthlane_CustomerService_Dtos_ParticipantBroadcastMessage_UpdateParticipantBroadcastMessageDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/customer/api/app/participant-broadcast-message/participant-broadcast-message',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_CustomerService_Dtos_ParticipantBroadcastMessage_ParticipantBroadcastMessageDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppParticipantBroadcastMessageParticipantBroadcastMessageById(
id: string,
): CancelablePromise<Wealthlane_CustomerService_Dtos_ParticipantBroadcastMessage_ParticipantBroadcastMessageDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/participant-broadcast-message/{id}/participant-broadcast-message-by-id',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_ParticipantBroadcastMessage_ParticipantBroadcastMessageDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppParticipantBroadcastMessageParticipantBroadcastMessageList(
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_CustomerService_Dtos_ParticipantBroadcastMessage_ParticipantBroadcastMessageDto_Wealthlane_CustomerService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/participant-broadcast-message/participant-broadcast-message-list',
            query: {
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_CustomerService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static deleteApiCustomerApiAppParticipantBroadcastMessageParticipantBroadcastMessage(
id: string,
): CancelablePromise<Wealthlane_CustomerService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/customer/api/app/participant-broadcast-message/{id}/participant-broadcast-message',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_CustomerService_Dtos_ParticipantBroadcastMessage_ParticipantBroadcastMessageTemplateDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppParticipantBroadcastMessageParticipantBroadcastMessageTemplates(): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_ParticipantBroadcastMessage_ParticipantBroadcastMessageTemplateDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/participant-broadcast-message/participant-broadcast-message-templates',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_CustomerService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppParticipantBroadcastMessageParticipantBroadcastMessageTemplate(
requestBody?: Wealthlane_CustomerService_Dtos_ParticipantBroadcastMessage_CreateParticipantBroadcastMessageTemplateDto,
): CancelablePromise<Wealthlane_CustomerService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/participant-broadcast-message/participant-broadcast-message-template',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_CustomerService_Dtos_ParticipantBroadcastMessage_ParticipantBroadcastMessageDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppParticipantBroadcastMessageParticipantBroadcastMessages(): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_ParticipantBroadcastMessage_ParticipantBroadcastMessageDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/participant-broadcast-message/participant-broadcast-messages',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
