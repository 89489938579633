import { type UseQueryResult, useQuery } from 'react-query';
import {
  ParticipantService,
  // eslint-disable-next-line camelcase
  type Wealthlane_CustomerService_Dtos_Participant_ParticipantSecurityQuestionsAnswersDto,
} from '../../services/wealthlane-customer-services';

const useGetParticipantsSecurityQuestionAnswer = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_CustomerService_Dtos_Participant_ParticipantSecurityQuestionsAnswersDto | undefined,
  unknown
> => {
  const ParticipantsSecurityQuestionAnswer = useQuery(
    ['getParticipantsSecurityQuestionAnswer'],
    async () => {
      return await handleRequest(
        ParticipantService.getApiCustomerApiAppParticipantParticipantSecurityQuestionsAnswers()
      );
    }
  );

  return ParticipantsSecurityQuestionAnswer;
};

export default useGetParticipantsSecurityQuestionAnswer;
