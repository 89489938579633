import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import ToastComponent from '../../components/toast';
import {
  ParticipantCashTransferService,
  // eslint-disable-next-line camelcase
  type Wealthlane_FinanceIntegrationService_Dtos_ParticipantCashTransfer_RequestParticipantCashTransferDto,
} from '../../services/wealthlane-finance-integration-services';

const usePostRequestParticipantCashTransfer = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  closeModal: () => void
): UseMutationResult<
  boolean | undefined,
  unknown,
  // eslint-disable-next-line camelcase
  Wealthlane_FinanceIntegrationService_Dtos_ParticipantCashTransfer_RequestParticipantCashTransferDto,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (
      // eslint-disable-next-line camelcase
      payload: Wealthlane_FinanceIntegrationService_Dtos_ParticipantCashTransfer_RequestParticipantCashTransferDto
    ) => {
      return await handleRequest(
        ParticipantCashTransferService.postApiFinanceIntegrationApiAppParticipantCashTransferRequestParticipantCashTransfer(
          payload
        )
      );
    },
    {
      onSuccess: async (response: boolean | undefined) => {
        if (response != null) {
          await queryClient.invalidateQueries('getParticipantCashTransferList');
          toast.success(
            <ToastComponent label='Success' message='Participant Cash Transfer Requested.' />
          );
          closeModal();
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostRequestParticipantCashTransfer;
