// import { useMutation, useQueryClient } from 'react-query';
import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import ToastComponent from '../../components/toast';
import {
  ParticipantLogInAndSecurityService,
  // eslint-disable-next-line camelcase
  type Wealthlane_Shared_Hosting_Dtos_ParticipantLogInSecurity_ParticipantLogInSecurityResponseDto,
} from '../../services/wealthlane-customer-services';

const usePostChangeContactNumber = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  closeModal: () => void
): UseMutationResult<
  // eslint-disable-next-line camelcase
  | Wealthlane_Shared_Hosting_Dtos_ParticipantLogInSecurity_ParticipantLogInSecurityResponseDto
  | undefined,
  unknown,
  void,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload) => {
      return await handleRequest(
        ParticipantLogInAndSecurityService.postApiCustomerApiAppParticipantLogInAndSecurityChangeParticipantContactNumber(
          payload as any
        )
      );
    },

    {
      onSuccess: async () => {
        // if (response) {
        toast.success(<ToastComponent label='Success' message='Contact number updated.' />);
        await queryClient.invalidateQueries('getParticipantAndLogin');
        closeModal();
        // }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostChangeContactNumber;
