import { getViewHeight, rem } from '../../../utils/style';
import styled from 'styled-components';
import { COLORS } from '../../../constants/style/color';

export const ContentContainer = styled.div`
  background: ${COLORS.White};
  border: 1px solid ${COLORS.CulturedWhite};
  border-radius: 6px;
  margin-bottom: ${rem(24)};
`;

export const ContentHeader = styled.div`
  padding: 32px;
  gap: ${rem(18)};
  border-bottom: 1px solid ${COLORS.CulturedWhite};
  .title {
    color: ${COLORS.Silver};
  }
`;
export const ContentBody = styled.div`
  padding: 0px;

  @media (min-width: 992px) {
    padding: 36px;
  }
`;
export const PasswordDetailsContainer = styled.div`
  padding: ${rem(16)};
  background-color: ${COLORS.OffWhite};
  border: ${rem(1)} solid ${COLORS.LightGray};
  border-radius: ${rem(6)};

  .icon {
    color: ${COLORS.Red};
  }

  .icon-success {
    color: ${COLORS.Green};
  }
`;

export const SignUpConfirmation = styled.div`
  width: ${rem(320)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  height: ${getViewHeight('104px')};
  img {
    margin-bottom: 24px;
  }
  h3 {
    margin-bottom: 32px;
  }
`;
