import { type UseMutationResult, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { LongShareService } from '../../services/wealthlane-award-services';
import { useNavigate } from 'react-router-dom';
import ToastComponent from '../../components/toast';

const usePostLongShareSellShare = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseMutationResult<
  // eslint-disable-next-line camelcase
  any | undefined,
  unknown,
  void,
  unknown
> => {
  const navigate = useNavigate();

  return useMutation(
    async (payload: any) => {
      return await handleRequest(
        LongShareService.postApiAwardApiAppLongShareSellLongShares(payload)
      );
    },
    {
      // eslint-disable-next-line camelcase
      onSuccess: (response: any) => {
        if (response != null) {
          toast.success(<ToastComponent label='Success' message={`Sell order placed.`} />);
          navigate('/holdings');
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostLongShareSellShare;
