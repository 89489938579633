import { COLORS } from './constants/style/color';
import { createGlobalStyle } from 'styled-components/macro';
import { DEFAULT_FONT_SIZE } from './constants/common';
import { rem, getViewHeight } from './utils/style';

export default createGlobalStyle`
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'HK Grotesk';
  font-weight: 400;
}

html {
  font-size: ${DEFAULT_FONT_SIZE}px; /* 10px or 62.5% */
    -webkit-text-size-adjust: 100%;
    text-rendering:optimizeLegibility;
    margin-right: calc(-1 * (100vw - 100%));
}

body {
    color: ${COLORS.Black};
    background-color: ${COLORS.White};
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition:padding-top .2s linear;
}

.h-normal-screen {
    height: ${getViewHeight('67px')};
  }

  .h-normal-notify-screen {
    height: ${getViewHeight('101px')};
  }

.material-symbols-outlined, .material-symbols-rounded, .material-symbols-sharp {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}

.material-symbols-outlined-filled, .material-symbols-rounded-filled, .material-symbols-sharp-filled {
  font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}

.toast-container {
  background: ${COLORS.White} ;
  border-radius: ${rem(15)};
  color: ${COLORS.Gray30};
border-radius: 15px;

box-shadow: inset -4px -1px 5px rgba(232, 232, 232, 0.23);
padding:14px 18px;
display:flex ;
align-items:center ;
font-size:12px;
 top: 65px;
} 

.Toastify__toast--success{
filter: drop-shadow(0px -1px 7px rgba(5, 185, 55, 0.2));
}
.Toastify__toast--error{
  filter: drop-shadow(0px -1px 7px rgba(221, 37, 37, 0.2));
}
.Toastify__toast--info{
  filter: drop-shadow(0px -1px 7px rgba(28, 133, 231, 0.2));
}
.Toastify__close-button{

}
.Toastify__toast-body{
  margin:0;
  padding:0;
}

.form-gap {
  margin-bottom: ${rem(14)};
}
fieldset{
  margin-bottom:14px;
}
.formGroup{
  margin-bottom:18px;
}

/* animation sidebar */

.sideBar{

  z-index:999999 ;
  opacity:0 ;
  animation: 500ms fadeOut ease-in-out;
  &-entered{
    animation: 500ms fadeIn ease-in-out;
    display:block;
    opacity:1 ;
    height: 100%;
  } 
  &-entering{
  }
  &-exited {
    animation: 500ms fadeOut ease-in-out;
    opacity:0 ;
  }
}
.modalBackdrop{
  background: rgba(0, 0, 0, 0);
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  transition: all 400ms linear;
  z-index: -10000;
  opacity:0 ;
  visibility:hidden ;
  &-entered{
    background: rgba(22, 22, 22, 0.85);
    z-index: 99999 ;
    opacity:1 ;
    visibility:visible  } 
  &-exited{
    background-color: rgba(0, 0, 0, 0);
    z-index: -10000 ;
    opacity:0 ;
    visibility:hidden  }
}

@keyframes fadeIn {
	0% {
		opacity: 0;
		transform: translateX(20rem);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		transform: translateX(20rem);
	}
}

/* main layout */
.mainLayout{
  padding:12px;
  height: ${getViewHeight()} ;
  background-size:cover ;
  .mainLayoutGrid{
    display:flex;
    gap:12px;
  }
  .bgIllustration{
    position: relative;
    height: ${getViewHeight('24px')};
    width:700px;
    .logo{
      padding:22px;
    }
    .tagline{
      position:absolute ;
      bottom:126px;
      left:43px;
      width:400px ;
    }
    .copyright{
       position:absolute ;
       left:15px;
       bottom:15px;
    }
  }
  .login {
    flex-grow:1;
    .loginForm {
      width:388px;
      margin:0px auto 0 120px;
      height: ${getViewHeight('102px')};
      display:flex;
      flex-direction:column;
      justify-content:center ;
      align-items:center ;

      .btnLink{
        color:rgba(56, 77, 214, 0.8);
        font-weight:400 ;
      }
      h1{
        margin-bottom:32px;
      }
      .error{
        margin-bottom:18px;
        span{
          border-left:3px solid ${COLORS.Red} ;
          padding-left:5px;
          line-height: 12px;
        }
      }
      .dot{
        width:4px;
        height:4px;
        border-radius:4px;
        background:#969696;
        margin:0 14px;
      }
    }
   
  }
}


.needHelp{
      width:100%;
      img{
        margin-right:12px;
      }
      padding:18px 24px;
      background: linear-gradient(90.44deg, rgba(255, 255, 255, 0.65) 39.11%, #FFFFFF 96.9%);
border-radius: 10px;
display:flex ;
align-items:center
    }
 .error-message,
  .field-error-message {
    font-weight: 400;
    font-size: ${rem(12)};
    line-height: ${rem(14)};
    color: ${COLORS.Gray};
    margin-top: ${rem(6)};
    transition: all 0.2s linear;
  }

  @media print {
    .myDivToPrint {
        background-color: white;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
        padding: 15px;
        font-size: 14px;
        line-height: 18px;
    }
}

.methodTable{
  background:#f3f3f380;
  border-radius: 6px;
  padding:17px 20px 10px 24px;
  
  &:not(:last-child){
    margin-bottom:12px;

  }
  &BoxTitle{
    margin-bottom:18px;
  }
  &Grid{
    grid-column-gap:12.4rem ;
  }
  &Tag{
    margin-left: ${rem(5)} ;
  }
  table{
    width:100%;
    thead{
      tr{
        td{
          padding-bottom:15px;
        }
      }
    }
    
  }
  tbody{
    tr{
      &:first-child{
        td{

          padding-top:0;
        }
      }
      &:not(:last-child){

        border-bottom: 1px solid #D7D7D7;
      }
      
      td{
        padding:14px 0;
        span{
          color:#595959;
        }
        &:last-child{
          span{
            justify-content:flex-end ;
          }
        }
      }
      &.bold{
         td{
            span{
              font-weight:600;
              color:${COLORS.Black}
            }
          }
      }
    }
  }
}


.MyCustomTransition-appear-active,
.MyCustomTransition-enter-active,
.MyCustomTransition-enter-done {
  transition: all 400ms ease-in-out;
}

.MyCustomTransition-appear,
.MyCustomTransition-enter,
.MyCustomTransition-exit-active,
.MyCustomTransition-exit-done {
  transform: translateY(-34px);
  transition: all 400ms ease-in-out;
}
.descriptionOutput{
  p>*{
    background: initial!important;
    font-size: inherit!important;
  }
}

.delete-label{
  color: ${COLORS.Red};
}
.screen-center{
  min-height: 90rem;
  display: flex;
  justify-content: center;
}

.noTermAndConditions{
    border: 1px solid gray;
    padding: 20px;
    display: flex;
    justify-content: center;
}
.cellWidth{
  width: 100px;
}

.testDrop{
  z-index:9999;
}

.link-active{
      &:after {
        content: '';
        height: 3px;
        width: 100%;
        background: ${COLORS.Purple};
        position: absolute;
        left: 0;
        bottom: 0;
        transition: all 0.2s linear;
      }
}
.link-text{
  font-weight: 400;
    font-size: 1.6rem;
  
    


     
}
`;
