import { type UseQueryResult, useQuery } from 'react-query';
import {
  UserDefinedFieldsService,
  // eslint-disable-next-line camelcase
  type Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsByModuleIdDto,
} from '../../services/wealthlane-customer-services';

const useGetUDFByModuleId = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  moduleId: number
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_CustomerService_Dtos_UserDefinedFields_UserDefinedFieldsByModuleIdDto[] | undefined,
  unknown
> => {
  const userDefinedFieldsByModuleId = useQuery(['getUserDefinedFieldsByModuleId'], async () => {
    return await handleRequest(
      UserDefinedFieldsService.getApiCustomerApiAppUserDefinedFieldsUserDefinedFieldsByModuleId(
        moduleId
      )
    );
  });

  return userDefinedFieldsByModuleId;
};

export default useGetUDFByModuleId;
