import { type UseQueryResult, useQuery } from 'react-query';
import {
  GrantService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_Grant_GrantTermsAndConditionsDto,
} from '../../services/wealthlane-award-services';

const useGetGrantTermsAndConditions = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  grantId: string
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_Grant_GrantTermsAndConditionsDto | undefined,
  unknown
> => {
  const grantTermsAndConditions = useQuery(['getGrantTermsAndConditions', grantId], async () => {
    if (grantId !== '' && grantId != null) {
      return await handleRequest(
        GrantService.getApiAwardApiAppGrantActiveTermsAndConditions(grantId)
      );
    }
  });

  return grantTermsAndConditions;
};

export default useGetGrantTermsAndConditions;
