import { type UseQueryResult, useQuery } from 'react-query';
import {
  ParticipantLogInAndSecurityService,
  // eslint-disable-next-line camelcase
  type Wealthlane_Dtos_LoginSecurityOptions_LoginSecurityOptionsForLoggedInUserDto,
} from '../../services/wealthlane-identity-services';

const useGetSetOption = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_Dtos_LoginSecurityOptions_LoginSecurityOptionsForLoggedInUserDto[] | undefined,
  unknown
> => {
  const getSetOption = useQuery(['getSetOption'], async () => {
    return await handleRequest(
      ParticipantLogInAndSecurityService.getApiIdentityApiAppParticipantLogInAndSecurityLoginSecurityOptions()
    );
  });

  return getSetOption;
};

export default useGetSetOption;
