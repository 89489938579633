/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_TradePreClearance_TradePreClearanceRequestListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_TradePreClearance_TradePreClearanceRequestListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_AwardService_Dtos_Response_ResponseDto } from '../models/Wealthlane_AwardService_Dtos_Response_ResponseDto';
import type { Wealthlane_AwardService_Dtos_TradePreClearance_PreClearancedAwardDetailDto } from '../models/Wealthlane_AwardService_Dtos_TradePreClearance_PreClearancedAwardDetailDto';
import type { Wealthlane_AwardService_Dtos_TradePreClearance_PreClearanceRequestStatusDto } from '../models/Wealthlane_AwardService_Dtos_TradePreClearance_PreClearanceRequestStatusDto';
import type { Wealthlane_AwardService_Dtos_TradePreClearance_TradePreClearanceParticipantDto } from '../models/Wealthlane_AwardService_Dtos_TradePreClearance_TradePreClearanceParticipantDto';
import type { Wealthlane_AwardService_Dtos_TradePreClearance_TradePreClearanceReqDto } from '../models/Wealthlane_AwardService_Dtos_TradePreClearance_TradePreClearanceReqDto';
import type { Wealthlane_AwardService_Dtos_TradePreClearance_TradePreClearanceRequestDto } from '../models/Wealthlane_AwardService_Dtos_TradePreClearance_TradePreClearanceRequestDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TradePreClearanceService {

    /**
     * @returns Wealthlane_AwardService_Dtos_TradePreClearance_PreClearancedAwardDetailDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppTradePreClearancePreClearancedAwardDetails(): CancelablePromise<Wealthlane_AwardService_Dtos_TradePreClearance_PreClearancedAwardDetailDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/trade-pre-clearance/pre-clearanced-award-details',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_TradePreClearance_TradePreClearanceParticipantDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppTradePreClearanceParticipantTradePreClearance(): CancelablePromise<Wealthlane_AwardService_Dtos_TradePreClearance_TradePreClearanceParticipantDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/trade-pre-clearance/participant-trade-pre-clearance',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppTradePreClearancePreClearanceRequest(
requestBody?: Wealthlane_AwardService_Dtos_TradePreClearance_TradePreClearanceRequestDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/trade-pre-clearance/pre-clearance-request',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param approvalStatusIds 
     * @param awardTypeId 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_TradePreClearance_TradePreClearanceRequestListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAwardApiAppTradePreClearancePreClearanceRequestList(
approvalStatusIds?: Array<number>,
awardTypeId?: string,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_TradePreClearance_TradePreClearanceRequestListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/trade-pre-clearance/pre-clearance-request-list',
            query: {
                'ApprovalStatusIds': approvalStatusIds,
                'AwardTypeId': awardTypeId,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_AwardService_Dtos_TradePreClearance_TradePreClearanceReqDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppTradePreClearancePreClearanceRequest(
id: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_TradePreClearance_TradePreClearanceReqDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/trade-pre-clearance/{id}/pre-clearance-request',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_TradePreClearance_PreClearanceRequestStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppTradePreClearanceApprovalStatus(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_TradePreClearance_PreClearanceRequestStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/trade-pre-clearance/approval-status',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
