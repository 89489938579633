/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_PSUAward_AdminPsuDashboardGrantList_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_PSUAward_AdminPsuDashboardGrantList_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_PSUAward_PsuAwardListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_PSUAward_PsuAwardListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_UpcomingVestingGrantsDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_UpcomingVestingGrantsDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto } from '../models/Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto';
import type { Wealthlane_AwardService_Dtos_PerformanceRule_PerformanceRuleDetailForAdminViewDto } from '../models/Wealthlane_AwardService_Dtos_PerformanceRule_PerformanceRuleDetailForAdminViewDto';
import type { Wealthlane_AwardService_Dtos_PSUAward_ExportPSUAwardForUpdateRequestDto } from '../models/Wealthlane_AwardService_Dtos_PSUAward_ExportPSUAwardForUpdateRequestDto';
import type { Wealthlane_AwardService_Dtos_PSUAward_GetPsuGrantDetailsDto } from '../models/Wealthlane_AwardService_Dtos_PSUAward_GetPsuGrantDetailsDto';
import type { Wealthlane_AwardService_Dtos_PSUAward_PSUAwardBlobDto } from '../models/Wealthlane_AwardService_Dtos_PSUAward_PSUAwardBlobDto';
import type { Wealthlane_AwardService_Dtos_PSUAward_PSUAwardBlobRequestDto } from '../models/Wealthlane_AwardService_Dtos_PSUAward_PSUAwardBlobRequestDto';
import type { Wealthlane_AwardService_Dtos_PSUAward_PSUAwardImportDataDto } from '../models/Wealthlane_AwardService_Dtos_PSUAward_PSUAwardImportDataDto';
import type { Wealthlane_AwardService_Dtos_PSUAward_PSUAwardImportResponseDTO } from '../models/Wealthlane_AwardService_Dtos_PSUAward_PSUAwardImportResponseDTO';
import type { Wealthlane_AwardService_Dtos_PSUAward_PsuAwardNumberDto } from '../models/Wealthlane_AwardService_Dtos_PSUAward_PsuAwardNumberDto';
import type { Wealthlane_AwardService_Dtos_PSUAward_PSUAwardOverviewDto } from '../models/Wealthlane_AwardService_Dtos_PSUAward_PSUAwardOverviewDto';
import type { Wealthlane_AwardService_Dtos_PSUAward_PSUAwardSampleFileBlobDto } from '../models/Wealthlane_AwardService_Dtos_PSUAward_PSUAwardSampleFileBlobDto';
import type { Wealthlane_AwardService_Dtos_PSUAward_PsuAwardSettlementHistory } from '../models/Wealthlane_AwardService_Dtos_PSUAward_PsuAwardSettlementHistory';
import type { Wealthlane_AwardService_Dtos_PSUAward_PSUAwardUpdateImportDataDto } from '../models/Wealthlane_AwardService_Dtos_PSUAward_PSUAwardUpdateImportDataDto';
import type { Wealthlane_AwardService_Dtos_PSUAward_PSUAwardUpdateImportResponseDTO } from '../models/Wealthlane_AwardService_Dtos_PSUAward_PSUAwardUpdateImportResponseDTO';
import type { Wealthlane_AwardService_Dtos_PSUAward_PSUAwardVestingScheduleDto } from '../models/Wealthlane_AwardService_Dtos_PSUAward_PSUAwardVestingScheduleDto';
import type { Wealthlane_AwardService_Dtos_PSUAward_PSUDocumentDto } from '../models/Wealthlane_AwardService_Dtos_PSUAward_PSUDocumentDto';
import type { Wealthlane_AwardService_Dtos_Response_ResponseDto } from '../models/Wealthlane_AwardService_Dtos_Response_ResponseDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrants } from '../models/Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrants';
import type { Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantView } from '../models/Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantView';
import type { Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantViewRequestDto } from '../models/Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantViewRequestDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_AwardedParticipants } from '../models/Wealthlane_AwardService_Dtos_RSUAward_AwardedParticipants';
import type { Wealthlane_AwardService_Dtos_RSUAward_AwardTransactionDetails } from '../models/Wealthlane_AwardService_Dtos_RSUAward_AwardTransactionDetails';
import type { Wealthlane_AwardService_Dtos_RSUAward_AwardTransactionDetailsByTransactionIdDTO } from '../models/Wealthlane_AwardService_Dtos_RSUAward_AwardTransactionDetailsByTransactionIdDTO';
import type { Wealthlane_AwardService_Dtos_RSUAward_GetParticipantDto } from '../models/Wealthlane_AwardService_Dtos_RSUAward_GetParticipantDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO } from '../models/Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO';
import type { Wealthlane_AwardService_Dtos_RSUAward_GetValuesDTO } from '../models/Wealthlane_AwardService_Dtos_RSUAward_GetValuesDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PsuAwardService {

    /**
     * @param sessionId 
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPSUAwardStopBulkImport(
sessionId: string,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/p-sUAward/stop-bulk-import/{sessionId}',
            path: {
                'sessionId': sessionId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData 
     * @returns Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPSUAwardValidateBulkPSu(
formData?: {
File?: Blob;
},
): CancelablePromise<Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/p-sUAward/validate-bulk-pSU',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param validateSessionId 
     * @returns Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPSUAwardImportBulkPSu(
validateSessionId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/p-sUAward/import-bulk-pSU/{validateSessionId}',
            path: {
                'validateSessionId': validateSessionId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData 
     * @returns Wealthlane_AwardService_Dtos_PSUAward_PSUAwardImportResponseDTO Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPSUAwardImportPsuAwards(
formData?: {
File?: Blob;
},
): CancelablePromise<Wealthlane_AwardService_Dtos_PSUAward_PSUAwardImportResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/p-sUAward/import-psu-awards',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData 
     * @returns Wealthlane_AwardService_Dtos_PSUAward_PSUAwardUpdateImportResponseDTO Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPSUAwardImportPsuAwardsForUpdate(
formData?: {
File?: Blob;
},
): CancelablePromise<Wealthlane_AwardService_Dtos_PSUAward_PSUAwardUpdateImportResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/p-sUAward/import-psu-awards-for-update',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetValuesDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardGrants(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetValuesDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward/grants',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetValuesDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardPlanTypes(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetValuesDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward/plan-types',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetValuesDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardVestingSchedule(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetValuesDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward/vesting-schedule',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_PSUAward_PSUAwardImportDataDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPSUAwardPSUAwards(
requestBody?: Array<Wealthlane_AwardService_Dtos_PSUAward_PSUAwardImportDataDto>,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_PSUAward_PSUAwardImportDataDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/p-sUAward/p-sUAwards',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_PSUAward_PSUAwardUpdateImportDataDto Success
     * @throws ApiError
     */
    public static putApiAwardApiAppPSUAwardPSUAwardsInBulk(
requestBody?: Array<Wealthlane_AwardService_Dtos_PSUAward_PSUAwardUpdateImportDataDto>,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_PSUAward_PSUAwardUpdateImportDataDto>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/award/api/app/p-sUAward/p-sUAwards-in-bulk',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_PSUAward_PSUAwardImportDataDto Success
     * @throws ApiError
     */
    public static putApiAwardApiAppPSUAwardPSUAward(
requestBody?: Wealthlane_AwardService_Dtos_PSUAward_PSUAwardImportDataDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_PSUAward_PSUAwardImportDataDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/award/api/app/p-sUAward/p-sUAward',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param awardId 
     * @returns Wealthlane_AwardService_Dtos_PSUAward_PSUAwardImportDataDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardPSUAwardForUpdateById(
awardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_PSUAward_PSUAwardImportDataDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward/p-sUAward-for-update-by-id/{awardId}',
            path: {
                'awardId': awardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param approvalStatusIds 
     * @param fromDate 
     * @param toDate 
     * @param employeeStatuses 
     * @param psuStatus 
     * @param psuAcceptance 
     * @param createdFromDate 
     * @param createdToDate 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_PSUAward_PsuAwardListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardPSUAwardList(
approvalStatusIds?: Array<number>,
fromDate?: string,
toDate?: string,
employeeStatuses?: Array<number>,
psuStatus?: number,
psuAcceptance?: number,
createdFromDate?: string,
createdToDate?: string,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_PSUAward_PsuAwardListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward/p-sUAward-list',
            query: {
                'ApprovalStatusIds': approvalStatusIds,
                'FromDate': fromDate,
                'ToDate': toDate,
                'EmployeeStatuses': employeeStatuses,
                'PSUStatus': psuStatus,
                'PSUAcceptance': psuAcceptance,
                'CreatedFromDate': createdFromDate,
                'CreatedToDate': createdToDate,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_PSUAward_PSUAwardBlobDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPSUAwardGetExportPSuAward(
requestBody?: Wealthlane_AwardService_Dtos_PSUAward_ExportPSUAwardForUpdateRequestDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_PSUAward_PSUAwardBlobDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/p-sUAward/get-export-pSUAward',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param fromDate 
     * @param toDate 
     * @returns string Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardPSUFileNameList(
fromDate?: string,
toDate?: string,
): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward/p-sUFile-name-list',
            query: {
                'fromDate': fromDate,
                'toDate': toDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData 
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPSUAwardUploadPSuAwardSample(
formData?: {
document?: Blob;
},
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/p-sUAward/upload-pSUAward-sample',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_PSUAward_PSUAwardSampleFileBlobDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPSUAwardDownloadPSuAwardSample(): CancelablePromise<Wealthlane_AwardService_Dtos_PSUAward_PSUAwardSampleFileBlobDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/p-sUAward/download-pSUAward-sample',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardPSUAwardStatuses(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward/p-sUAward-statuses',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardEmployeeStatuses(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward/employee-statuses',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardPSUStatuses(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward/p-sUStatuses',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardPSUAcceptances(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetStatusDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward/p-sUAcceptances',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_GetParticipantDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardEmployeeIds(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_GetParticipantDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward/employee-ids',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_PSUAward_GetPsuGrantDetailsDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardGrantDetails(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_PSUAward_GetPsuGrantDetailsDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward/grant-details',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param psuAwardId 
     * @returns Wealthlane_AwardService_Dtos_PSUAward_PSUAwardOverviewDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardPSUAwardOverview(
psuAwardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_PSUAward_PSUAwardOverviewDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward/p-sUAward-overview/{PSUAwardId}',
            path: {
                'PSUAwardId': psuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param awardId 
     * @returns Wealthlane_AwardService_Dtos_PerformanceRule_PerformanceRuleDetailForAdminViewDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardPerformanceRuleForAdminDetail(
awardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_PerformanceRule_PerformanceRuleDetailForAdminViewDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward/performance-rule-for-admin-detail/{awardId}',
            path: {
                'awardId': awardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param psuAwardId 
     * @returns Wealthlane_AwardService_Dtos_PSUAward_PSUAwardVestingScheduleDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardPSUAwardVestingSchedule(
psuAwardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_PSUAward_PSUAwardVestingScheduleDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward/p-sUAward-vesting-schedule/{PSUAwardId}',
            path: {
                'PSUAwardId': psuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param psuAwardId 
     * @returns Wealthlane_AwardService_Dtos_PSUAward_PSUDocumentDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardPSUAwardDocuments(
psuAwardId: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_PSUAward_PSUDocumentDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward/p-sUAward-documents/{PSUAwardId}',
            path: {
                'PSUAwardId': psuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_PSUAward_PSUAwardBlobDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPSUAwardDownloadDocument(
requestBody?: Wealthlane_AwardService_Dtos_PSUAward_PSUAwardBlobRequestDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_PSUAward_PSUAwardBlobDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/p-sUAward/download-document',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param psuAwardId 
     * @returns string Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardPSUAwardStatusById(
psuAwardId: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward/p-sUAward-status-by-id/{PSUAwardId}',
            path: {
                'PSUAwardId': psuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param psuAwardId 
     * @returns string Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPSUAwardExportPSuAwardVestingSchedule(
psuAwardId: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/p-sUAward/export-pSUAward-vesting-schedule/{PSUAwardId}',
            path: {
                'PSUAwardId': psuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPSUAwardSendParticipantsSignUpLinks(
requestBody?: Array<string>,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/p-sUAward/send-participants-sign-up-links',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param psuAwardId 
     * @returns Wealthlane_AwardService_Dtos_PSUAward_PsuAwardSettlementHistory Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardPSUAwardSettlementHistory(
psuAwardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_PSUAward_PsuAwardSettlementHistory> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward/p-sUAward-settlement-history/{PSUAwardId}',
            path: {
                'PSUAwardId': psuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param psuAwardId 
     * @returns Wealthlane_AwardService_Dtos_PSUAward_PsuAwardNumberDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardPSUAwardNumbers(
psuAwardId: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_PSUAward_PsuAwardNumberDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward/p-sUAward-numbers/{PSUAwardId}',
            path: {
                'PSUAwardId': psuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_UpcomingVestingGrantsDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardUpcomingVestingGrants(
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_RSUAward_UpcomingVestingGrantsDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward/upcoming-vesting-grants',
            query: {
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param vestingDate 
     * @param grantId 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantView Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardAdminDashboardGrantView(
vestingDate?: string,
grantId?: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantView> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward/admin-dashboard-grant-view',
            query: {
                'VestingDate': vestingDate,
                'GrantId': grantId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param grantId 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantView Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardAdminDashboardGrantViewOld(
grantId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantView> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward/admin-dashboard-grant-view-old/{grantId}',
            path: {
                'grantId': grantId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_AwardedParticipants Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardAwardedParticipants(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_AwardedParticipants>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward/awarded-participants',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrants Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardUpcomingGrantList(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrants>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward/upcoming-grant-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param vestingDate 
     * @param grantId 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_PSUAward_AdminPsuDashboardGrantList_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardAdminDashboardGrantList(
vestingDate?: string,
grantId?: string,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_PSUAward_AdminPsuDashboardGrantList_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward/admin-dashboard-grant-list',
            query: {
                'VestingDate': vestingDate,
                'GrantId': grantId,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static deleteApiAwardApiAppPSUAwardPsuAwardById(
id: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/award/api/app/p-sUAward/psu-award-by-id/{Id}',
            path: {
                'Id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPSUAwardCancelPsuAwardById(
id: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/p-sUAward/cancel-psu-award-by-id/{Id}',
            path: {
                'Id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static postApiAwardApiAppPSUAwardExportAdminDashboardGrantList(
requestBody?: Wealthlane_AwardService_Dtos_RSUAward_AdminDashboardGrantViewRequestDto,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/p-sUAward/export-admin-dashboard-grant-list',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param grantId 
     * @param input 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_AwardTransactionDetails Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardTransactionForTax(
grantId: string,
input?: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_AwardTransactionDetails>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward/transaction-for-tax/{GrantId}',
            path: {
                'GrantId': grantId,
            },
            query: {
                'input': input,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param transactionId 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_AwardTransactionDetailsByTransactionIdDTO Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardTransactionDetailByTransactionId(
transactionId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_RSUAward_AwardTransactionDetailsByTransactionIdDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward/transaction-detail-by-transaction-id/{TransactionId}',
            path: {
                'TransactionId': transactionId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardPsuBulkImportStatus(
id: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_BulkImport_BulkImportResponseDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward/psu-bulk-import-status/{Id}',
            path: {
                'Id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param psuVestingDateId 
     * @returns Wealthlane_AwardService_Dtos_PSUAward_PSUAwardOverviewDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardPSUAwardDetailByPSuVestingDateId(
psuVestingDateId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_PSUAward_PSUAwardOverviewDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward/p-sUAward-detail-by-pSUVesting-date-id/{PSUVestingDateId}',
            path: {
                'PSUVestingDateId': psuVestingDateId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param psuVestingDateId 
     * @returns Wealthlane_AwardService_Dtos_PSUAward_PSUAwardOverviewDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppPSUAwardPSUAwardDetailByPSuVestingDateIdForParticipant(
psuVestingDateId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_PSUAward_PSUAwardOverviewDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/p-sUAward/p-sUAward-detail-by-pSUVesting-date-id-for-participant/{PSUVestingDateId}',
            path: {
                'PSUVestingDateId': psuVestingDateId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
