/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_AwardSettlementService_Dtos_PSUElect_PsuElectDto } from '../models/Wealthlane_AwardSettlementService_Dtos_PSUElect_PsuElectDto';
import type { Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto } from '../models/Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto';
import type { Wealthlane_AwardSettlementService_Dtos_RSUElect_ElectionHistoryDto } from '../models/Wealthlane_AwardSettlementService_Dtos_RSUElect_ElectionHistoryDto';
import type { Wealthlane_AwardSettlementService_Dtos_RSUElect_LongShareDto } from '../models/Wealthlane_AwardSettlementService_Dtos_RSUElect_LongShareDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PsuElectService {

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardSettlementApiAppPSUElectSaveElection(
requestBody?: Wealthlane_AwardSettlementService_Dtos_PSUElect_PsuElectDto,
): CancelablePromise<Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award-settlement/api/app/p-sUElect/save-election',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param psuAwardId 
     * @returns Wealthlane_AwardSettlementService_Dtos_RSUElect_ElectionHistoryDto Success
     * @throws ApiError
     */
    public static getApiAwardSettlementApiAppPSUElectElectionHistoryList(
psuAwardId: string,
): CancelablePromise<Array<Wealthlane_AwardSettlementService_Dtos_RSUElect_ElectionHistoryDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award-settlement/api/app/p-sUElect/election-history-list/{PsuAwardId}',
            path: {
                'PsuAwardId': psuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param psuAwardId 
     * @returns string Success
     * @throws ApiError
     */
    public static postApiAwardSettlementApiAppPSUElectExportElectionHistoryList(
psuAwardId: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award-settlement/api/app/p-sUElect/export-election-history-list/{PSUAwardId}',
            path: {
                'PSUAwardId': psuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param psuAwardId 
     * @returns Wealthlane_AwardSettlementService_Dtos_RSUElect_ElectionHistoryDto Success
     * @throws ApiError
     */
    public static getApiAwardSettlementApiAppPSUElectElectionHistoryByPSuAwardId(
psuAwardId: string,
): CancelablePromise<Array<Wealthlane_AwardSettlementService_Dtos_RSUElect_ElectionHistoryDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award-settlement/api/app/p-sUElect/election-history-by-pSUAward-id/{PsuAwardId}',
            path: {
                'PsuAwardId': psuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param psuAwardId 
     * @returns string Success
     * @throws ApiError
     */
    public static postApiAwardSettlementApiAppPSUElectExportElectionHistoryListByPSuAwardId(
psuAwardId: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award-settlement/api/app/p-sUElect/export-election-history-list-by-pSUAward-id/{PsuAwardId}',
            path: {
                'PsuAwardId': psuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardSettlementService_Dtos_RSUElect_LongShareDto Success
     * @throws ApiError
     */
    public static getApiAwardSettlementApiAppPSUElectLongSharesForParticipants(): CancelablePromise<Array<Wealthlane_AwardSettlementService_Dtos_RSUElect_LongShareDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award-settlement/api/app/p-sUElect/long-shares-for-participants',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
