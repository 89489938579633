import { type UseQueryResult, useQuery } from 'react-query';
import {
  ParticipantService,
  // eslint-disable-next-line camelcase
  type Wealthlane_CustomerService_Dtos_Participant_ViewParticipantEmploymentByParticipantDto,
} from '../../services/wealthlane-customer-services';

const useGetParticipantEmployeeDetails = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_CustomerService_Dtos_Participant_ViewParticipantEmploymentByParticipantDto | undefined,
  unknown
> => {
  const participantEmployeeDetails = useQuery(
    ['getParticipantEmployeeDetails'],
    async () => {
      return await handleRequest(
        ParticipantService.getApiCustomerApiAppParticipantParticipantEmployment()
      );
    },
    {
      cacheTime: 1000 * 60 * 5,
    }
  );

  return participantEmployeeDetails;
};

export default useGetParticipantEmployeeDetails;
