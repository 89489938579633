const InitialValuesForm = {
  SignUp: {
    stepOne: {
      token: '',
      isUsCitizen: '',
      socialSecurityNumber: '',
      employeeId: '',
      dateOfBirth: null,
      hireDate: null,
      companyTickerSymbol: '',
      code: '',
    },

    stepTwo: {
      email: '',
      confirmemail: '',
    },
    stepThree: {
      token: '',
      code: '',
      socialSecurityNumber: '',
      employeeId: '',
      dateOfBirth: '',
      hireDate: '',
      companyTickerSymbol: '',
      isUsCitizen: '',
      userName: '',
      password: '',
      otpToken: '',
    },
    stepFour: {
      token: '',
      code: '',
      socialSecurityNumber: '',
      employeeId: '',
      dateOfBirth: '',
      hireDate: '',
      companyTickerSymbol: '',
      isUsCitizen: '',
      userName: '',
      password: '',
      securityQuestion1: '',
      securityQuestion2: '',
      securityQuestion3: '',
      securityAnswer1: '',
      securityAnswer2: '',
      securityAnswer3: '',
      otpToken: '',
    },
  },
  tableParams: {
    filter: '',
    sorting: '',
    skipCount: 0,
    maxResultCount: 10,
  },
  changePassword: {
    currentPassword: '',
    newPassword: '',
    repeatPassword: '',
  },

  contactNumber: {
    callingCodeId: '',
    contactNumber: '',
    callingCodeStatus: '',
  },

  onboardingStepOneValues: {
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',
    genderId: '',
    fullLegalName: '',
    residenceStatusId: '',
    maritalStatusId: '',
    countryOfLegalResidencyId: '',
    currentHomeAddressCountryId: '',
    currentHomeAddressStateId: '',
    currentHomeAddressStateText: '',
    currentHomeAddressCityText: '',
    addressLine1: '',
    currentHomeAddressLine2: '',
    currentHomeAddressZipPostalCode: '',
    currentHomeAddressHomePhone: '',
    currentHomeAddressMobilePhone: '',
    workPhone: '',
    currentHomeAddressMobilePhoneCallingCodeId: '',
    currentHomeAddressHomePhoneCallingCodeId: '',
    workPhoneCallingCodeId: '',
    currentHomeAddressStartDate: '',
    mailingAddressCountryId: '',
    mailingAddressStateId: '',
    mailingAddressStateText: '',
    mailingAddressCityText: '',
    mailingAddressLine1: '',
    mailingAddressLine2: '',
    mailingAddressZipPostalCode: '',
    employerName: '',
    employmentCountryId: '',
    employmentStateId: '',
    employmentStateText: '',
    employmentCityText: '',
    employmentAddressLine1: '',
    employmentAddressLine2: '',
    employmentZipPostalCode: '',
    governmentDocumentTypeId: '',
    governmentDocumentIssuedCountryId: '',
    governmentDocumentIdNo: '',
    governmentDocumentIdIssueDate: '',
    governmentDocumentExpirationDate: '',
    governmentDocumentIdIssuedStateId: '',
    governmentDocumentIdIssuedStateText: '',
    governmentDocumentIdIssuedCityText: '',
    governmentDocumentIdZipOrPostalCode: '',
    governmentDocuments: [],
    governmentDocumentsNameList: [],
    customerTitleId: '',
    customerDepartmentId: '',
    costCenter: '',
  },
  onboardingStepTwoValues: {
    accountTypeId: '',
    registrationTypeId: '',
    employeedByARegisteredBrokerDealer: false,
    directorOfPolicyMaker: false,
    shareHolderOfAPubliclyOwnedCompany: false,
    participantInvestmentObjectiveId: '',
    participantAnnualIncomeId: '',
    participantLiquidNetWorthId: '',
    participantTotalNetWorthId: '',
    participantInvestmentExperienceId: '',
    numberOfDependents: '',
    beneficiaryFirstName: '',
    beneficiaryMiddleName: '',
    beneficiaryLastName: '',
    beneficiaryEmail: '',
    beneficiaryMobileCallingCodeId: '',
    beneficiaryMobileNumber: '',
    beneficiaryRelationship: '',
  },
  w9Form: {
    name: '',
    federalTax: '',
    limitedLiabilityCompanyText: '',
    federalTaxClassificationOtherText: '',
    address: '',
    cityStateZipCode: '',
    accountNumberList: '',
    requestorName: '',
    ssn: '',
    certificationDate: new Date(),
    signature: null,
  },
  w8Form: {
    name: '',
    citizenshipCountryId: '',
    permanentAddress: '',
    permanentCity: '',
    permanentCountryId: '',
    hasDifferentMailingAddress: false,
    mailingAddress: '',
    mailingCity: '',
    mailingCountryId: '',
    ssnOrIdentificationNumber: '',
    foreignTaxIdentifyingNumber: '',
    ftinLegallyRequired: false,
    referenceNumber: '',
    dateOfBirth: '',
    beneficialCountryId: '',
    beneficialArticle: '',
    beneficialPercentage: '',
    beneficialIncomeType: '',
    beneficialAdditionalCondition: '',
    capacitySignCertify: '',
    certificationDate: new Date(),
    printName: '',
    signature: '',
  },
};

export default InitialValuesForm;
