import Button from '../../components/button';
import Heading from '../../components/typography';
import userIcon from '../../assets/images/usericon.png';
import { SignUpConfirmation } from '../signup/signup-steps/signUpSteps.styled';
import { useNavigate } from 'react-router-dom';

const SignUpConfirmationPage = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <div>
      <SignUpConfirmation>
        <img src={userIcon} alt='' />
        <Heading variant={'h3'} title={'You have successfully registered to Wealthlane.'} />
        <Button
          variant={'primary'}
          type='button'
          title={'Log In to your Account'}
          onClick={() => {
            navigate(`/login`);
          }}
        />
      </SignUpConfirmation>
    </div>
  );
};

export default SignUpConfirmationPage;
