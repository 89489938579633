import { type UseMutationResult, useMutation } from 'react-query';
import { type NavigateFunction } from 'react-router-dom';
import {
  TaxCertificationService,
  // eslint-disable-next-line camelcase
  type Wealthlane_CustomerService_Dtos_TaxCertificationFormDTO_TaxCertificationFormW9DTO,
} from '../../services/wealthlane-customer-services';

const usePostW9Form = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  navigate: NavigateFunction
): UseMutationResult<
  // eslint-disable-next-line camelcase
  Wealthlane_CustomerService_Dtos_TaxCertificationFormDTO_TaxCertificationFormW9DTO | undefined,
  unknown,
  any,
  unknown
> => {
  return useMutation(
    async (payload: any) => {
      return await handleRequest(
        TaxCertificationService.postApiCustomerApiAppTaxCertificationTaxCertificationFormW9(payload)
      );
    },
    {
      onSuccess: async (
        response: // eslint-disable-next-line camelcase
        | Wealthlane_CustomerService_Dtos_TaxCertificationFormDTO_TaxCertificationFormW9DTO
          | undefined
      ) => {
        if (response !=null) {
          navigate('/profile', { state: { w9FormSubmitted: true, loading: true } });
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostW9Form;
