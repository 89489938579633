import { type UseQueryResult, useQuery } from 'react-query';
import {
  ParticipantSignUpService,
  // eslint-disable-next-line camelcase
  type Wealthlane_CustomerService_Dtos_ParticipantSignUp_ParticipantInformationForSignUpDto,
} from '../../services/wealthlane-customer-services';

const useGetSignUpInformation = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  token: string | null
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_CustomerService_Dtos_ParticipantSignUp_ParticipantInformationForSignUpDto | undefined,
  unknown
> => {
  const participantSignUpInformation = useQuery(
    ['getSignUpInformation', token],
    async () => {
      if (token != null) {
        return await handleRequest(
          ParticipantSignUpService.getApiCustomerApiAppParticipantSignUpParticipantInformationForSignUp()
        );
      }
    },
    {
      enabled: typeof token === 'string' && token != null && token !== '',
    }
  );

  return participantSignUpInformation;
};

export default useGetSignUpInformation;
