/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_AwardService_Dtos_ParticipantTask_AcceptPsuAwardDto } from '../models/Wealthlane_AwardService_Dtos_ParticipantTask_AcceptPsuAwardDto';
import type { Wealthlane_AwardService_Dtos_ParticipantTask_AcceptRsuAwardDto } from '../models/Wealthlane_AwardService_Dtos_ParticipantTask_AcceptRsuAwardDto';
import type { Wealthlane_AwardService_Dtos_ParticipantTask_AuditTrailsDto } from '../models/Wealthlane_AwardService_Dtos_ParticipantTask_AuditTrailsDto';
import type { Wealthlane_AwardService_Dtos_ParticipantTask_CheckBlackoutDto } from '../models/Wealthlane_AwardService_Dtos_ParticipantTask_CheckBlackoutDto';
import type { Wealthlane_AwardService_Dtos_ParticipantTask_CheckTradePreClearanceDto } from '../models/Wealthlane_AwardService_Dtos_ParticipantTask_CheckTradePreClearanceDto';
import type { Wealthlane_AwardService_Dtos_ParticipantTask_ElectionMethodDto } from '../models/Wealthlane_AwardService_Dtos_ParticipantTask_ElectionMethodDto';
import type { Wealthlane_AwardService_Dtos_ParticipantTask_ElectionMethodSettingDto } from '../models/Wealthlane_AwardService_Dtos_ParticipantTask_ElectionMethodSettingDto';
import type { Wealthlane_AwardService_Dtos_ParticipantTask_ExerciseMethodDto } from '../models/Wealthlane_AwardService_Dtos_ParticipantTask_ExerciseMethodDto';
import type { Wealthlane_AwardService_Dtos_ParticipantTask_HoldingDetailsDto } from '../models/Wealthlane_AwardService_Dtos_ParticipantTask_HoldingDetailsDto';
import type { Wealthlane_AwardService_Dtos_ParticipantTask_OptionAwardAcceptDetailsDto } from '../models/Wealthlane_AwardService_Dtos_ParticipantTask_OptionAwardAcceptDetailsDto';
import type { Wealthlane_AwardService_Dtos_ParticipantTask_ParticipantCurrentCashBalanceDto } from '../models/Wealthlane_AwardService_Dtos_ParticipantTask_ParticipantCurrentCashBalanceDto';
import type { Wealthlane_AwardService_Dtos_ParticipantTask_PendingPSUAwardDto } from '../models/Wealthlane_AwardService_Dtos_ParticipantTask_PendingPSUAwardDto';
import type { Wealthlane_AwardService_Dtos_ParticipantTask_PendingPSUElectAwardDto } from '../models/Wealthlane_AwardService_Dtos_ParticipantTask_PendingPSUElectAwardDto';
import type { Wealthlane_AwardService_Dtos_ParticipantTask_PendingRSUAwardDto } from '../models/Wealthlane_AwardService_Dtos_ParticipantTask_PendingRSUAwardDto';
import type { Wealthlane_AwardService_Dtos_ParticipantTask_PendingRSUElectAwardDto } from '../models/Wealthlane_AwardService_Dtos_ParticipantTask_PendingRSUElectAwardDto';
import type { Wealthlane_AwardService_Dtos_ParticipantTask_PsuAwardAcceptDetailsDto } from '../models/Wealthlane_AwardService_Dtos_ParticipantTask_PsuAwardAcceptDetailsDto';
import type { Wealthlane_AwardService_Dtos_ParticipantTask_PSUElectionMethodDto } from '../models/Wealthlane_AwardService_Dtos_ParticipantTask_PSUElectionMethodDto';
import type { Wealthlane_AwardService_Dtos_ParticipantTask_RsuAwardAcceptDetailsDto } from '../models/Wealthlane_AwardService_Dtos_ParticipantTask_RsuAwardAcceptDetailsDto';
import type { Wealthlane_AwardService_Dtos_ParticipantTask_TotalValueDto } from '../models/Wealthlane_AwardService_Dtos_ParticipantTask_TotalValueDto';
import type { Wealthlane_AwardService_Dtos_ParticipantTask_UpComingEventsDto } from '../models/Wealthlane_AwardService_Dtos_ParticipantTask_UpComingEventsDto';
import type { Wealthlane_AwardService_Dtos_Response_ResponseDto } from '../models/Wealthlane_AwardService_Dtos_Response_ResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ParticipantTaskService {

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppParticipantTaskAcceptRSuAward(
requestBody?: Wealthlane_AwardService_Dtos_ParticipantTask_AcceptRsuAwardDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/participant-task/accept-rSUAward',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_ParticipantTask_PendingRSUAwardDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppParticipantTaskPendingRSuAwards(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_ParticipantTask_PendingRSUAwardDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/participant-task/pending-rSUAwards',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_ParticipantTask_PendingRSUElectAwardDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppParticipantTaskPendingRSuElectAwards(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_ParticipantTask_PendingRSUElectAwardDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/participant-task/pending-rSUElect-awards',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_ParticipantTask_ElectionMethodSettingDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppParticipantTaskRSUElectionMethodSetting(): CancelablePromise<Wealthlane_AwardService_Dtos_ParticipantTask_ElectionMethodSettingDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/participant-task/r-sUElection-method-setting',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppParticipantTaskAcceptPSuAward(
requestBody?: Wealthlane_AwardService_Dtos_ParticipantTask_AcceptPsuAwardDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/participant-task/accept-pSUAward',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_ParticipantTask_PendingPSUAwardDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppParticipantTaskPendingPSuAwards(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_ParticipantTask_PendingPSUAwardDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/participant-task/pending-pSUAwards',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_ParticipantTask_PendingPSUElectAwardDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppParticipantTaskPendingPSuElectAwards(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_ParticipantTask_PendingPSUElectAwardDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/participant-task/pending-pSUElect-awards',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_ParticipantTask_ElectionMethodSettingDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppParticipantTaskPSUElectionMethodSetting(): CancelablePromise<Wealthlane_AwardService_Dtos_ParticipantTask_ElectionMethodSettingDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/participant-task/p-sUElection-method-setting',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_ParticipantTask_ElectionMethodSettingDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppParticipantTaskOptionsElectionMethodSetting(): CancelablePromise<Wealthlane_AwardService_Dtos_ParticipantTask_ElectionMethodSettingDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/participant-task/options-election-method-setting',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param rsuVestingDateId 
     * @param sellAll 
     * @param sellToCover 
     * @param holdToCover 
     * @returns Wealthlane_AwardService_Dtos_ParticipantTask_ElectionMethodDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppParticipantTaskElectionMethod(
rsuVestingDateId?: string,
sellAll?: boolean,
sellToCover?: boolean,
holdToCover?: boolean,
): CancelablePromise<Wealthlane_AwardService_Dtos_ParticipantTask_ElectionMethodDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/participant-task/election-method',
            query: {
                'RSUVestingDateId': rsuVestingDateId,
                'SellAll': sellAll,
                'SellToCover': sellToCover,
                'HoldToCover': holdToCover,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param psuVestingDateId 
     * @param sellAll 
     * @param sellToCover 
     * @param holdToCover 
     * @returns Wealthlane_AwardService_Dtos_ParticipantTask_PSUElectionMethodDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppParticipantTaskPsuElectionMethod(
psuVestingDateId?: string,
sellAll?: boolean,
sellToCover?: boolean,
holdToCover?: boolean,
): CancelablePromise<Wealthlane_AwardService_Dtos_ParticipantTask_PSUElectionMethodDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/participant-task/psu-election-method',
            query: {
                'PSUVestingDateId': psuVestingDateId,
                'SellAll': sellAll,
                'SellToCover': sellToCover,
                'HoldToCover': holdToCover,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param rsuVestingDateId 
     * @returns Wealthlane_AwardService_Dtos_ParticipantTask_CheckTradePreClearanceDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppParticipantTaskCheckTradePreClearanceOrBlackout(
rsuVestingDateId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_ParticipantTask_CheckTradePreClearanceDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/participant-task/check-trade-pre-clearance-or-blackout/{rsuVestingDateId}',
            path: {
                'rsuVestingDateId': rsuVestingDateId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param rsuVestingDateId 
     * @returns Wealthlane_AwardService_Dtos_ParticipantTask_CheckBlackoutDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppParticipantTaskCheckBlackout(
rsuVestingDateId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_ParticipantTask_CheckBlackoutDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/participant-task/check-blackout/{rsuVestingDateId}',
            path: {
                'rsuVestingDateId': rsuVestingDateId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param psuVestingDateId 
     * @returns Wealthlane_AwardService_Dtos_ParticipantTask_CheckTradePreClearanceDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppParticipantTaskCheckPSuTradePreClearanceOrBlackout(
psuVestingDateId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_ParticipantTask_CheckTradePreClearanceDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/participant-task/check-pSUTrade-pre-clearance-or-blackout/{psuVestingDateId}',
            path: {
                'psuVestingDateId': psuVestingDateId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param psuVestingDateId 
     * @returns Wealthlane_AwardService_Dtos_ParticipantTask_CheckBlackoutDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppParticipantTaskCheckPSuBlackout(
psuVestingDateId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_ParticipantTask_CheckBlackoutDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/participant-task/check-pSUBlackout/{psuVestingDateId}',
            path: {
                'psuVestingDateId': psuVestingDateId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_ParticipantTask_UpComingEventsDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppParticipantTaskUpComingEvents(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_ParticipantTask_UpComingEventsDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/participant-task/up-coming-events',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_ParticipantTask_AuditTrailsDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppParticipantTaskAuditTrails(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_ParticipantTask_AuditTrailsDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/participant-task/audit-trails',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_ParticipantTask_TotalValueDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppParticipantTaskTotalValue(): CancelablePromise<Wealthlane_AwardService_Dtos_ParticipantTask_TotalValueDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/participant-task/total-value',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param companyId 
     * @returns Wealthlane_AwardService_Dtos_ParticipantTask_HoldingDetailsDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppParticipantTaskHoldingDetails(
companyId: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_ParticipantTask_HoldingDetailsDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/participant-task/holding-details/{CompanyId}',
            path: {
                'CompanyId': companyId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param rsuAwardId 
     * @returns Wealthlane_AwardService_Dtos_ParticipantTask_RsuAwardAcceptDetailsDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppParticipantTaskRSUAwardAcceptDetails(
rsuAwardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_ParticipantTask_RsuAwardAcceptDetailsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/participant-task/r-sUAward-accept-details/{RsuAwardId}',
            path: {
                'RsuAwardId': rsuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param psuAwardId 
     * @returns Wealthlane_AwardService_Dtos_ParticipantTask_PsuAwardAcceptDetailsDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppParticipantTaskPSUAwardAcceptDetails(
psuAwardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_ParticipantTask_PsuAwardAcceptDetailsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/participant-task/p-sUAward-accept-details/{PsuAwardId}',
            path: {
                'PsuAwardId': psuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param optionAwardId 
     * @returns Wealthlane_AwardService_Dtos_ParticipantTask_OptionAwardAcceptDetailsDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppParticipantTaskOptionAwardAcceptDetails(
optionAwardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_ParticipantTask_OptionAwardAcceptDetailsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/participant-task/option-award-accept-details/{OptionAwardId}',
            path: {
                'OptionAwardId': optionAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppParticipantTaskAcceptOptionAward(
requestBody?: Wealthlane_AwardService_Dtos_ParticipantTask_AcceptRsuAwardDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/participant-task/accept-option-award',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param optionVestingDateId 
     * @param exerciseQuantity 
     * @param sellAll 
     * @param sellToCover 
     * @param holdToCover 
     * @returns Wealthlane_AwardService_Dtos_ParticipantTask_ExerciseMethodDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppParticipantTaskOptionElectionMethod(
optionVestingDateId?: string,
exerciseQuantity?: number,
sellAll?: boolean,
sellToCover?: boolean,
holdToCover?: boolean,
): CancelablePromise<Wealthlane_AwardService_Dtos_ParticipantTask_ExerciseMethodDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/participant-task/option-election-method',
            query: {
                'OptionVestingDateId': optionVestingDateId,
                'ExerciseQuantity': exerciseQuantity,
                'SellAll': sellAll,
                'SellToCover': sellToCover,
                'HoldToCover': holdToCover,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param optionVestingDateId 
     * @returns Wealthlane_AwardService_Dtos_ParticipantTask_CheckTradePreClearanceDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppParticipantTaskCheckTradePreClearanceOrBlackoutForExercise(
optionVestingDateId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_ParticipantTask_CheckTradePreClearanceDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/participant-task/check-trade-pre-clearance-or-blackout-for-exercise/{optionVestingDateId}',
            path: {
                'optionVestingDateId': optionVestingDateId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param optionAwardId 
     * @returns Wealthlane_AwardService_Dtos_ParticipantTask_CheckTradePreClearanceDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppParticipantTaskCheckTradePreClearanceOrBlackoutForExerciseByAwardId(
optionAwardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_ParticipantTask_CheckTradePreClearanceDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/participant-task/check-trade-pre-clearance-or-blackout-for-exercise-by-award-id/{optionAwardId}',
            path: {
                'optionAwardId': optionAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_ParticipantTask_ParticipantCurrentCashBalanceDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppParticipantTaskParticipantCurrentCashBalance(): CancelablePromise<Wealthlane_AwardService_Dtos_ParticipantTask_ParticipantCurrentCashBalanceDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/participant-task/participant-current-cash-balance',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
