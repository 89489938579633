/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Transactions_TransactionList_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Transactions_TransactionList_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_AwardService_Dtos_Transactions_AwardTypeDto } from '../models/Wealthlane_AwardService_Dtos_Transactions_AwardTypeDto';
import type { Wealthlane_AwardService_Dtos_Transactions_TaxesDto } from '../models/Wealthlane_AwardService_Dtos_Transactions_TaxesDto';
import type { Wealthlane_AwardService_Dtos_Transactions_TransactionCancelStatusDto } from '../models/Wealthlane_AwardService_Dtos_Transactions_TransactionCancelStatusDto';
import type { Wealthlane_AwardService_Dtos_Transactions_TransactionDto } from '../models/Wealthlane_AwardService_Dtos_Transactions_TransactionDto';
import type { Wealthlane_AwardService_Dtos_Transactions_TransactionStatusDto } from '../models/Wealthlane_AwardService_Dtos_Transactions_TransactionStatusDto';
import type { Wealthlane_AwardService_Dtos_Transactions_TransactionStatusListDto } from '../models/Wealthlane_AwardService_Dtos_Transactions_TransactionStatusListDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TransactionService {

    /**
     * @param transactionId 
     * @returns Wealthlane_AwardService_Dtos_Transactions_TransactionDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppTransactionTransactionById(
transactionId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Transactions_TransactionDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/transaction/transaction-by-id/{transactionId}',
            path: {
                'transactionId': transactionId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Transactions_TransactionStatusListDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppTransactionTransactionStatusList(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Transactions_TransactionStatusListDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/transaction/transaction-status-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param approvalStatusIds 
     * @param fromDate 
     * @param toDate 
     * @param taxCollectionStatuses 
     * @param transferAllocateStatuses 
     * @param settlementStatuses 
     * @param awardTypes 
     * @param settlementTypes 
     * @param recordTypes 
     * @param transactionStatus 
     * @param cancelStatusId 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Transactions_TransactionList_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAwardApiAppTransactionTransactionListForParticipant(
approvalStatusIds?: Array<number>,
fromDate?: string,
toDate?: string,
taxCollectionStatuses?: Array<number>,
transferAllocateStatuses?: Array<number>,
settlementStatuses?: Array<number>,
awardTypes?: Array<string>,
settlementTypes?: Array<string>,
recordTypes?: Array<string>,
transactionStatus?: Array<number>,
cancelStatusId?: Array<string>,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Transactions_TransactionList_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/transaction/transaction-list-for-participant',
            query: {
                'ApprovalStatusIds': approvalStatusIds,
                'FromDate': fromDate,
                'ToDate': toDate,
                'TaxCollectionStatuses': taxCollectionStatuses,
                'TransferAllocateStatuses': transferAllocateStatuses,
                'SettlementStatuses': settlementStatuses,
                'AwardTypes': awardTypes,
                'SettlementTypes': settlementTypes,
                'RecordTypes': recordTypes,
                'TransactionStatus': transactionStatus,
                'CancelStatusId': cancelStatusId,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Transactions_AwardTypeDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppTransactionAwardTypeList(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Transactions_AwardTypeDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/transaction/award-type-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Transactions_TransactionStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppTransactionTaxCollectionStatusList(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Transactions_TransactionStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/transaction/tax-collection-status-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Transactions_TransactionCancelStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppTransactionTransactionCancelStatusList(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Transactions_TransactionCancelStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/transaction/transaction-cancel-status-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Transactions_TransactionStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppTransactionTransferAllocateStatusList(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Transactions_TransactionStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/transaction/transfer-allocate-status-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Transactions_TransactionStatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppTransactionSettlementStatusList(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Transactions_TransactionStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/transaction/settlement-status-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param transactionId 
     * @returns Wealthlane_AwardService_Dtos_Transactions_TaxesDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppTransactionTaxDetailsByTransactionId(
transactionId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Transactions_TaxesDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/transaction/tax-details-by-transaction-id/{TransactionId}',
            path: {
                'TransactionId': transactionId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
