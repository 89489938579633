/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Release_GetReleaseDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Release_GetReleaseDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Release_IncludedReleaseDetailDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Release_IncludedReleaseDetailDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Release_ReleaseDetailDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Release_ReleaseDetailDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Release_ReleaseOutputListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Release_ReleaseOutputListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_AwardService_Dtos_Common_CalculationRequestDto } from '../models/Wealthlane_AwardService_Dtos_Common_CalculationRequestDto';
import type { Wealthlane_AwardService_Dtos_Common_CalculationResultDto } from '../models/Wealthlane_AwardService_Dtos_Common_CalculationResultDto';
import type { Wealthlane_AwardService_Dtos_OptionExercise_StatusDto } from '../models/Wealthlane_AwardService_Dtos_OptionExercise_StatusDto';
import type { Wealthlane_AwardService_Dtos_OptionExercise_UpdateTaxesDto } from '../models/Wealthlane_AwardService_Dtos_OptionExercise_UpdateTaxesDto';
import type { Wealthlane_AwardService_Dtos_Release_CreateReleaseDto } from '../models/Wealthlane_AwardService_Dtos_Release_CreateReleaseDto';
import type { Wealthlane_AwardService_Dtos_Release_ReleaseDto } from '../models/Wealthlane_AwardService_Dtos_Release_ReleaseDto';
import type { Wealthlane_AwardService_Dtos_Release_ReleaseItemDetailDto } from '../models/Wealthlane_AwardService_Dtos_Release_ReleaseItemDetailDto';
import type { Wealthlane_AwardService_Dtos_Release_ReleaseItemIdsDto } from '../models/Wealthlane_AwardService_Dtos_Release_ReleaseItemIdsDto';
import type { Wealthlane_AwardService_Dtos_Release_ReleaseOutputDto } from '../models/Wealthlane_AwardService_Dtos_Release_ReleaseOutputDto';
import type { Wealthlane_AwardService_Dtos_Release_ReleaseResponseDto } from '../models/Wealthlane_AwardService_Dtos_Release_ReleaseResponseDto';
import type { Wealthlane_AwardService_Dtos_Release_ReleaseUpdateDto } from '../models/Wealthlane_AwardService_Dtos_Release_ReleaseUpdateDto';
import type { Wealthlane_AwardService_Dtos_Release_StatusDto } from '../models/Wealthlane_AwardService_Dtos_Release_StatusDto';
import type { Wealthlane_AwardService_Dtos_Release_TransactionDetailDto } from '../models/Wealthlane_AwardService_Dtos_Release_TransactionDetailDto';
import type { Wealthlane_AwardService_Dtos_Release_UpdateReleaseDto } from '../models/Wealthlane_AwardService_Dtos_Release_UpdateReleaseDto';
import type { Wealthlane_AwardService_Dtos_Transactions_ElectionHistoryDto } from '../models/Wealthlane_AwardService_Dtos_Transactions_ElectionHistoryDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReleaseService {

    /**
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @param searchKeyWord 
     * @param name 
     * @param expectedReleaseDate 
     * @param releaseStatusId 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Release_GetReleaseDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAwardApiAppReleaseReleaseList(
sorting?: string,
skipCount?: number,
maxResultCount?: number,
searchKeyWord?: string,
name?: string,
expectedReleaseDate?: string,
releaseStatusId?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Release_GetReleaseDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/release/release-list',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'SearchKeyWord': searchKeyWord,
                'Name': name,
                'ExpectedReleaseDate': expectedReleaseDate,
                'ReleaseStatusId': releaseStatusId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_AwardService_Dtos_Release_ReleaseOutputDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppReleaseReleaseSummary(
id: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Release_ReleaseOutputDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/release/{id}/release-summary',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Release_ReleaseOutputListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAwardApiAppReleaseReleaseDetail(
id: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Release_ReleaseOutputListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/release/{id}/release-detail',
            path: {
                'id': id,
            },
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Release_ReleaseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppReleaseRelease(
requestBody?: Wealthlane_AwardService_Dtos_Release_CreateReleaseDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_Release_ReleaseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/release/release',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param releaseId 
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Release_ReleaseDto Success
     * @throws ApiError
     */
    public static putApiAwardApiAppReleaseRelease(
releaseId: string,
requestBody?: Wealthlane_AwardService_Dtos_Release_UpdateReleaseDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_Release_ReleaseDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/award/api/app/release/release/{releaseId}',
            path: {
                'releaseId': releaseId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param releaseId 
     * @returns boolean Success
     * @throws ApiError
     */
    public static deleteApiAwardApiAppReleaseRelease(
releaseId: string,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/award/api/app/release/release/{releaseId}',
            path: {
                'releaseId': releaseId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns boolean Success
     * @throws ApiError
     */
    public static deleteApiAwardApiAppReleaseReleaseItem(
requestBody?: Array<Wealthlane_AwardService_Dtos_Release_ReleaseItemIdsDto>,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/award/api/app/release/release-item',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @param searchKeyWord 
     * @param grantId 
     * @param awardTypeId 
     * @param fromDate 
     * @param toDate 
     * @param settlementTypeIds 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Release_ReleaseDetailDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAwardApiAppReleaseVestingList(
sorting?: string,
skipCount?: number,
maxResultCount?: number,
searchKeyWord?: string,
grantId?: string,
awardTypeId?: string,
fromDate?: string,
toDate?: string,
settlementTypeIds?: Array<string>,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Release_ReleaseDetailDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/release/vesting-list',
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'SearchKeyWord': searchKeyWord,
                'GrantId': grantId,
                'AwardTypeId': awardTypeId,
                'FromDate': fromDate,
                'ToDate': toDate,
                'SettlementTypeIds': settlementTypeIds,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @param searchKeyWord 
     * @param grantId 
     * @param awardTypeId 
     * @param fromDate 
     * @param toDate 
     * @param settlementTypeIds 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Release_IncludedReleaseDetailDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiAwardApiAppReleaseIncludedReleaseList(
id: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
searchKeyWord?: string,
grantId?: string,
awardTypeId?: string,
fromDate?: string,
toDate?: string,
settlementTypeIds?: Array<string>,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_Release_IncludedReleaseDetailDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/release/{id}/included-release-list',
            path: {
                'id': id,
            },
            query: {
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'SearchKeyWord': searchKeyWord,
                'GrantId': grantId,
                'AwardTypeId': awardTypeId,
                'FromDate': fromDate,
                'ToDate': toDate,
                'SettlementTypeIds': settlementTypeIds,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param fmv 
     * @param requestBody 
     * @returns boolean Success
     * @throws ApiError
     */
    public static putApiAwardApiAppReleaseFMV(
fmv: number,
requestBody?: Array<Wealthlane_AwardService_Dtos_Release_ReleaseItemIdsDto>,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/award/api/app/release/f-mV',
            query: {
                'FMV': fmv,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param releaseName 
     * @returns Wealthlane_AwardService_Dtos_Release_ReleaseResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppReleaseCheckDuplicateReleaseName(
releaseName?: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Release_ReleaseResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/release/check-duplicate-release-name',
            query: {
                'releaseName': releaseName,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param releaseItemId 
     * @returns Wealthlane_AwardService_Dtos_Release_ReleaseItemDetailDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppReleaseReleaseItemDetail(
releaseItemId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Release_ReleaseItemDetailDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/release/release-item-detail/{releaseItemId}',
            path: {
                'releaseItemId': releaseItemId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param releaseId 
     * @param name 
     * @returns Wealthlane_AwardService_Dtos_Release_ReleaseDto Success
     * @throws ApiError
     */
    public static putApiAwardApiAppReleaseReleaseName(
releaseId: string,
name?: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Release_ReleaseDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/award/api/app/release/release-name/{releaseId}',
            path: {
                'releaseId': releaseId,
            },
            query: {
                'name': name,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param releaseItemId 
     * @returns Wealthlane_AwardService_Dtos_Release_TransactionDetailDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppReleaseTransactionDetail(
releaseItemId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Release_TransactionDetailDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/release/transaction-detail/{releaseItemId}',
            path: {
                'releaseItemId': releaseItemId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param releaseItemId 
     * @returns Wealthlane_AwardService_Dtos_OptionExercise_UpdateTaxesDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppReleaseTaxDetailsByReleaseItemId(
releaseItemId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_OptionExercise_UpdateTaxesDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/release/tax-details-by-release-item-id/{releaseItemId}',
            path: {
                'releaseItemId': releaseItemId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiAwardApiAppReleaseCalculateTax(
requestBody?: Array<Wealthlane_AwardService_Dtos_Release_ReleaseItemIdsDto>,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/release/calculate-tax',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_Release_StatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppReleaseReleaseStatuses(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Release_StatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/release/release-statuses',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param releaseId 
     * @returns Wealthlane_AwardService_Dtos_Release_ReleaseDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppReleaseReleaseById(
releaseId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_Release_ReleaseDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/release/release-by-id/{releaseId}',
            path: {
                'releaseId': releaseId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_OptionExercise_StatusDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppReleaseAwardTypes(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_OptionExercise_StatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/release/award-types',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Release_ReleaseDto Success
     * @throws ApiError
     */
    public static putApiAwardApiAppReleaseReleaseDetail(
requestBody?: Wealthlane_AwardService_Dtos_Release_ReleaseUpdateDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_Release_ReleaseDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/award/api/app/release/release-detail',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_Common_CalculationResultDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppReleaseCalculationTest(
requestBody?: Wealthlane_AwardService_Dtos_Common_CalculationRequestDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_Common_CalculationResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/release/calculation-test',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param awardId 
     * @returns Wealthlane_AwardService_Dtos_Transactions_ElectionHistoryDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppReleaseRsuTransactionListByAwardId(
awardId: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Transactions_ElectionHistoryDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/release/rsu-transaction-list-by-award-id/{AwardId}',
            path: {
                'AwardId': awardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param awardId 
     * @returns Wealthlane_AwardService_Dtos_Transactions_ElectionHistoryDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppReleasePsuTransactionListByAwardId(
awardId: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_Transactions_ElectionHistoryDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/release/psu-transaction-list-by-award-id/{AwardId}',
            path: {
                'AwardId': awardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
