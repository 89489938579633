import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type RootState } from '../store/store';
import { IDENTITY_CONFIG } from '../utils/authConst';

interface AuthState {
  isAuthenticated: boolean | null;
  token: string | null;
  user: any;
}

const sliceState: AuthState = { user: {}, isAuthenticated: null, token: null };

const slice = createSlice({
  name: 'auth',
  initialState: sliceState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: { isAuthenticated, token, user },
      }: PayloadAction<{ isAuthenticated: boolean; token: string; user: object }>
    ) => {
      state.isAuthenticated = isAuthenticated;
      state.token = token;
      state.user = user;
    },
  },
});

export const { setCredentials } = slice.actions;

export default slice.reducer;

export const selectIsAuthenticated = (state: RootState): boolean | null =>
  state.auth.isAuthenticated;

export const selectToken = (state: RootState): string => {
  const oidcClientData =
    IDENTITY_CONFIG.authority != null && IDENTITY_CONFIG.client_id != null
      ? localStorage.getItem(`oidc.user:${IDENTITY_CONFIG.authority}:${IDENTITY_CONFIG.client_id}`)
      : '';
  const jsonData = oidcClientData != null && JSON.parse(oidcClientData);
  return jsonData?.access_token;
};

export const selectUser = (state: RootState): Record<string, unknown> => state?.auth?.user;
