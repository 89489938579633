import { type TableParamsType } from '../../types/common';
import { type UseQueryResult, useQuery } from 'react-query';
import {
  TradePreClearanceService,
  // eslint-disable-next-line camelcase
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_TradePreClearance_TradePreClearanceRequestListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
} from '../../services/wealthlane-award-services';

const useGetPreClearanceRequestList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType
): UseQueryResult<
  // eslint-disable-next-line camelcase
  | Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_AwardService_Dtos_TradePreClearance_TradePreClearanceRequestListDto_Wealthlane_AwardService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
  | undefined,
  unknown
> => {
  const { approvalStatusIds, awardTypeId, filter, sorting, skipCount, maxResultCount } = tableInfo;
  const preClearanceRequestList = useQuery(
    [
      'getPreClearanceRequestList',
      approvalStatusIds,
      awardTypeId,
      filter,
      sorting,
      skipCount,
      maxResultCount,
    ],
    async () => {
      return await handleRequest(
        TradePreClearanceService.getApiAwardApiAppTradePreClearancePreClearanceRequestList(
          approvalStatusIds,
          awardTypeId,
          filter,
          sorting,
          skipCount,
          maxResultCount
        )
      );
    }
  );

  return preClearanceRequestList;
};

export default useGetPreClearanceRequestList;
