import { type UseQueryResult, useQuery } from 'react-query';
import {
  TaxCalculationService,
  Wealthlane_TaxService_Dtos_ParticipantTransaction_ParticipantDetailTransaction,
  Wealthlane_TaxService_Dtos_ParticipantTransaction_TaxCalculationResponseDto,
} from '../../services/wealthlane-tax-services';

const useGetParticipantTaxViewDetail = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  payload?: Wealthlane_TaxService_Dtos_ParticipantTransaction_ParticipantDetailTransaction
): UseQueryResult<
  | {
      title: string | null | undefined;
      icon: string;
      isContent: boolean;
      isUSCitizen: null;
      content: string | number;
    }[]
  | undefined,
  unknown
> => {
  const TaxViewDetails = useQuery(
    ['taxViewDetail'],
    async () => {
      return await handleRequest(
        TaxCalculationService.postApiTaxApiAppTaxCalculationParticipantTaxDetailForParticipant(
          payload
        )
      );
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            title: item?.taxName,
            icon: 'badge',
            isContent: false,
            isUSCitizen: null,
            content: item?.taxRate !== null ? `${item?.taxRate}%` : '-',
          };
        });
      },
    }
  );

  return TaxViewDetails;
};

export default useGetParticipantTaxViewDetail;
