import { type UseQueryResult, useQuery } from 'react-query';
import { type TableParamsType } from '../../types/common';
import {
  OptionExerciseService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_OptionExercise_OptionExerciseListDto,
} from '../../services/wealthlane-award-services';

const useGetOptionExerciseExercisableAwards = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  title: string
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_OptionExercise_OptionExerciseListDto[] | undefined,
  unknown
> => {
  const optionExerciseExercisableAwards = useQuery(
    ['getOptionExercisedList', title],
    async () => {
      return await handleRequest(
        OptionExerciseService.getApiAwardApiAppOptionExerciseExercisableAwards(title)
      );
    }
    // {
    //   keepPreviousData: true,
    // }
  );
  return optionExerciseExercisableAwards;
};

export default useGetOptionExerciseExercisableAwards;
