/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_AwardService_Dtos_MyHoldings_AwardSummaryDto } from '../models/Wealthlane_AwardService_Dtos_MyHoldings_AwardSummaryDto';
import type { Wealthlane_AwardService_Dtos_MyHoldings_AwardSummaryListDto } from '../models/Wealthlane_AwardService_Dtos_MyHoldings_AwardSummaryListDto';
import type { Wealthlane_AwardService_Dtos_MyHoldings_HoldingsDto } from '../models/Wealthlane_AwardService_Dtos_MyHoldings_HoldingsDto';
import type { Wealthlane_AwardService_Dtos_MyHoldings_PortfolioCashDto } from '../models/Wealthlane_AwardService_Dtos_MyHoldings_PortfolioCashDto';
import type { Wealthlane_AwardService_Dtos_OptionAward_OptionAwardExerciseHistoryDto } from '../models/Wealthlane_AwardService_Dtos_OptionAward_OptionAwardExerciseHistoryDto';
import type { Wealthlane_AwardService_Dtos_OptionAward_OptionAwardNumberDto } from '../models/Wealthlane_AwardService_Dtos_OptionAward_OptionAwardNumberDto';
import type { Wealthlane_AwardService_Dtos_OptionAward_OptionAwardOverviewDto } from '../models/Wealthlane_AwardService_Dtos_OptionAward_OptionAwardOverviewDto';
import type { Wealthlane_AwardService_Dtos_OptionAward_OptionAwardVestingScheduleDto } from '../models/Wealthlane_AwardService_Dtos_OptionAward_OptionAwardVestingScheduleDto';
import type { Wealthlane_AwardService_Dtos_OptionAward_OptionDocumentDto } from '../models/Wealthlane_AwardService_Dtos_OptionAward_OptionDocumentDto';
import type { Wealthlane_AwardService_Dtos_PerformanceRule_PerformanceRuleDetailForViewDto } from '../models/Wealthlane_AwardService_Dtos_PerformanceRule_PerformanceRuleDetailForViewDto';
import type { Wealthlane_AwardService_Dtos_PSUAward_PSUAwardBlobDto } from '../models/Wealthlane_AwardService_Dtos_PSUAward_PSUAwardBlobDto';
import type { Wealthlane_AwardService_Dtos_PSUAward_PSUAwardBlobRequestDto } from '../models/Wealthlane_AwardService_Dtos_PSUAward_PSUAwardBlobRequestDto';
import type { Wealthlane_AwardService_Dtos_PSUAward_PsuAwardNumberDto } from '../models/Wealthlane_AwardService_Dtos_PSUAward_PsuAwardNumberDto';
import type { Wealthlane_AwardService_Dtos_PSUAward_PSUAwardOverviewDto } from '../models/Wealthlane_AwardService_Dtos_PSUAward_PSUAwardOverviewDto';
import type { Wealthlane_AwardService_Dtos_PSUAward_PsuAwardSettlementHistory } from '../models/Wealthlane_AwardService_Dtos_PSUAward_PsuAwardSettlementHistory';
import type { Wealthlane_AwardService_Dtos_PSUAward_PSUAwardVestingScheduleDto } from '../models/Wealthlane_AwardService_Dtos_PSUAward_PSUAwardVestingScheduleDto';
import type { Wealthlane_AwardService_Dtos_PSUAward_PSUDocumentDto } from '../models/Wealthlane_AwardService_Dtos_PSUAward_PSUDocumentDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_RSUAwardBlobDto } from '../models/Wealthlane_AwardService_Dtos_RSUAward_RSUAwardBlobDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_RSUAwardBlobRequestDto } from '../models/Wealthlane_AwardService_Dtos_RSUAward_RSUAwardBlobRequestDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_RsuAwardNumberDto } from '../models/Wealthlane_AwardService_Dtos_RSUAward_RsuAwardNumberDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_RSUAwardOverviewDto } from '../models/Wealthlane_AwardService_Dtos_RSUAward_RSUAwardOverviewDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_RsuAwardSettlementHistory } from '../models/Wealthlane_AwardService_Dtos_RSUAward_RsuAwardSettlementHistory';
import type { Wealthlane_AwardService_Dtos_RSUAward_RSUAwardVestingScheduleDto } from '../models/Wealthlane_AwardService_Dtos_RSUAward_RSUAwardVestingScheduleDto';
import type { Wealthlane_AwardService_Dtos_RSUAward_RSUDocumentDto } from '../models/Wealthlane_AwardService_Dtos_RSUAward_RSUDocumentDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MyHoldingsService {

    /**
     * @returns Wealthlane_AwardService_Dtos_MyHoldings_HoldingsDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppMyHoldingsHoldingsOld(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_MyHoldings_HoldingsDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/my-holdings/holdings-old',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_MyHoldings_HoldingsDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppMyHoldingsHoldings(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_MyHoldings_HoldingsDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/my-holdings/holdings',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_MyHoldings_AwardSummaryDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppMyHoldingsAwardSummaries(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_MyHoldings_AwardSummaryDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/my-holdings/award-summaries',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_MyHoldings_AwardSummaryDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppMyHoldingsPsuAwardSummaries(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_MyHoldings_AwardSummaryDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/my-holdings/psu-award-summaries',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_MyHoldings_AwardSummaryListDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppMyHoldingsAwardSummariesList(): CancelablePromise<Wealthlane_AwardService_Dtos_MyHoldings_AwardSummaryListDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/my-holdings/award-summaries-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param rsuAwardId 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_RSUAwardOverviewDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppMyHoldingsRSUAwardOverview(
rsuAwardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_RSUAward_RSUAwardOverviewDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/my-holdings/r-sUAward-overview/{RSUAwardId}',
            path: {
                'RSUAwardId': rsuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param rsuAwardId 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_RSUAwardVestingScheduleDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppMyHoldingsRSUAwardVestingSchedule(
rsuAwardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_RSUAward_RSUAwardVestingScheduleDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/my-holdings/r-sUAward-vesting-schedule/{RSUAwardId}',
            path: {
                'RSUAwardId': rsuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param rsuAwardId 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_RSUDocumentDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppMyHoldingsRSUAwardDocuments(
rsuAwardId: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_RSUDocumentDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/my-holdings/r-sUAward-documents/{RSUAwardId}',
            path: {
                'RSUAwardId': rsuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_RSUAwardBlobDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppMyHoldingsDownloadDocument(
requestBody?: Wealthlane_AwardService_Dtos_RSUAward_RSUAwardBlobRequestDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_RSUAward_RSUAwardBlobDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/my-holdings/download-document',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param rsuAwardId 
     * @returns string Success
     * @throws ApiError
     */
    public static getApiAwardApiAppMyHoldingsRSUAwardStatusById(
rsuAwardId: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/my-holdings/r-sUAward-status-by-id/{RSUAwardId}',
            path: {
                'RSUAwardId': rsuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param rsuAwardId 
     * @returns Wealthlane_AwardService_Dtos_RSUAward_RsuAwardSettlementHistory Success
     * @throws ApiError
     */
    public static getApiAwardApiAppMyHoldingsRSUAwardSettlementHistory(
rsuAwardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_RSUAward_RsuAwardSettlementHistory> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/my-holdings/r-sUAward-settlement-history/{RSUAwardId}',
            path: {
                'RSUAwardId': rsuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_RSUAward_RsuAwardNumberDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppMyHoldingsRSUAwardNumbers(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_RSUAward_RsuAwardNumberDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/my-holdings/r-sUAward-numbers',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param psuAwardId 
     * @returns Wealthlane_AwardService_Dtos_PSUAward_PSUAwardOverviewDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppMyHoldingsPSUAwardOverview(
psuAwardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_PSUAward_PSUAwardOverviewDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/my-holdings/p-sUAward-overview/{PSUAwardId}',
            path: {
                'PSUAwardId': psuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param psuAwardId 
     * @returns Wealthlane_AwardService_Dtos_PSUAward_PSUAwardVestingScheduleDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppMyHoldingsPSUAwardVestingSchedule(
psuAwardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_PSUAward_PSUAwardVestingScheduleDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/my-holdings/p-sUAward-vesting-schedule/{PSUAwardId}',
            path: {
                'PSUAwardId': psuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param psuAwardId 
     * @returns Wealthlane_AwardService_Dtos_PSUAward_PSUDocumentDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppMyHoldingsPSUAwardDocuments(
psuAwardId: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_PSUAward_PSUDocumentDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/my-holdings/p-sUAward-documents/{PSUAwardId}',
            path: {
                'PSUAwardId': psuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardService_Dtos_PSUAward_PSUAwardBlobDto Success
     * @throws ApiError
     */
    public static postApiAwardApiAppMyHoldingsDownloadPsuDocument(
requestBody?: Wealthlane_AwardService_Dtos_PSUAward_PSUAwardBlobRequestDto,
): CancelablePromise<Wealthlane_AwardService_Dtos_PSUAward_PSUAwardBlobDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award/api/app/my-holdings/download-psu-document',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param psuAwardId 
     * @returns string Success
     * @throws ApiError
     */
    public static getApiAwardApiAppMyHoldingsPSUAwardStatusById(
psuAwardId: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/my-holdings/p-sUAward-status-by-id/{PSUAwardId}',
            path: {
                'PSUAwardId': psuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param psuAwardId 
     * @returns Wealthlane_AwardService_Dtos_PSUAward_PsuAwardSettlementHistory Success
     * @throws ApiError
     */
    public static getApiAwardApiAppMyHoldingsPSUAwardSettlementHistory(
psuAwardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_PSUAward_PsuAwardSettlementHistory> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/my-holdings/p-sUAward-settlement-history/{PSUAwardId}',
            path: {
                'PSUAwardId': psuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_PSUAward_PsuAwardNumberDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppMyHoldingsPSUAwardNumbers(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_PSUAward_PsuAwardNumberDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/my-holdings/p-sUAward-numbers',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param optionAwardId 
     * @returns Wealthlane_AwardService_Dtos_OptionAward_OptionAwardVestingScheduleDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppMyHoldingsOptionAwardVestingSchedule(
optionAwardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_OptionAward_OptionAwardVestingScheduleDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/my-holdings/option-award-vesting-schedule/{OptionAwardId}',
            path: {
                'OptionAwardId': optionAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param optionAwardId 
     * @returns Wealthlane_AwardService_Dtos_OptionAward_OptionAwardOverviewDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppMyHoldingsOptionAwardOverview(
optionAwardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_OptionAward_OptionAwardOverviewDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/my-holdings/option-award-overview/{OptionAwardId}',
            path: {
                'OptionAwardId': optionAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_MyHoldings_AwardSummaryDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppMyHoldingsOptionAwardSummaries(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_MyHoldings_AwardSummaryDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/my-holdings/option-award-summaries',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param optionAwardId 
     * @returns Wealthlane_AwardService_Dtos_OptionAward_OptionDocumentDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppMyHoldingsOptionAwardDocuments(
optionAwardId: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_OptionAward_OptionDocumentDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/my-holdings/option-award-documents/{optionAwardId}',
            path: {
                'optionAwardId': optionAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param optionAwardId 
     * @returns string Success
     * @throws ApiError
     */
    public static getApiAwardApiAppMyHoldingsOptionAwardStatusById(
optionAwardId: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/my-holdings/option-award-status-by-id/{optionAwardId}',
            path: {
                'optionAwardId': optionAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param type 
     * @returns Wealthlane_AwardService_Dtos_OptionAward_OptionAwardNumberDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppMyHoldingsOptionAwardNumbers(
type?: string,
): CancelablePromise<Array<Wealthlane_AwardService_Dtos_OptionAward_OptionAwardNumberDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/my-holdings/option-award-numbers',
            query: {
                'type': type,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param optionAwardId 
     * @returns Wealthlane_AwardService_Dtos_OptionAward_OptionAwardExerciseHistoryDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppMyHoldingsOptionAwardExerciseHistory(
optionAwardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_OptionAward_OptionAwardExerciseHistoryDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/my-holdings/option-award-exercise-history/{optionAwardId}',
            path: {
                'optionAwardId': optionAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardService_Dtos_MyHoldings_PortfolioCashDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppMyHoldingsParticipantCash(): CancelablePromise<Array<Wealthlane_AwardService_Dtos_MyHoldings_PortfolioCashDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/my-holdings/participant-cash',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param awardId 
     * @returns Wealthlane_AwardService_Dtos_PerformanceRule_PerformanceRuleDetailForViewDto Success
     * @throws ApiError
     */
    public static getApiAwardApiAppMyHoldingsPerformanceRuleDetail(
awardId: string,
): CancelablePromise<Wealthlane_AwardService_Dtos_PerformanceRule_PerformanceRuleDetailForViewDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award/api/app/my-holdings/performance-rule-detail/{awardId}',
            path: {
                'awardId': awardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
