import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import {
  OptionExerciseService,
  //  eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_OptionExercise_CalculateExerciseRequestDto,
} from '../../services/wealthlane-award-services';
import { isEmpty } from 'lodash';
import ToastComponent from '../../components/toast';

const usePostOptionExerciseCalculateExercise = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  newData: any,
  setNewData: any,
  rowId: any,
  setCalculated: any,
  setExerciseResponse: any,
  exerciseResponse: any
): UseMutationResult<
  //  eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_OptionExercise_CalculateExerciseRequestDto | undefined,
  unknown,
  any,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload) => {
      const newPayload = {
        awardId: payload?.awardId,
        exerciseShare: payload?.exerciseShares,
        settlementTypeId: payload?.settlementTypeId,
      };
      return await handleRequest(
        OptionExerciseService.postApiAwardApiAppOptionExerciseCalculateExercise(newPayload)
      );
    },
    {
      onSuccess: async (
        // eslint-disable-next-line camelcase
        response: any
      ) => {
        if (response != null) {
          toast.success(<ToastComponent label='Success' message={`Exercise Calculated.`} />);

          const filter: any = newData?.find((item: any) => item?.awardId === rowId);
          filter.netShare = response?.netShare;
          filter.netCash = response?.netCash;

          const existingIndex = newData.findIndex(
            (item: any) => (item?.awardId || item?.id) === rowId
          );
          // If there is an overlap, replace the existing data with the new data
          newData[existingIndex] = filter;
          setNewData([...newData]);
          setCalculated(true);
          const updatedExerciseResponse = exerciseResponse.map((item: any) => {
            if (item.awardId === response.awardId) {
              return response; // Replace the object if the id matches
            } else {
              return item; // Keep the original object if the id doesn't match
            }
          });

          // If the response ID is not found in exerciseResponse, add the new response
          if (!exerciseResponse.find((item: any) => item.awardId === response.awardId)) {
            updatedExerciseResponse.push(response);
          }
          // setExerciseResponse([...exerciseResponse, response]);
          setExerciseResponse(updatedExerciseResponse);
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostOptionExerciseCalculateExercise;
