import styled from 'styled-components';
import { COLORS } from '../../constants/style/color';

interface ToastType {
  borderColor?: string;
  labelType?: 'Success' | 'Error' | 'Info' | '';
}

export const NotificaionWrapper: any = styled.div<ToastType>`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 24px;
    color: ${(props) =>
      props.labelType === 'Success'
        ? `#05B937`
        : props.labelType === 'Error'
        ? `#DD2525`
        : `#1C85E7`};
  }

  .closeIcon {
    color: ${COLORS.LightGray};
    font-size: 20px;
  }
`;

export const NotificationContent: any = styled.div<ToastType>`
  .notification-title {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    padding-bottom: 3px;
    color: ${(props) =>
      props.labelType === 'Success'
        ? `#05B937`
        : props.labelType === 'Error'
        ? `#DD2525`
        : `#1C85E7`};
  }
  .notification-message {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
`;
