/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto } from '../models/Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto';
import type { Wealthlane_AwardSettlementService_Dtos_RSUElect_ElectionHistoryDto } from '../models/Wealthlane_AwardSettlementService_Dtos_RSUElect_ElectionHistoryDto';
import type { Wealthlane_AwardSettlementService_Dtos_RSUElect_LongShareDto } from '../models/Wealthlane_AwardSettlementService_Dtos_RSUElect_LongShareDto';
import type { Wealthlane_AwardSettlementService_Dtos_RSUElect_RsuElectDto } from '../models/Wealthlane_AwardSettlementService_Dtos_RSUElect_RsuElectDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RsuElectService {

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardSettlementApiAppRSUElectSaveElection(
requestBody?: Wealthlane_AwardSettlementService_Dtos_RSUElect_RsuElectDto,
): CancelablePromise<Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award-settlement/api/app/r-sUElect/save-election',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param rsuAwardId 
     * @returns Wealthlane_AwardSettlementService_Dtos_RSUElect_ElectionHistoryDto Success
     * @throws ApiError
     */
    public static getApiAwardSettlementApiAppRSUElectElectionHistoryList(
rsuAwardId: string,
): CancelablePromise<Array<Wealthlane_AwardSettlementService_Dtos_RSUElect_ElectionHistoryDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award-settlement/api/app/r-sUElect/election-history-list/{RsuAwardId}',
            path: {
                'RsuAwardId': rsuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param rsuAwardId 
     * @returns string Success
     * @throws ApiError
     */
    public static postApiAwardSettlementApiAppRSUElectExportElectionHistoryList(
rsuAwardId: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award-settlement/api/app/r-sUElect/export-election-history-list/{RSUAwardId}',
            path: {
                'RSUAwardId': rsuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param rsuAwardId 
     * @returns Wealthlane_AwardSettlementService_Dtos_RSUElect_ElectionHistoryDto Success
     * @throws ApiError
     */
    public static getApiAwardSettlementApiAppRSUElectElectionHistoryByRSuAwardId(
rsuAwardId: string,
): CancelablePromise<Array<Wealthlane_AwardSettlementService_Dtos_RSUElect_ElectionHistoryDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award-settlement/api/app/r-sUElect/election-history-by-rSUAward-id/{RsuAwardId}',
            path: {
                'RsuAwardId': rsuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param rsuAwardId 
     * @returns string Success
     * @throws ApiError
     */
    public static postApiAwardSettlementApiAppRSUElectExportElectionHistoryListByRSuAwardId(
rsuAwardId: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award-settlement/api/app/r-sUElect/export-election-history-list-by-rSUAward-id/{RsuAwardId}',
            path: {
                'RsuAwardId': rsuAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardSettlementService_Dtos_RSUElect_LongShareDto Success
     * @throws ApiError
     */
    public static getApiAwardSettlementApiAppRSUElectLongSharesForParticipants(): CancelablePromise<Array<Wealthlane_AwardSettlementService_Dtos_RSUElect_LongShareDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award-settlement/api/app/r-sUElect/long-shares-for-participants',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
