import moment from 'moment';
import { DATE_FORMAT, DATE_FORMAT_WITH_TIME, SUPPORTED_IMAGE_FORMATS } from '../constants/common';
import { commonValidation } from './validations/validationRule';
import { BarInfo, TotalCombinedDataType } from '../types/common';

export const phoneFormat = (name: string, setFieldValue: any, value: string): void => {
  if (value.length === 0) setFieldValue(name, value);
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) {
    setFieldValue(name, phoneNumber);
  } else if (phoneNumberLength < 7) {
    setFieldValue(name, `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`);
  } else {
    setFieldValue(
      name,
      `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
    );
  }
};

export const phoneFormatWithDash = (value: string | undefined): string => {
  const phoneNumber = value?.replace(/[^\d]/g, '');
  const formattedPhone =
    phoneNumber != null
      ? `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
      : '';
  return formattedPhone;
};

export const ssnFormatWithDash = (name: string, setFieldValue: any, value: string): void => {
  if (value.length === 0) setFieldValue(name, value);
  const ssnNumber = value.replace(/[^\d]/g, '');
  const ssnNumberLength = ssnNumber.length;
  if (ssnNumberLength < 4) {
    setFieldValue(name, ssnNumber);
  } else if (ssnNumberLength < 6) {
    setFieldValue(name, `${ssnNumber.slice(0, 3)}-${ssnNumber.slice(3)}`);
  } else {
    setFieldValue(
      name,
      `${ssnNumber.slice(0, 3)}-${ssnNumber.slice(3, 5)}-${ssnNumber.slice(5, 9)}`
    );
  }
};

export const changeArrayValues = (
  array: Record<string, string | number | boolean>[],
  rowId: string,
  name: string,
  value: string | number | boolean
): any => {
  const filter: any = array?.find(
    (item: any) => (item?.awardId || item?.id || item?.openLotId) === rowId
  );

  filter[`${name}`] = value;
  const existingIndex = array.findIndex(
    (item: any) => (item?.awardId || item?.id || item?.openLotId) === rowId
  );
  // If there is an overlap, replace the existing data with the new data
  array[existingIndex] = filter;

  return [...array];
};

export const ssnWithDash = (value: string | undefined | null): string => {
  const ssnNumber = value?.replace(/[^\d]/g, '');
  const formattedPhone = ssnNumber !== undefined ? `XXX-XX-${ssnNumber?.slice(5, 9)}` : '';
  return formattedPhone;
};

export const setDashAsEmptyValue = (values: any): any => values ?? '-';
export const checkEmpty = (values: string | number | boolean | any): boolean => {
  if (values !== undefined && values !== null) return true;
  else return false;
};

export const ssnDisplayFormat = (values: string, display: string): string => {
  const filteredValue = display.includes('show')
    ? `${values.slice(0, 3)}-${values.slice(3, 5)}-${values.slice(5, 9)}`
    : `XXX-XX-${values.slice(5, 9)}`;
  return filteredValue;
};

export const getNonUsCountryValidation = (
  isOtherCountry: boolean | undefined,
  fieldName: string
): any => {
  if (isOtherCountry ?? false) {
    return commonValidation({ required: true, noBlank: true }).matches(
      /^[a-zA-Z ]*$/,
      `Invalid ${fieldName} Name`
    );
  } else {
    return commonValidation({ noBlank: true }).matches(/^[a-zA-Z ]*$/, `Invalid ${fieldName} Name`);
  }
};

export const getUsCountryValidation = (isCountryUS: boolean | undefined): any => {
  if (!(isCountryUS ?? false)) {
    return commonValidation({ required: true });
  } else {
    return commonValidation({});
  }
};

export const getFileExtension = (name: string): any => {
  const fileArray = name?.split('.');
  const fileExtension = fileArray[fileArray?.length - 1];

  return fileExtension;
};

export const getFormData = (data: any): any => {
  const formData = new FormData(); // Must be FormData so that the ajax request will be Form post
  Object.keys(data).forEach((k) => {
    formData.append(k, data[k]);
  });
  return formData;
};

export const handleOpenFile = (fileName: string, fileContent: string): void => {
  const fileExtension: string = getFileExtension(fileName);

  if (SUPPORTED_IMAGE_FORMATS.includes(fileExtension)) {
    const image = new Image();
    image.src = fileExtension != null ? `data:image/${fileExtension};base64,` + fileContent : '';
    const w: any = window.open('');
    w.document.write(image.outerHTML);
    w.document.close();
  } else if (fileExtension === 'pdf') {
    const pdfWindow: any = window.open('');
    pdfWindow.document.write(
      `<iframe width='100%' height='100%' src='data:application/pdf;base64, ` +
        encodeURI(fileContent) +
        "'></iframe>"
    );
  } else {
    const aElement = document.createElement('a');
    const downloadLink = `data:application/${fileExtension};base64,` + fileContent;
    aElement.setAttribute('download', fileName);
    aElement.setAttribute('href', downloadLink);
    aElement.click();
  }
};

export const handleDownloadFile = (fileName: string, fileContent: string): void => {
  const fileExtension: string = getFileExtension(fileName);

  const aElement = document.createElement('a');
  const downloadLink = `data:application/${fileExtension};base64,` + fileContent;
  aElement.setAttribute('download', fileName);
  aElement.setAttribute('href', downloadLink);
  aElement.click();
};
export const CommaFormatted = (amount: any, name?: string, setFieldValue?: any): string => {
  const numberValue = parseInt(amount?.toString().replaceAll(',', ''));
  const value = numberValue.toLocaleString();

  if (name !== null && name !== undefined && amount !== null && amount !== undefined) {
    setFieldValue?.(name, value);
  }
  return value;
};

export const convertNumberWithCommas = (value?: number | string, showDecimal?: boolean): string => {
  const NumberValue = Number(value)?.toFixed(2);
  value = String(value);
  const x: string[] = NumberValue?.toString().split('.');
  let x1 = x[0];
  const x2 = '.' + x[1];
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    // eslint-disable-next-line no-useless-concat
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  const finalValue = showDecimal ?? false ? x1 + x2 : x1;

  return finalValue;
};

export const profitLossCalculation = (value?: number | string): string => {
  const calculatedValue = value?.toString().replaceAll('-', '');

  return Number(value) >= 0
    ? `+$${Number(calculatedValue)
        .toFixed(2)
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}`
    : `-$${Number(Number(calculatedValue)?.toString())
        .toFixed(2)
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}`;
};

export const changeTimeZone = (date: any, timeZone: string): any => {
  if (typeof date === 'string') {
    return moment(
      new Date(date)?.toLocaleString('en-US', {
        timeZone,
      })
    ).format(DATE_FORMAT_WITH_TIME);
  }

  return moment(
    date?.toLocaleString('en-US', {
      timeZone,
    })
  ).format(`${DATE_FORMAT} . h:mm A`);
};

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const handleStringCheck = (
  value: string,
  setCheck: ({
    longer,
    camelCase,
    isNumber,
    hasSymbol,
  }: {
    longer: boolean;
    camelCase: boolean;
    isNumber: boolean;
    hasSymbol: boolean;
  }) => void
): any => {
  const longer = value.length >= 8;
  const isNumber = /\d/.test(value);
  // eslint-disable-next-line no-useless-escape
  const hasSymbol = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value);
  const camelCase = /[a-z]/.test(value) && /[A-Z]/.test(value);

  setCheck({
    longer,
    camelCase,
    isNumber,
    hasSymbol,
  });
};

export const MaterialIconFilled = {
  fontVariationSettings: `"FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48`,
};

export const combineDataByIndexValue = (info: BarInfo[]): TotalCombinedDataType[] => {
  const combinedData: any = {};

  info.forEach((item: any) => {
    const indexValue = item.data.indexValue;
    if (!combinedData[indexValue]) {
      combinedData[indexValue] = {
        width: item.width,
        x: item.x,
        y: item.y,
        indexValue: indexValue,
        totalValue: item.data.value,
      };
    } else {
      combinedData[indexValue].totalValue += item.data.value;
    }
  });

  return Object.values(combinedData);
};

export const getVestingSchedule = (
  sliderValue: number,
  latestPrice: number,
  exerciseCost: number,
  qty: number,
  isRSU: boolean
) => {
  let value: number = 0;
  if (isRSU) {
    value = Number(latestPrice) * qty;
  } else if (exerciseCost < latestPrice) {
    value = (latestPrice - exerciseCost) * qty;
  } else if (exerciseCost > latestPrice) {
    value = 0;
  }
  return Number(value.toFixed(2)) ?? 0;
};
