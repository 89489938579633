import { useQuery } from 'react-query';
import { TransactionService } from '../../services/wealthlane-award-services';
import { convertNumberWithCommas } from '../../utils/global';

const useGetTransactionHistoryTaxDetail = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: string,
  setInitialValue?: any
) => {
  const transactionHistoryTaxDetail = useQuery(
    ['getTransactionHistoryTaxDetail', id],
    async () => {
      if (id) {
        return await handleRequest(
          TransactionService.getApiAwardApiAppTransactionTaxDetailsByTransactionId(id)
        );
      }
    },
    {
      onSuccess: (response: any) => {
        if (response != null) {
          const formattedTaxDetails = {
            ...response,
            taxDetails: response?.taxDetails?.map((taxDetail: any) => ({
              ...taxDetail,
              taxRate: convertNumberWithCommas(Number(taxDetail.taxRate).toFixed(2), true),
              taxAmount: convertNumberWithCommas(Number(taxDetail.taxAmount).toFixed(2), true),
            })),
          };

          setInitialValue(formattedTaxDetails);
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
  return transactionHistoryTaxDetail;
};

export default useGetTransactionHistoryTaxDetail;
