import { type UseQueryResult, useQuery } from 'react-query';
import {
  RsuAwardService,
  TransactionService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_Transactions_TransactionDto,
} from '../../services/wealthlane-award-services';

const useGetExtraTransactionById = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_Transactions_TransactionDto | undefined,
  unknown
> => {
  const extraTransactionById = useQuery(['getExtraTransactionById', id], async () => {
    if (id) {
      return await handleRequest(
        TransactionService.getApiAwardApiAppTransactionTransactionById(id)
      );
    }
  });

  return extraTransactionById;
};

export default useGetExtraTransactionById;
