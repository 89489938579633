import { type UseQueryResult, useQuery } from 'react-query';
import {
  NotificationSettingsService,
  // eslint-disable-next-line camelcase
  type Wealthlane_NotificationService_DTOs_NotificationSettingsDto,
} from '../../services/wealthlane-notification-services';

const useGetNotificationSettings = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_NotificationService_DTOs_NotificationSettingsDto[] | undefined,
  unknown
> => {
  const notificationSettings = useQuery(['getNotificationSettings'], async () => {
    return await handleRequest(
      NotificationSettingsService.getApiNotificationApiAppNotificationSettingsParticipantNotificationSettings()
    );
  });

  return notificationSettings;
};

export default useGetNotificationSettings;
