/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_CustomerService_Dtos_Response_ResponseDto } from '../models/Wealthlane_CustomerService_Dtos_Response_ResponseDto';
import type { Wealthlane_CustomerService_Dtos_TermsAndConditions_TermsAndConditionsBlobDto } from '../models/Wealthlane_CustomerService_Dtos_TermsAndConditions_TermsAndConditionsBlobDto';
import type { Wealthlane_CustomerService_Dtos_TermsAndConditions_TermsAndConditionsDto } from '../models/Wealthlane_CustomerService_Dtos_TermsAndConditions_TermsAndConditionsDto';
import type { Wealthlane_CustomerService_Dtos_TermsAndConditions_TermsAndConditionsListDto } from '../models/Wealthlane_CustomerService_Dtos_TermsAndConditions_TermsAndConditionsListDto';
import type { Wealthlane_CustomerService_Dtos_TermsAndConditions_TermsAndConditionsPageDto } from '../models/Wealthlane_CustomerService_Dtos_TermsAndConditions_TermsAndConditionsPageDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TermsAndConditionsService {

    /**
     * @param formData 
     * @returns Wealthlane_CustomerService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppTermsAndConditions(
formData?: {
PageId: string;
Name: string;
DocumentContent?: string;
IsActive: boolean;
Document?: Blob;
AgreementStatement: string;
},
): CancelablePromise<Wealthlane_CustomerService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/terms-and-conditions',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData 
     * @returns Wealthlane_CustomerService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static putApiCustomerApiAppTermsAndConditions(
formData?: {
Id: string;
PageId: string;
Name: string;
DocumentContent?: string;
IsActive: boolean;
Document?: Blob;
AgreementStatement: string;
IsDocumentRemoved: boolean;
},
): CancelablePromise<Wealthlane_CustomerService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/customer/api/app/terms-and-conditions',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param termsAndConditionsId 
     * @returns Wealthlane_CustomerService_Dtos_TermsAndConditions_TermsAndConditionsBlobDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppTermsAndConditionsDownloadDocument(
termsAndConditionsId: string,
): CancelablePromise<Wealthlane_CustomerService_Dtos_TermsAndConditions_TermsAndConditionsBlobDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/terms-and-conditions/download-document/{termsAndConditionsId}',
            path: {
                'termsAndConditionsId': termsAndConditionsId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param termsAndConditionsId 
     * @returns Wealthlane_CustomerService_Dtos_TermsAndConditions_TermsAndConditionsBlobDto Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppTermsAndConditionsDownloadDocumentByParticipant(
termsAndConditionsId: string,
): CancelablePromise<Wealthlane_CustomerService_Dtos_TermsAndConditions_TermsAndConditionsBlobDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/terms-and-conditions/download-document-by-participant/{termsAndConditionsId}',
            path: {
                'termsAndConditionsId': termsAndConditionsId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_CustomerService_Dtos_TermsAndConditions_TermsAndConditionsListDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppTermsAndConditionsTermsAndConditions(): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_TermsAndConditions_TermsAndConditionsListDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/terms-and-conditions/terms-and-conditions',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param page 
     * @returns Wealthlane_CustomerService_Dtos_TermsAndConditions_TermsAndConditionsListDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppTermsAndConditionsActiveTermsAndConditions(
page?: string,
): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_TermsAndConditions_TermsAndConditionsListDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/terms-and-conditions/active-terms-and-conditions',
            query: {
                'page': page,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns Wealthlane_CustomerService_Dtos_TermsAndConditions_TermsAndConditionsDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppTermsAndConditionsById(
id: string,
): CancelablePromise<Wealthlane_CustomerService_Dtos_TermsAndConditions_TermsAndConditionsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/terms-and-conditions/{id}/by-id',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_CustomerService_Dtos_TermsAndConditions_TermsAndConditionsPageDto Success
     * @throws ApiError
     */
    public static getApiCustomerApiAppTermsAndConditionsPages(): CancelablePromise<Array<Wealthlane_CustomerService_Dtos_TermsAndConditions_TermsAndConditionsPageDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer/api/app/terms-and-conditions/pages',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
