import { ErrorMessage, Field } from 'formik';
import React, { type ReactNode, useEffect, useState } from 'react';
import { useMediaQuery } from '../../hooks';
import Tooltip from '../tooltip';
import { FormWrapper, Label } from './input.styled';
import Tag from '../lozenge/tags';

interface InputTypeProps {
  label?: string;
  name: any;
  type?: string | number;
  className?: string;
  labelClassName?: string;
  required?: boolean;
  disabled?: boolean;
  value?: string | number;
  avoidKey?: any;
  onKeyUp?: any;
  defaultValue?: number | string;
  errors?: any;
  touched?: any;
  placeholder?: string;
  onPaste?: any;
  width?: string;
  min?: number;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  onChange: any;

  // change to boolean on all fields
  inputType?: boolean | string;
  errorValue?: any;
  touchedValue?: any;
  note?: string | ReactNode;
  tooltipText?: string;
  id?: string;
}

const InputText = React.memo((props: InputTypeProps) => {
  const isMobile = useMediaQuery(512);
  const [show, setShow] = useState<boolean>(false);
  const {
    label,
    name,
    type,
    className,
    labelClassName,
    required,
    onKeyUp,
    avoidKey,
    value,
    defaultValue,
    setFieldValue,
    inputType,
    errors,
    touched,
    placeholder,
    min,
    // onBlur,
    width,
    errorValue,
    touchedValue,
    note,
    tooltipText,
    id,
    onPaste,
    ...rest
  } = props;

  useEffect(() => {
    setFieldValue?.(name, defaultValue);
  }, [defaultValue]);

  const showType = type === 'password' && show ? 'text' : type;
  const hasError =
    (Boolean(errors) && Boolean(touched) && Boolean(errors[name]) && Boolean(touched[name])) ||
    (Boolean(errorValue) && Boolean(touchedValue))
      ? 'is-invalid'
      : '';
  const isTypePassword = type === 'password' ? 'input-password' : '';
  return (
    <FormWrapper className={className}>
      {label != null && (
        <Label
          htmlFor={name}
          className={`${labelClassName ?? ''} ${tooltipText != null ? 'tooltipView' : ''}`}>
          {label} {!(required ?? false) && <span>(Optional)</span>}{' '}
          {tooltipText != null && (
            <Tooltip tooltipText={tooltipText} variant='dark' size='sm'>
              <span className='material-symbols-sharp'>info</span>
            </Tooltip>
          )}
        </Label>
      )}
      <Field
        id={id}
        name={name}
        label={label}
        className={`${hasError} ${isTypePassword}`}
        type={showType ?? 'text'}
        autoComplete='off'
        as={'input'}
        min={min}
        onPaste={onPaste}
        // onBlur={onBlur}
        onKeyUp={onKeyUp}
        placeholder={placeholder}
        defaultValue={defaultValue}
        style={{ width: isMobile ? '100%' : width ?? '100%' }}
        {...(Boolean(avoidKey) && { avoidKey })}
        {...(Boolean(value) && { value })}
        {...rest}
      />
      {type === 'password' && (
        <span
          className='material-symbols-outlined password-icon'
          onClick={() => {
            setShow(!show);
          }}>
          {`visibility${show ? '' : '_off'}`}
        </span>
      )}
      {type === 'days' && <Tag variant='default' title='DAYS' className='year' />}
      {type === 'year' && <Tag variant='default' title='YEAR' className='year' />}
      {Boolean(note) && <div className='inputNote'>{note}</div>}

      {Boolean(errors) && <ErrorMessage name={name} component='div' className='error-message' />}
    </FormWrapper>
  );
});

export default InputText;
