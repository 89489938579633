import { type UseQueryResult, useQuery } from 'react-query';

import {
  TradeOrderService,
  // eslint-disable-next-line camelcase
  type Wealthlane_AwardSettlementService_Dtos_TradeOrder_OrderView,
} from '../../services/wealthlane-award-settlement-services';

const useGetTradeOrderTransactionById = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: string
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_AwardSettlementService_Dtos_TradeOrder_OrderView | undefined,
  unknown
> => {
  const tradeOrderTransactionById = useQuery(['tradeOrderTransactionDetails', id], async () => {
    if (id) {
      return await handleRequest(
        TradeOrderService.getApiAwardSettlementApiAppTradeOrderTradeOrderByTransactionId(id)
      );
    }
  });

  return tradeOrderTransactionById;
};

export default useGetTradeOrderTransactionById;
