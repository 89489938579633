import { type UseQueryResult, useQuery } from 'react-query';
import {
  ParticipantBankService,
  // eslint-disable-next-line camelcase
  type Wealthlane_FinanceIntegrationService_Dtos_ParticipantBank_ParticipantBankForView,
} from '../../services/wealthlane-finance-integration-services';

const useGetCompanyBankView = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id: string,
  openModal: () => void
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_FinanceIntegrationService_Dtos_ParticipantBank_ParticipantBankForView | undefined,
  unknown
> => {
  const companyBankView = useQuery(
    ['getBankAccountViewData', id],
    async () => {
      return await handleRequest(
        ParticipantBankService.getApiFinanceIntegrationApiAppParticipantBankParticipantBankForView(
          id
        )
      );
    },
    {
      enabled: typeof id === 'string' && id !== '',
      onSuccess: (data) => {
        if (data != null) {
          openModal();
          return data;
        }
      },
    }
  );

  return companyBankView;
};

export default useGetCompanyBankView;
