import React, { type ReactNode, useEffect, useState } from 'react';
import { ClearNotificationButton, ModelStyle } from './modal.styled';
import { COLORS } from '../../constants/style/color';
import Heading from '../typography';
import { ModalAnimation } from '../animation/exports';
import Spinners from '../spinner';
import { useSelector } from 'react-redux';
import AlertPopup from '../alert-popup';
import { selectNotify } from '../../auth/notify';

interface ModalComponentType {
  show: boolean;
  closeModal: any;
  children: any;
  subTitle?: string;
  title?: string | ReactNode;
  className?: any;
  showHeader?: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';
  placement?: 'top' | 'center' | 'bottom';
  handleSubmit?: any;
  loading?: boolean;
  showNav?: boolean;
  outsideClickable?: boolean;
  isNotification?: boolean;
  clearAll?: boolean;
  clearAllNotification?: any;
  notificationModalClassName?: string;
  valueEdited?: boolean;
}

const ModalComponent = ({
  show,
  closeModal,
  children,
  title,
  subTitle,
  className,
  size,
  placement,
  loading = false,
  handleSubmit,
  showHeader = true,
  outsideClickable = false,
  isNotification = false,
  showNav = false,
  clearAll = false,
  clearAllNotification,
  notificationModalClassName,
  valueEdited = true,
  ...rest
}: ModalComponentType): JSX.Element => {
  const showNotify = useSelector(selectNotify);
  const [openAlert, setOpenAlert] = useState<boolean>(false);

  useEffect(() => {
    if (show) {
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    }
  }, [show]);

  return (
    <>
      <ModalAnimation
        isNotification={isNotification}
        closeModal={() => outsideClickable && closeModal()}>
        <ModelStyle size={size ?? 'lg'} {...rest} className={className} showNotify={showNotify}>
          {showHeader && (
            <div className={`modal-heading ${notificationModalClassName ?? ''}`}>
              <div className={`header-text ${notificationModalClassName ?? ''}`}>
                <div className={`flex items-center gap-x-5 ${notificationModalClassName ?? ''}`}>
                  {title != null && (
                    <Heading
                      variant='h4'
                      title={title}
                      className={`${notificationModalClassName ?? ''} ${subTitle ? '' : 'mb-0'}`}
                    />
                  )}
                </div>
                {subTitle != null && <Heading variant='h6' title={subTitle} color={COLORS.Black} />}
              </div>
              {clearAll ? (
                <ClearNotificationButton
                  onClick={() => clearAllNotification()}
                  className={notificationModalClassName}>
                  Clear All
                </ClearNotificationButton>
              ) : (
                <div
                  onClick={() => {
                    valueEdited ? closeModal() : setOpenAlert(true);
                  }}
                  className='modal-close'>
                  <span className='material-symbols-outlined icon-color mt-0'>cancel</span>
                  <Heading variant='body-s' title='Close' color={`${COLORS.Gray}`} />
                </div>
              )}
            </div>
          )}
          <div className='modal-body'>
            {loading ? (
              <div className={`modal-loader ${showNotify ? 'modal-loader-notify' : ''}`}>
                <Spinners />
              </div>
            ) : (
              React.cloneElement(children, { show, closeModal })
            )}
          </div>
        </ModelStyle>
      </ModalAnimation>
      {openAlert && (
        <AlertPopup
          isShow={openAlert}
          closeModal={() => {
            setOpenAlert(false);
          }}
          customTitle={'All data will be lost if you close. Are you sure you want to close?'}
          handleSubmit={async () => {
            setOpenAlert(false);
            await closeModal();
          }}
          // loading={loading}
        />
      )}
    </>
  );
};

export default ModalComponent;
