import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import ToastComponent from '../../components/toast';
import {
  ParticipantBankService,
  // eslint-disable-next-line camelcase
  type Wealthlane_FinanceIntegrationService_Dtos_ParticipantBank_SavePublicTokenMetaDataForParticipantDto,
} from '../../services/wealthlane-finance-integration-services';

const usePostBankAccount = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseMutationResult<
  // eslint-disable-next-line camelcase
  | Wealthlane_FinanceIntegrationService_Dtos_ParticipantBank_SavePublicTokenMetaDataForParticipantDto
  | undefined,
  unknown,
  any,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      return await handleRequest(
        ParticipantBankService.postApiFinanceIntegrationApiAppParticipantBankSavePublicTokenMetaData(
          payload
        )
      );
    },
    {
      onSuccess: async (
        response: // eslint-disable-next-line camelcase
        | Wealthlane_FinanceIntegrationService_Dtos_ParticipantBank_SavePublicTokenMetaDataForParticipantDto
          | undefined
      ) => {
        if (response !== undefined && response !== null) {
          await queryClient.invalidateQueries('getCompanyBankList');
          await queryClient.invalidateQueries('getCompanyBankToken');
          toast.success(<ToastComponent label='Success' message='Bank Account Added' />);
        }
      },
      onError: (err) => {
        console.log('error', err);
      },
    }
  );
};

export default usePostBankAccount;
