import { type UseQueryResult, useQuery } from 'react-query';
import { NotificationService } from '../../services/wealthlane-notification-services';

const useGetUnseenNotificationCount = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  token: string | null
): UseQueryResult<number | undefined, unknown> => {
  const unseenNotificationCountList = useQuery(
    ['getGetUnseenNotificationCountList'],
    async () => {
      if (token != null && token !== '') {
        return await handleRequest(
          NotificationService.getApiNotificationApiAppNotificationUnseenNotificationsCount()
        );
      }
    },
    {
      cacheTime: 1000 * 60 * 5,
    }
  );
  return unseenNotificationCountList;
};

export default useGetUnseenNotificationCount;
