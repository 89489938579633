/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_AwardSettlementService_Dtos_OptionElect_ExerciseHistoryDto } from '../models/Wealthlane_AwardSettlementService_Dtos_OptionElect_ExerciseHistoryDto';
import type { Wealthlane_AwardSettlementService_Dtos_OptionElect_OptionAwardElectDto } from '../models/Wealthlane_AwardSettlementService_Dtos_OptionElect_OptionAwardElectDto';
import type { Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto } from '../models/Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto';
import type { Wealthlane_AwardSettlementService_Dtos_RSUElect_ElectionHistoryDto } from '../models/Wealthlane_AwardSettlementService_Dtos_RSUElect_ElectionHistoryDto';
import type { Wealthlane_AwardSettlementService_Dtos_RSUElect_LongShareDto } from '../models/Wealthlane_AwardSettlementService_Dtos_RSUElect_LongShareDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OptionElectService {

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiAwardSettlementApiAppOptionElectSaveExercise(
requestBody?: Wealthlane_AwardSettlementService_Dtos_OptionElect_OptionAwardElectDto,
): CancelablePromise<Wealthlane_AwardSettlementService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award-settlement/api/app/option-elect/save-exercise',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param optionAwardId 
     * @returns Wealthlane_AwardSettlementService_Dtos_RSUElect_ElectionHistoryDto Success
     * @throws ApiError
     */
    public static getApiAwardSettlementApiAppOptionElectExerciseHistoryList(
optionAwardId: string,
): CancelablePromise<Array<Wealthlane_AwardSettlementService_Dtos_RSUElect_ElectionHistoryDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award-settlement/api/app/option-elect/exercise-history-list/{OptionAwardId}',
            path: {
                'OptionAwardId': optionAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param optionAwardId 
     * @returns Wealthlane_AwardSettlementService_Dtos_RSUElect_ElectionHistoryDto Success
     * @throws ApiError
     */
    public static getApiAwardSettlementApiAppOptionElectExerciseHistoryByOptionAwardId(
optionAwardId: string,
): CancelablePromise<Array<Wealthlane_AwardSettlementService_Dtos_RSUElect_ElectionHistoryDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award-settlement/api/app/option-elect/exercise-history-by-option-award-id/{OptionAwardId}',
            path: {
                'OptionAwardId': optionAwardId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardSettlementService_Dtos_RSUElect_LongShareDto Success
     * @throws ApiError
     */
    public static getApiAwardSettlementApiAppOptionElectLongSharesForParticipants(): CancelablePromise<Array<Wealthlane_AwardSettlementService_Dtos_RSUElect_LongShareDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award-settlement/api/app/option-elect/long-shares-for-participants',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardSettlementService_Dtos_OptionElect_ExerciseHistoryDto Success
     * @throws ApiError
     */
    public static getApiAwardSettlementApiAppOptionElectExerciseHistory(): CancelablePromise<Array<Wealthlane_AwardSettlementService_Dtos_OptionElect_ExerciseHistoryDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award-settlement/api/app/option-elect/exercise-history',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
