import { type PropsWithChildren } from 'react';
import { motion } from 'framer-motion';

const SlideUpAnimation = ({ children }: PropsWithChildren): JSX.Element => {
  return (
    <motion.section
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -20, opacity: 0 }}
      transition={{ duration: 0.8 }}>
      {children}
    </motion.section>
  );
};

export default SlideUpAnimation;
