import { type UseQueryResult, useQuery } from 'react-query';
import { PsuElectionWindowConfigurationService } from '../../services/wealthlane-award-settlement-services';
import { MyHoldingsService, PsuAwardService } from '../../services/wealthlane-award-services';

const useGetPsuAwardPerformanceRuleDetails = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  awardId: string
): any => {
  const psuAwardPerformanceRuleDetails = useQuery(
    ['getPsuAwardPerformanceRuleDetails'],
    async () => {
      if (awardId) {
        return await handleRequest(
          MyHoldingsService.getApiAwardApiAppMyHoldingsPerformanceRuleDetail(awardId)
        );
      }
    }
  );
  return psuAwardPerformanceRuleDetails;
};

export default useGetPsuAwardPerformanceRuleDetails;
